@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
}

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  background-color: var(--bg-wht);
}
.post--img img {
  transition: var(--transit-default);
}
.post:hover .post--img img {
  opacity: 0.5;
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--date {
  color: #aaa;
  @include mq(sp) {
    font-size: sprem(12);
  }
  + .post--views {
    margin-left: rem(16);
  }
}
.post--views {
  display: none;
  color: #aaa;
  font-size: rem(12);
  @include mq(sp) {
    font-size: sprem(12);
  }
}
.post--info {
  gap: 8px;
}
.post--info.flex .cat_list + .post--date {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(14);
  min-width: 83px;
  font-family: var(--font-secondary);
  font-weight: bold;
  text-align: center;
  background-color: var(--clr-pink);
  color: var(--clr-wht, #fff);
  padding: 0.3em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
  line-height: var(--line-height-none);
  border-radius: 4px;
}
.cat_list a:hover {
  opacity: 0.7;
}
.icon-new {
  display: flex;
  align-items: center;
  margin-inline: rem(20);
  color: var(--bg-red);
  font-size: rem(20);
  font-family: var(--font-secondary);
  font-weight: bold;
  line-height: 1;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 300px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: 64px;
}
.side--ttl {
  margin-bottom: rem(16);
  color: var(--clr-main-sub);
  font-size: rem(32);
  line-height: 1;
  letter-spacing: -0.03em;
}

/* --- list --- */
.side--list {
  gap: rem(32);
  font-size: rem(16);
}
.side--list a {
  display: flex;
  justify-content: space-between;
}
.post-type-article .side--list {
  line-height: 2;
  column-count: 2;
  page-break-inside: avoid;
  break-inside: avoid;
}

/* --- profile --- */
.profile-img {
  display: block;
  margin-inline: auto;
  border-radius: 50%;
  @include mq(sp) {
    width: 80%;
  }
}

/* --- post --- */
.posts-side {
  counter-reset: number;
  .post {
    display: grid;
    grid-template-columns: rem(64) minmax(0, 1fr);
    gap: rem(16);
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
    &::before {
      counter-increment: number;
      content: counter(number);
      position: absolute;
      left: rem(-10);
      top: rem(-10);
      display: grid;
      place-content: center;
      width: rem(10);
      height: rem(10);
      padding: rem(10);
      color: var(--clr-wht);
      font-size: rem(14);
      letter-spacing: 0;
      line-height: 1;
      border-radius: 50%;
      background-color: var(--bg-red);
    }
  }
  .post--date {
    margin-bottom: 0;
    font-size: rem(12);
  }
  .post--ttl {
    font-size: rem(15);
    line-height: var(--line-height-hdr);
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--trans-default, all 0.4s ease-out);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
  padding: rem(16);
  background-color: var(--bg-off_wht);
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  &:hover {
    background-color: #eee;
  }
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--bg-wht, #fff);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(
    --font-primary,
    "YuGothic",
    "游ゴシック",
    "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN",
    sans-serif
  );
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0.4em;
    right: 0.8em;
    margin: auto;
    width: 0.6em;
    height: 0.6em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: all 0.2s ease-out;
  }
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht, #fff);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* article
**************************************** */
.posts-article {
  counter-reset: number;
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.posts-article .post {
  display: grid;
  gap: rem(40);
  border-bottom: 1px solid #e5e5e5;
  padding-block: rem(24);
  .icon-new {
    margin-right: vw(8);
  }
  @include mq(sp, min) {
    grid-template-columns: 240px 1fr;
  }
  @include mq(sp) {
    gap: sprem(20);
  }
  .ttl-deco05--cont {
    right: rem(32);
    padding: 0 rem(16) rem(16);
    @include mq(sp) {
      right: sprem(8);
      padding: 0 sprem(10) sprem(6);
    }
    &::before {
      counter-increment: number;
      content: counter(number);
      font-size: rem(20);
      font-family: var(--font-en);
    }
  }
}
.posts-article .post--ttl {
  color: #111;
  font-size: rem(28);
  @include lh(28, 36);
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.posts-article .post--txtarea {
  overflow: hidden;
}

/* form
**************************************** */
.posts-form .post {
  display: grid;
  place-items: center;
  padding: 1em;
  text-align: center;
  border-radius: 8px;
  background-color: var(--bg-wht);
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
  border-top: 14px solid;
  border-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
}

/* program
**************************************** */
.posts-program .post {
  margin-top: rem(44);
  border-radius: 8px;
  background-color: var(--bg-wht);
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: var(--bg-wht);
    border-radius: 8px;
  }
  &::before {
    top: rem(-44);
    left: calc(50% - rem(160) / 2);
    z-index: 1;
    width: rem(160);
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
  }
  &::after {
    top: 14px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
.deco-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 14px;
  background-color: var(--color);
  border-radius: 8px 8px 0 0;
  @include mq(sp) {
    height: 6px;
  }
}
// .billionaire-mind-programs .deco-border {
//   background-image: linear-gradient(to right, #6d521d, #dec347, #dbddd7);
// }
// .billionaire-mind-coach-program .deco-border {
// }
// .billionaire-business-consulting-program .deco-border {
//   background-image: linear-gradient(to right, #8c4216, #a6823d, #dbddd7);
// }
// .billionaire-influencer-program .deco-border {
//   background-image: linear-gradient(to right, #4a31b7, #5861a0, #dbddd7);
// }
// .billionaire-elite-10xclub-program .deco-border {
//   background-image: linear-gradient(to right, #2c55ec, #78dcf9, #dbddd7);
// }
// .interpreter-business-program .deco-border {
//   background-image: linear-gradient(to right, #ef6145, #de783b, #dbddd7);
// }
// .sokupera-english-program .deco-border {
//   background-image: linear-gradient(to right, #b70035, #f24646, #dbddd7);
// }
.posts-program .post--inner {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  padding: vw(40);
  padding-top: calc(rem(40) + 14px);
  height: 100%;
  overflow: hidden;
  @include mq(sp) {
    padding: sprem(32);
  }
}
.posts-program .post--term {
  position: absolute;
  z-index: 3;
  top: -15px;
  left: 50%;
  font-size: rem(20);
  translate: -50% 0;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: "";
    display: block;
    width: 24px;
    margin-inline: auto;
    background-image: url(../images/common/icon-light.svg);
    aspect-ratio: 1;
  }
}
.cat-english .posts-program .post--term {
  top: -25px;
}
.posts-program .post--ttl {
  color: var(--clr-main);
  font-size: rem(32);
  @include lh(32, 42);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.posts-program .post--ttl .font-en {
  display: block;
  text-transform: capitalize;
  margin-top: 1em;
  font-size: rem(18);
  @include mq(sp) {
    font-size: sprem(10);
  }
}
.posts-program .post--ttl a {
  color: var(--clr-main);
}
.posts-program .post--txt {
  font-size: vw(20);
  @include lh(22, 36);
  @include mq(sp) {
    font-size: sprem(15);
  }
}
@include mq(sp) {
  .posts-program .post--txt br {
    display: none;
  }
}
.posts-program .btn {
  margin-top: auto;
}

/* story
**************************************** */
.posts-story {
  gap: 4.9% !important;
  @include mq(sp) {
    gap: 10vw !important;
    width: 85%;
    margin-inline: auto;
  }
}
@include mq(sp, min) {
  .posts.posts-story > * {
    width: 30%;
    margin-bottom: 3%;
  }
  .home {
    .posts-story .post {
      &:nth-child(1) {
        margin-left: 4.9%;
      }
      &:nth-child(2) {
        margin-right: 4.9%;
      }
    }
  }
}
.posts-story .post {
  overflow: hidden;
}
.posts-story .post--ttl {
  font-size: rem(20);
  @include lh(24, 40);
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.posts-story .post--img {
  position: relative;
  display: block;
  margin-bottom: 24px;
  -webkit-perspective: 500px;
  perspective: 500px;
  transform-style: preserve-3d;
  transition: 1s ease-in-out;
  box-shadow: 0 0 52px rgba(103, 15, 39, 0.15);
  background-color: var(--bg-wht);
}
.before-card,
.after-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    background-size: contain;
  }
}
.before-card {
  &::before {
    right: 1em;
    bottom: 1em;
    width: rem(90);
    aspect-ratio: 90/41;
    background-image: url(../images/txt-before.svg);
  }
}
.after-card {
  z-index: 1;
  &::before {
    left: 1em;
    top: 1em;
    width: rem(77);
    aspect-ratio: 77/41;
    background-image: url(../images/txt-after.svg);
  }
}
html:not(.ipad) {
  .posts-story .after-card {
    transform: rotateY(180deg);
  }
  .posts-story .post.is-active .post--img {
    transform: rotateY(180deg);
  }
}
html.ipad {
  .posts-story .after-card {
    opacity: 0;
    filter: blur(10px);
    transform: scale(1.1);
    transition: var(--transit-default);
  }
  .posts-story .post.is-active .after-card {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}
.story_more-btn a {
  max-width: 500px;
  width: min(100%, 500px);
}

/* voice
**************************************** */
.posts-voice .post {
  gap: rem(54);
  border: 1px solid rgba(151, 89, 11, 0.1);
  padding: rem(40);
  background-color: var(--bg-wht);
  @include mq(sp) {
    gap: sprem(32);
    padding: sprem(20);
  }
  &:not(:last-child) {
    margin-bottom: rem(40);
  }
}
.posts-voice .post--name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: rem(16);
  margin-top: 1em;
  font-size: rem(18);
  &::before {
    content: "";
    flex: 1;
    height: 1px;
    background-color: rgba(151, 89, 11, 0.1);
    display: block;
  }
}
.posts-voice .post--main {
  position: relative;
  flex-shrink: 0;
  gap: rem(32);
  @include mq(sp, min) {
    width: 30%;
  }
}
.posts-voice .post--img {
  position: relative;
  padding-bottom: rem(100);
  @include mq(sp) {
    padding-bottom: sprem(80);
  }
}
.posts-voice .post--img + .btn {
  margin-top: rem(24);
}
.posts-voice .post--img .img {
  position: relative;
  &::before {
    position: absolute;
    z-index: 1;
    color: var(--clr-main);
    font-family: var(--font-en);
    line-height: 1;
  }
}
.posts-voice .post:hover .post--img img {
  opacity: 1 !important;
}
.posts-voice .post--img .before {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  width: 40%;
  &::before {
    content: "Before";
    position: relative;
    display: block;
    font-size: rem(20);
  }
  &::after {
    content: "";
    position: absolute;
    top: rem(-80);
    left: rem(20);
    width: rem(60);
    background-image: url(../images/b_a_arrow.svg);
    background-repeat: no-repeat;
    aspect-ratio: 161/150;
    transform: rotate(-15deg);
    @include mq(sp) {
      top: sprem(-80);
      width: sprem(60);
    }
  }
}
.posts-voice .post--img .after {
  margin-left: rem(100);
  @include mq(sp) {
    margin-left: sprem(80);
  }
  &::before {
    content: "After";
    left: rem(10);
    top: rem(10);
    font-size: rem(20);
  }
}
.posts-voice .post--txtarea {
  flex: 1;
}
.posts-voice .post--txtarea dd:not(:last-child) {
  margin-bottom: 1rem;
}

/* coach
**************************************** */
.posts-coach {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: rem(40);
  @include mq(sp) {
    grid-template-columns: repeat(2, 1fr);
    gap: sprem(16);
  }
}
.posts-coach .post--img {
  overflow: hidden;
  border-radius: 50%;
}
.posts-coach .post--ttl span {
  display: block;
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht, #fff);
}

/* --- news_list --- */
.news_list .post {
  padding-block: rem(24);
  border-bottom: 2px solid #efefef;
  @include mq(sp) {
    display: block;
  }
}
.news_list .post--date {
  color: var(--clr-blk);
  font-size: rem(18);
}
.news_list .post--txt {
  font-size: rem(14);
  color: var(--clr-main-sub);
}
.news_list .post--info {
  @include mq(sp, min, ps) {
    margin-right: rem(32);
  }
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: rem(16);
}

/* gallery
**************************************** */
.posts-gallery .post {
  width: 48%;
  @include mq(sp) {
    width: 100%;
    margin-bottom: vw(56);
  }
}
