@use "../abstracts" as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.inner-sm {
  max-width: 960px;
}
.inner-xs {
  max-width: 840px;
}
.inner-lg {
  max-width: 1440px;
}
.inner-xl {
  max-width: 1600px;
}
@include mq(sp) {
  .inner :not(.breadcrumbs) .inner {
    width: 100%;
  }
}
