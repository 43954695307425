@use "../abstracts" as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  width: min(100%, 430px);
  padding: 1.1em rem(40);
  color: var(--btn-clr-main, #fff);
  font-weight: bold;
  font-size: rem(20);
  font-family: var(--font-jp);
  text-align: center;
  line-height: 1.4;
  gap: rem(16);
  letter-spacing: 0.17em;
  background-color: #b21141;
  box-shadow: 0 0 52px rgb(103 15 38 / 15%);
  border-radius: 100px;
  transition: var(--transit-default);
  text-decoration: none;
  @include mq(sp) {
    min-width: 100%;
    font-size: sprem(16);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
    background-position: right;
    background-size: 150%;
    border-radius: 100px;
    opacity: 0.5;
    transition: var(--transit-default);
  }
}
.btn a i {
  display: none;
}
.btn a:hover {
  opacity: 0.5;
}
.flex > .btn a {
  min-width: 100%;
}
.btn-sm a {
  width: min(100%, 350px);
  padding: rem(16) rem(54);
  font-size: rem(18);
}
.btn-lg a {
  padding: 1.5em 3em;
  @include mq(sp) {
    padding: 1em 2em;
  }
}
.btn.txt-ctr a {
  @include auto-margin;
}
.btn svg {
  position: absolute;
  right: rem(30);
  transition: var(--transit-default, all 0.4s ease-out);
  fill: var(--btn-clr-main, #fff);
}
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27, 12);
}
.btn a:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::after {
  content: "";
  position: absolute;
  right: rem(30);
  display: block;
  width: 9px;
  height: 19px;
  background: url(/wp/wp-content/themes/standard_sass/images/common/right-arrow.svg) no-repeat;
  background-size: cover;
  transition: var(--transit-default, all 0.4s ease-out);
  @include mq(sp) {
    width: 6px;
    height: auto;
    aspect-ratio: 6/12;
  }
}
.btn.btn-next_page a {
  min-width: 100%;
  border-radius: 0;
  text-align: left;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
  background-color: var(--btn-bg-wht, #fff);
  background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  box-shadow: none;
  &::before {
    opacity: 1;
  }
}
.btn-wh a i {
  display: block;
  position: absolute;
  inset: 2px;
  z-index: -1;
  background: var(--bg-wht);
  border-radius: 100px;
  opacity: 1;
  &::before {
    display: none;
  }
}
.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}
@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}

/* --- btn-line --- */
.btn-line a {
  background-color: #00B900;
  max-width: 100%;
  width: fit-content;
  &::before {
    display: none;
  }
}

/* --- btn-magazine --- */
.btn-magazine a {
  max-width: 100%;
  width: fit-content;
  border: 1px solid #b21141;
  background-color: #fff;
  color: #b21141;
  font-size: rem(16);
  line-height: 1.7;
  &::before {
    background-image: none;
  }
}

.btn-ctr {
  text-align: center;
}

.txt-link {
  display: inline-block;
}
