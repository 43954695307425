@use "../abstracts" as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.lps_sec {
  position: relative;
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  z-index: 0;
  height: 100%;
  overflow: hidden;
  mix-blend-mode: multiply;
}
.lps_sec-bg .bg {
  position: absolute;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
}

// bg-circle
.bg-circle {
  position: absolute;
  width: vw(879);
  height: auto;
  aspect-ratio: 879/882;
  animation: 100s rotate linear infinite;
  overflow: hidden;
  @include mq(sp) {
    width: spvw(264);
  }
}
.bg-circle svg {
  width: 100%;
  height: 100%;
}

// bg
.bg-lft {
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 50%;
  height: 100%;
  pointer-events: none;
}
.bg-lft .bg-circle {
  left: vw(-702);
}
.bg-rgt {
  position: absolute;
  right: 0;
  overflow: hidden;
  width: 50%;
  height: 100%;
  pointer-events: none;
}
.bg-rgt .bg-circle {
  right: vw(-702);
  top: vw(210);
}

// .lps_sec-bg-lft
.lps_sec-bg-lft {
  width: vw(498);
  @include mq(sp) {
    width: spvw(192);
  }
}
.lps_sec-bg-lft .bg {
  left: 0;
  background-image: url(../images/common/bg-lft.jpg);
  aspect-ratio: 498/1010;
}
.lps_sec-bg-lft .bg:first-child {
  top: vw(-200);
}
.lps_sec-bg-lft .bg:nth-child(2) {
  bottom: min(10%, 1000px);
}
.lps_sec-bg-lft .bg-circle {
  left: vw(-702);
  top: vw(290);
  @include mq(sp) {
    left: spvw(-195);
  }
}

// .lps_sec-bg-rgt
.lps_sec-bg-rgt {
  width: vw(484);
  @include mq(sp) {
    width: spvw(202);
  }
}
.lps_sec-bg-rgt .bg {
  right: 0;
  background-image: url(../images/common/bg-rgt.jpg);
  aspect-ratio: 484/1010;
}
.lps_sec-bg-rgt .bg:first-child {
  top: vw(600);
}
.lps_sec-bg-rgt .bg:nth-child(2) {
  bottom: min(5%, 200px);
}
.lps_sec-bg-rgt .bg-circle {
  right: vw(-702);
  top: vw(210);
  @include mq(sp) {
    right: spvw(-216);
  }
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
  @include mq(sp) {
    margin-top: 40px;
  }
}
.lps_parts--img_text .lps_parts:has(.bg-wh) + .lps_parts:has(.bg-wh) {
  margin-top: 40px;
  @include mq(sp) {
    margin-top: 24px;
  }
}
.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
  @include mq(sp) {
    margin-top: 16px;
  }
}
.lps_parts--button + .lps_parts--button {
  margin-top: 0;
  @include mq(sp) {
    margin-top: 8px;
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}
.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 24px;
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
