@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 111px;
  z-index: z(header);
  mix-blend-mode: exclusion;
  transition: var(--transit-default);
  @include mq(sp) {
    height: 64px;
  }
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: 24px;
    @include mq(med) {
      padding-left: 16px;
    }
    @include mq(sp) {
      align-items: center;
    }
  }
}
.header-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z(top);
  width: 100%;
  height: 111px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 32px rgba(103, 15, 39, 0.1);
  transition: var(--transit-default);
  opacity: 0;
  @include mq(sp) {
    height: 64px;
  }
}
.header.active {
  mix-blend-mode: normal;
}
.header--logo {
  z-index: z(header);
  transition: var(--transit-default);
  font-weight: normal;
  @include mq(sp, min) {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
  }
}
.header--logo svg {
  display: block;
  width: 235px;
  aspect-ratio: 234.3/18.5;
  fill: var(--bg-wht);
  @include mq(sp) {
    width: auto;
    height: 1rem;
  }
}
.header--logo span {
  display: block;
  margin-top: 1em;
  font-size: rem(13);
  letter-spacing: 0.13em;
  line-height: var(--line-height-none);
  @include mq(sp, min) {
    text-align: center;
  }
  @include mq(sp) {
    margin-top: 0.7em;
    font-size: sprem(11);
  }
}
.header--logo a {
  color: var(--clr-wht);
}
.header--logo a,
.footer--logo a {
  display: block;
  &:hover {
    opacity: 0.7;
  }
}

body:not(.home) {
  .header {
    border-bottom: 1px solid rgba(171, 154, 175, 0.2);
  }
}
@include mq(sp, min) {
  body.home {
    .header--logo {
      opacity: 0;
    }
    .header-sm .header--logo {
      opacity: 1;
    }
  }
}
.header-sm {
  border-bottom: 1px solid rgba(171, 154, 175, 0.2);
  + .header-bg {
    opacity: 1;
  }
}
@include mq(med) {
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}

/* gnav
********************************************** */
.gnav {
  position: fixed;
  z-index: z(top);
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 111px;
  color: var(--clr-wht);
  background-color: #a1051d;
  background-image: url(../images/common/gnav-bg.jpg);
  background-position: center;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  transition: var(--transit-default);
  overflow: auto;
  @include mq(sp) {
    padding-top: 64px;
    background-image: url(../images/common/gnav-bg-sp.jpg);
  }
}
.gnav.active {
  opacity: 1;
  pointer-events: auto;
}
.gnav a {
  color: var(--clr-wht);
}
.gnav--btm {
  margin-top: auto;
  padding-inline: rem(60);
  padding-bottom: rem(53);
  font-size: rem(12);
  @include mq(sp) {
    align-items: center;
    flex-direction: column-reverse;
    gap: 0.5em;
    padding: sprem(24);
    font-size: sprem(12);
    border-top: 1px solid rgb(171 154 175 / 20%);
    margin-bottom: sprem(64);
  }
}
.gnav--btm-link {
  gap: 2em;
  @include mq(sp) {
    flex-wrap: wrap;
    gap: 0;
    li {
      width: 50%;
      padding-bottom: 6px;
    }
  }
}
.gnav--menu {
  width: 83%;
  margin-inline: auto;
  flex: 1;
  padding-block: vw(80);
  @include mq(sp, min) {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    column-gap: vw(130);
    grid-template-areas:
      "catch column-1 column-2"
      "sub_nav column-1 column-2";
  }
  @include mq(1600) {
    width: 90%;
    column-gap: vw(80);
  }
  @include mq(sp) {
    width: 88%;
  }
}
.gnav--intro {
  @include mq(sp, min) {
    grid-area: catch;
  }
}
.gnav--intro-sns {
  @include mq(sp) {
    display: flex;
    gap: sprem(16);
    flex-direction: row-reverse;
    margin-bottom: sprem(20);
  }
}

.gnav--catch {
  width: fit-content;
  font-size: rem(40);
  line-height: 1.5;
  letter-spacing: 0.21em;
  white-space: nowrap;
  @include mq(1600) {
    font-size: rem(40);
  }
  @include mq(sp, min) {
    font-feature-settings: normal;
  }
  @include mq(sp) {
    font-size: sprem(18);
    @include lh(18, 34);
    letter-spacing: 0.15em;
  }
}
.gnav--item:not(:last-child) {
  margin-bottom: rem(50);
}
.gnav_sub {
  margin-top: rem(32);
  @include mq(sp) {
    display: none;
  }
}
.gnav--item-list {
  font-size: rem(18);
  @include lh(18, 36);
  @include mq(sp) {
    font-size: sprem(15);
  }
  &.column-2 {
    column-count: 2;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}
.gnav--item-list a {
  display: flex;
  align-items: center;
  gap: rem(23);
  &::before {
    content: "";
    width: rem(16);
    height: 1px;
    background-color: var(--bg-wht);
  }
  br {
    display: none;
  }
  .icon-arrow {
    display: none;
  }
}
.gnav--sub_nav {
  font-size: rem(15);
  @include lh(15, 36);
  @include mq(sp, min) {
    grid-area: sub_nav;
  }
  @include mq(sp) {
    margin-top: sprem(24);
    font-size: sprem(12);
  }
}
.gnav--sub_nav ul {
  column-count: 2;
  break-inside: avoid;
}
.gnav--sub_nav a {
  display: flex;
  gap: 1rem;
  margin-inline: -0.5em;
  padding: 0.5em;
  line-height: 1.3;
}
.gnav--column {
  &.column-1 {
    @include mq(sp, min) {
      grid-area: column-1;
    }
    @include mq(sp) {
      display: flex;
      flex-direction: column-reverse;
      gap: 50px;
    }
  }
  &.column-2 {
    @include mq(sp, min) {
      grid-area: column-2;
    }
  }
}
.gnav--link {
  display: grid;
  place-items: center;
  height: 100%;
  padding: 0 1em;
  text-align: center;
  color: var(--clr-wht);
  @include mq(sp) {
    padding: 0 0.5em;
  }
}
.gnav--ttl {
  position: relative;
  display: flex;
  gap: rem(16);
  font-size: rem(24);
  letter-spacing: 0.09em;
  line-height: var(--line-height-none);
  @include mq(sp) {
    font-size: sprem(18);
  }
  &::before {
    content: "";
    display: block;
    flex-shrink: 0;
    width: 4px;
    background-color: var(--bg-wht);
    border-radius: 100px;
  }
}
.gnav--ttl a {
  margin: -1em;
  padding: 1em;
  flex: 1;
  @include mq(sp) {
    margin-right: calc(12.5px + 1em);
  }
}

.header--nav {
  position: relative;
  z-index: z(header);
  font-size: clamp(14px, vw(16), 16px);
  line-height: var(--line-height-none);
  letter-spacing: 0.05em;
}
.language_btn {
  color: var(--clr-wht);
  font-size: rem(14);
  border: 1px solid rgb(255 255 255 / 50%);
  padding: 6px 14px 6px 16px;
  @include mq(sp) {
    margin-right: 8px;
  }
}

@include mq(1500) {
  .header--nav .menu-item {
    display: none;
  }
}
.header--nav .icon-heart {
  width: 27px;
}
// search-keyword
.search-keyword {
  width: min(100%, 400px);
  input.searchtext + .caret {
    position: absolute;
    left: calc(1rem + 3px);
    top: calc(1rem - 1px);
    width: 1px;
    height: calc(1rem + 2px);
    background-color: var(--bg-wht);
    animation: caret 1s linear infinite;
    pointer-events: none;
  }
  input.searchtext:focus + .caret {
    display: none;
  }
}
.search-keyword.txt-ctr {
  margin-inline: auto;
}
.search-keyword input {
  width: 100%;
  padding: rem(9) rem(36) rem(9) rem(18);
  font-size: rem(18);
  font-family: var(--font-yu-cothic);
  border: none;
  border: 1px solid rgb(0 0 0 / 50%);
  border-radius: rem(8);
  background: none;
  outline: none;
  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
}
.search-keyword button svg {
  fill: var(--bg-blk);
}
.search-keyword button {
  position: absolute;
  right: 0;
  bottom: 0;
  background: none;
  border: none;
  height: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  svg {
    width: 17px;
    height: auto;
    aspect-ratio: 1;
    fill: currentColor;
  }
}

// gnav--search
.gnav--search {
  .search-keyword {
    width: 100%;
  }
  .search-keyword input {
    color: var(--clr-wht);
    border: 1px solid rgb(255 255 255 / 50%);
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .search-keyword button svg {
    fill: var(--bg-wht);
  }
}
.gnav--search-more {
  display: flex;
  align-items: center;
  gap: 0.5em;
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.gnav--search-list {
  gap: 8px;
}
.gnav--search-list a {
  display: inline-block;
  padding: 0.7em;
  color: var(--clr-wht);
  font-size: rem(14);
  line-height: var(--line-height-none);
  border-radius: 4px;
  background-color: rgba(200, 25, 45, 0.75);
}

// gnav_btn
.gnav_btn {
  position: relative;
  z-index: z(header);
  width: 70px;
  height: 111px;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  @include mq(sp, min) {
    margin-right: 16px;
  }
  @include mq(sp) {
    width: 54px;
    height: 64px;
  }
}
.header.active {
  .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(11px) rotate(-35deg);
    @include mq(sp) {
      transform: translateY(8px) rotate(-35deg);
    }
  }
  .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-10px) rotate(35deg);
    @include mq(sp) {
      transform: translateY(-8px) rotate(35deg);
    }
  }
}
.gnav_btn--lines {
  position: relative;
  width: 36px;
  height: 24px;
  @include mq(sp) {
    width: 26px;
    height: 18px;
  }
}
.gnav_btn--lines span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--bg-wht);
  transition: var(--transit-default, all 0.4s ease-out);
  &:nth-of-type(1) {
    top: 0;
  }
  &:nth-of-type(2) {
    top: calc(50% - 1px / 2);
  }
  &:nth-of-type(3) {
    bottom: 0;
  }
}

@include mq(med, min, ps) {
  .gnav,
  .gnav ul,
  .gnav a {
    height: 100%;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
  .gnav--menu > li.current-menu-item > .gnav--link {
    color: var(--clr-wht, #fff);
    background-color: var(--clr-body, #333);
  }
  .gnav--contact a {
    border-radius: 0;
  }
  /* hover */
  .gnav--menu .menu-item-has-children:hover .gnav--menu-sub {
    opacity: 1;
    pointer-events: auto;
  }
}

@include mq(sp) {
  .gnav_sub_toggle {
    position: absolute;
    top: calc(50% - 45px / 2);
    right: -12.5px;
    width: 45px;
    height: 45px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-wht);
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 20px;
      height: 2px;
      background-color: currentColor;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}
.home .lps_sec-bg-lft {
  width: vw(655);
}
.home .lps_sec-bg-lft span {
  background-image: url(../images/bg-lft-top.jpg);
  aspect-ratio: 655/1010;
}
.home .lps_sec-bg-rgt {
  width: vw(519);
}
.home .lps_sec-bg-rgt span {
  top: auto !important;
  bottom: 0 !important;
  background-image: url(../images/bg-rgt-top.jpg);
  aspect-ratio: 519/1151;
}

/* sns_area
********************************************** */
.sns--list a {
  display: grid;
  place-items: center;
  @include rect(40);
  &:hover {
    opacity: 0.7;
  }
}
.sns--list svg {
  @include rect(24);
}
.header {
  .sns_area {
    @include mq(sp) {
      display: none;
    }
  }
  .sns--list {
    position: relative;
    z-index: z(header);
    height: 100%;
  }
  .sns--list svg {
    @include rect(18);
    fill: var(--bg-wht);
  }
}
.gnav {
  .sns_area {
    flex: 1;
  }
  .sns_area svg {
    fill: currentColor;
    @include mq(sp) {
      width: sprem(18);
      height: sprem(18);
    }
  }
  .sns--list {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 6px;
  }
  .sns--list a {
    width: sprem(24);
    height: sprem(24);
  }
  @include mq(sp, min) {
    .sns_area {
      margin-top: rem(80);
      margin-bottom: rem(40);
    }
  }
  .sns--list {
    gap: rem(10);
  }
  .sns--list a {
    display: grid;
    place-content: center;
    width: rem(49);
    height: rem(49);
    aspect-ratio: 1;
    background-color: var(--bg-wht);
    border-radius: 50%;
    @include mq(sp) {
      width: sprem(30);
      height: sprem(30);
      padding: sprem(6);
    }
  }
}

/* fix_sideNav
**************************************** */
.fix_sideNav {
  position: fixed;
  right: 0;
  z-index: z(header);
  transition: var(--transit-default);
  translate: 100% 0;
  @include mq(sp, min, ps) {
    top: 20%;
    width: rem(104);
  }
  @include mq(sp) {
    inset-inline: 2%;
    bottom: 0;
    translate: 0 100%;
  }
  &.show {
    translate: 0 0;
  }
}
.fix_sideNav ul {
  display: flex;
  gap: rem(8);
  @include mq(sp, min, ps) {
    flex-direction: column;
  }
}
.fix_sideNav li {
  flex: 1;
}
.fix_sideNav a {
  display: block;
  width: 100%;
  padding: rem(10);
  color: #da063f;
  font-size: rem(14);
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bg-wht);
  border-radius: rem(8) 0 0 rem(8);
  box-shadow: 0 0 32px rgba(102, 15, 38, 0.15);
  @include mq(sp) {
    height: 100%;
    padding: sprem(8);
    border-radius: sprem(8) sprem(8) 0 0;
  }
}
.fix_sideNav img {
  display: block;
  max-width: rem(64);
  max-height: rem(64);
  margin-inline: auto;
  margin-bottom: rem(16);
  @include mq(sp) {
    max-width: sprem(31);
    max-height: sprem(31);
    margin-bottom: sprem(4);
  }
}

/************************************************
Win対応
************************************************/
.win .header {
  mix-blend-mode: normal;
}
.win .header a {
  color: var(--clr-blk);
}
.win .header svg {
  fill: var(--bg-blk) !important;
}
.win .gnav_btn--lines span {
  background-color: var(--bg-blk);
}

.win .header.active a {
  color: var(--clr-wht);
}
.win .header.active svg {
  fill: var(--bg-wht) !important;
}
.win .header.active .gnav_btn--lines span {
  background-color: var(--bg-wht);
}
