@use "../abstracts" as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  @include auto-margin;
  padding-left: 86px;
  counter-increment: number;
  @include mq(sp) {
    padding-left: 48px;
  }
  &:not(:last-child) {
    padding-bottom: 40px;
    @include mq(sp) {
      padding-bottom: 20px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 64px;
      left: 36px;
      display: block;
      width: 0;
      height: calc(100% - 80px);
      border-right: 3px solid var(--bg-sub, #8a6114);
      @include mq(sp) {
        top: 36px;
        left: 18px;
        height: calc(100% - 48px);
      }
    }
  }
}
.lps_parts--flow .ttl-03 {
  font-size: rem(32);
  letter-spacing: var(--ltr-space-default);
  color: var(--clr-main, #875e0e);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &::after {
    content: counter(number);
    position: absolute;
    top: -8px;
    left: -80px;
    display: flex;
    @include center-flex;
    @include rect(64);
    border-radius: 50%;
    text-align: center;
    color: var(--clr-wht, #fff);
    background-color: var(--bg-sub, #8a6114);
    font-family: var(--font-num, "Montserrat", sans-serif);
    font-size: rem(36);
    letter-spacing: 0;
    padding-bottom: 8px;
    @include mq(sp) {
      top: -6px;
      left: -48px;
      @include rect(36);
      font-size: sprem(18);
    }
  }
}

// .lps_parts--flow.type-circle
.lps_parts--flow.type-circle .flow_item {
  &:not(:last-child) {
    &::before {
      top: 37px;
      height: calc(100% - 44px);
    }
  }
}
.lps_parts--flow.type-circle .ttl-03 {
  font-size: rem(24);
  &::after {
    content: "";
    @include rect(24);
    top: 3px;
    left: -60px;
    background-color: var(--bg-wht);
    border: 3px solid var(--bg-sub);
    @include mq(sp) {
      left: -39px;
    }
  }
}

.lps_parts--flow .flow_item p {
  color: var(--clr-blk, #000);
  letter-spacing: 0.075em;
  @include lh(16, 30);
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: var(--clr-wht, #fff);
  &::after {
    color: var(--clr-main, #875e0e);
    background-color: var(--bg-wht, #fff);
  }
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: var(--clr-wht, #fff);
}
.lps_parts--flow .text {
  @include mq(sp, min, ps) {
    display: flex;
    gap: 5%;
  }
}
.lps_parts--flow .img {
  @include mq(sp, min, ps) {
    width: 35%;
  }
  @include mq(sp) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
.lps_parts--flow .mce-content-body {
  margin-bottom: 0 !important;
  @include mq(sp, min, ps) {
    flex: 1;
  }
}
