@use "../abstracts" as *;

/* deco
********************************************** */
.icon-arrow {
  display: inline-block;
  width: 6px;
  height: auto;
  aspect-ratio: 6/12;
  flex-shrink: 0;
  &::before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url(../images/common/icon-arrow.svg);
    background-size: 100%;
  }
}

.icon-heart {
  aspect-ratio: 43/38;
  &::before {
    content: "";
    display: block;
    width: 40px;
    background-image: url(../images/common/icon-heart-line.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    aspect-ratio: 43/38;
    @include mq(sp) {
      width: 24px;
    }
  }
  &.fill {
    &::before {
      background-image: url(../images/common/icon-heart-fill.svg);
    }
  }
}
.icon-light {
  display: block;
  width: rem(24);
  height: rem(24);
  margin-inline: auto;
  aspect-ratio: 1;
  flex-shrink: 0;
}

.fill-wht {
  fill: var(--bg-wht);
}
.fill-blk {
  fill: var(--bg-blk);
}
.fill-main {
  fill: var(--clr-main-sub);
}
