@use "../abstracts" as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: clamp(#{$fz-sp}px, #{perc($fz-pc, 1400, "vw")}, #{$fz-pc}px);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: $fz-sp + px;
  }
}
html.win body {
  -webkit-font-smoothing: antialiased;
}
body {
  font-family: var(
    --font-primary,
    YuGothic,
    "Yu Gothic",
    "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN",
    sans-serif
  );
  line-height: var(--line-height-default, 1.8);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, 0.07em);
  color: var(--clr-body, #333);
  font-size: rem(18);
  height: 100%;
  word-break: break-word;
  *font-size: small;
  *font: x-small;
  @include mq(sp, min, ps) {
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
ul,
ol {
  list-style: none;
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #333);
  text-decoration: none;
  transition: var(--transit-default, all 0.4s ease-out);
  outline: none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:focus {
  text-decoration: none !important;
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-hdr, 1.4);
}
svg {
  transition: var(--transit-default, all 0.4s ease-out);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
