@use "../abstracts" as *;

/* --- section_pdg --- */
.section_pdg {
  padding-block: 80px;
  @include mq(sp) {
    padding-block: 40px;
  }
}
.section_pdg-top {
  padding-top: 80px;
  @include mq(sp) {
    padding-top: 40px;
  }
}
.section_pdg-btm {
  padding-bottom: 80px;
  @include mq(sp) {
    padding-bottom: 40px;
  }
}

.section_pdg-sm {
  padding-block: 40px;
  @include mq(sp) {
    padding-block: 24px;
  }
}
.lps_sec.section_pdg {
  padding-bottom: vw(150);
  @include mq(sp) {
    padding-bottom: spvw(100);
  }
}
.page-contact {
  .lps_sec.section_pdg {
    padding-bottom: vw(80);
    @include mq(sp) {
      padding-bottom: spvw(40);
    }
  }
}
