@use "../abstracts" as *;

/* lps_parts--slide
********************************************** */
.lps_parts--slide .slick-list {
  margin-bottom: 32px;
}
.lps_parts--slide .img img {
  display: inline-block;
}
.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  @include mq(med) {
    margin-top: 16px;
  }
}

.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
  @include mq(med) {
    width: 56px;
  }
  @include mq(sp) {
    width: 40px;
  }
  &::after {
    content: "";
    background-image: url(../images/common/icon-arrow.svg);
    background-size: cover;
    display: block;
    width: rem(24);
    height: rem(48);
  }
}
.lps_parts--slide .slick-prev {
  left: -60px;
  @include mq(med) {
    left: 0;
  }
  &::after {
    content: "";
    transform: scale(-1, 1);
  }
}
.lps_parts--slide .slick-next {
  right: -60px;
  @include mq(med) {
    right: 0;
  }
}
.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include mq(med) {
    position: relative;
    bottom: 4px;
  }
}
.lps_parts--slide .slick-dots li {
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--clr-main);
  opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  margin: 6px;
  &.slick-active {
    background-color: var(--clr-main);
    opacity: 1;
  }
  &:hover {
    opacity: 0.7;
  }
}

// slide-carousel
.lps_parts--slide .slide-carousel {
  .slick-list {
    margin-inline: rem(-16);
  }
  .slick-slide {
    margin-inline: rem(16);
  }
  .slick-prev {
    left: -70px;
    @include mq(sp) {
      left: 0;
    }
  }
  .slick-next {
    right: -70px;
    @include mq(sp) {
      right: 0;
    }
  }
}

// slide-extra_large
.lps_parts--slide .slide-extra_large {
  margin-inline: calc(50% - 50vw);
  .slick-slide {
    position: relative;
  }
  .img {
    width: 100%;
    height: 800px;
    @include mq(sp) {
      height: 400px;
    }
  }
  .text {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: auto;
    margin: auto;
  }
  .ttl-03,
  .mce-content-body {
    color: var(--clr-wht);
  }
  .slick-list {
    margin-bottom: 0;
  }
  .slick-arrow::after {
    background-image: url(../images/common/icon-arrow-wh.svg);
  }
  .slick-prev {
    left: 40px;
    @include mq(sp) {
      left: 0;
    }
  }
  .slick-next {
    right: 40px;
    @include mq(sp) {
      right: 0;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 70px;
  }
  .slick-dots li {
    background-color: var(--bg-wht);
  }
}
