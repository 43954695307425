@use "../abstracts" as *;

/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
  &:not(:last-child) {
    @include mq(sp, min, ps) {
      margin-right: perc(80, 1080, "%");
    }
    @include mq(sp) {
      margin-bottom: 24px;
    }
  }
}
.sitemap--menu a,
.sitemap--menu span {
  display: block;
}
.sitemap--menu a {
  position: relative;
}
.sitemap--menu a:hover {
  color: var(--clr-main, #1d7fb7);
}
.sitemap--menu > li > a {
  font-size: rem(18);
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 0.5em;
  margin-bottom: 1.25em;
}
.sitemap_sub_nav--wrap {
  margin-bottom: 1.5em;
}
.sitemap_sub_nav {
  column-count: 2;
  page-break-inside: avoid;
  break-inside: avoid;
}
.sitemap_sub_nav a {
  display: flex;
  gap: 0.5rem;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
