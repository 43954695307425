@use "../abstracts" as *;

/* bg
********************************************** */
.lps_sec:nth-child(2n) {
  background-color: var(--bg-main, #f6f5f1) !important;
  padding-bottom: 150px;
}
.lps_sec:first-child .sec-round {
  display: none;
}
.sec-round {
  position: relative;
  background-color: var(--bg-main, #f6f5f1) !important;
  span {
    position: absolute;
    top: vw(-172);
    left: 0;
    width: 100vw;
    background-image: url(../images/common/bg-round.svg);
    background-size: 100% !important;
    background-repeat: no-repeat;
    aspect-ratio: 1920/172;
    @include mq(sp) {
      top: perc(-65, 520, "vw");
      background-image: url(../images/common/bg-round-sp.svg);
      aspect-ratio: 414/52;
    }
  }
  span:nth-child(2) {
    opacity: 0.5;
    top: vw(-208);
    @include mq(sp) {
      top: perc(-78, 520, "vw");
    }
  }
  span:nth-child(3) {
    opacity: 0.25;
    top: vw(-244);
    @include mq(sp) {
      top: perc(-91, 520, "vw");
    }
  }
}
.sec-round-wh span {
  background-image: url(../images/common/bg-round-wh.svg);
}
.sec-wave {
  position: relative;
  background-color: var(--bg-main, #f6f5f1) !important;
  span {
    position: absolute;
    top: vw(-150);
    left: 0;
    width: 100vw;
    background-image: url(../images/common/bg-wave.svg);
    background-size: 100% !important;
    background-repeat: no-repeat;
    aspect-ratio: 1920/150;
    @include mq(sp) {
      top: spvw(-56);
      background-image: url(../images/common/bg-wave-sp.svg);
      aspect-ratio: 414/62;
    }
  }
  span:nth-child(2) {
    opacity: 0.8;
    top: vw(-186);
    @include mq(sp) {
      top: spvw(-69);
    }
  }
  span:nth-child(3) {
    opacity: 0.75;
    top: vw(-222);
    @include mq(sp) {
      top: spvw(-82);
    }
  }
}
.sec-wave-top {
  transform: rotate(180deg);
}
.home_program >,
.program_list:first-child,
.lps_sec:nth-child(2n + 1) {
  .sec-round {
    span {
      background-image: url(../images/common/bg-round-wh.svg);
      @include mq(sp) {
        background-image: url(../images/common/bg-round-wh-sp.svg);
      }
    }
  }
}
.bg-main {
  background-color: var(--bg-main, #f6f5f1);
}
.bg-sub {
  background-color: #eee;
}
.bg-wh {
  background-color: var(--bg-wht, #fff);
}
.bg-off_wht {
  background-color: var(--bg-off_wht, #fff);
}
