@use "../abstracts/" as *;

/* hero
********************************************** */
.hero {
  position: relative;
  overflow: hidden;
  &::before,
  .hero-mask,
  .hero--cont,
  .hero--ttl {
    opacity: 0;
  }
}
.hero.loaded {
  &::before,
  .hero-mask {
    animation: fade_In ease-out 0.8s forwards;
  }
  .hero--ttl {
    animation: fade_slide_In ease-out 0.8s 0.3s forwards;
  }
  .hero--cont {
    animation: fade_slide_In ease-out 0.8s 0.6s forwards;
  }
}
.hero {
  position: relative;
  height: vw(1205);
  background-image: url(../images/hero-bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  @include mq(sp) {
    height: spvw(640);
    background-image: url(../images/hero-bg-sp.jpg);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url(../images/hero.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      background-image: url(../images/hero-sp.jpg);
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-image: url(../images/hero-btm.svg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    aspect-ratio: 1920/414;
  }
}
.hero-mask {
  position: absolute;
  inset: 0;
  mask-image: url(../images/hero-mask.png);
  mask-repeat: no-repeat;
  mask-size: cover;
  @include mq(sp) {
    mask-image: url(../images/hero-mask-sp.png);
  }
}
.hero .bg-circle {
  left: vw(98);
  top: vw(323);
  width: vw(1023);
  @include mq(sp) {
    left: spvw(-130);
    top: spvw(70);
    width: spvw(511);
  }
}
.hero--logo {
  position: absolute;
  left: 50%;
  translate: -50% 0;
  width: fit-content;
  margin-inline: auto;
  margin-top: vw(100);
  font-size: rem(13);
  text-align: center;
}
.hero--logo svg {
  display: block;
  width: 223px;
  height: auto;
  margin-bottom: 0.7em;
  aspect-ratio: 234.3/18.5;
}
.hero--ttl {
  position: absolute;
  top: vw(226);
  right: 0;
  z-index: 2;
  width: perc(704, 1800, "vw");
  @include mq(sp) {
    right: calc(50% - 50vw);
    top: spvw(134);
    width: spvw(306);
  }
}
.hero--cont {
  position: absolute;
  top: vw(703);
  inset-inline: calc(50% - 50vw);
  z-index: 1;
  width: 100%;
  padding-block: rem(10);
  padding-left: vw(242);
  @include mq(sp, min) {
    min-height: 324px;
  }
  @include mq(sp) {
    left: calc(50% - 50vw);
    top: spvw(416);
    width: 100vw;
    padding-top: sprem(10);
    padding-bottom: sprem(20);
    padding-inline: 6%;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: vw(100);
    width: vw(1279);
    height: 100%;
    background-image: url(../images/hero-txt-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    @include mq(sp) {
      top: spvw(57);
      width: 100vw;
      background-size: spvw(1279);
    }
  }
}

// hero--counter
.hero--counter {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-end;
  width: fit-content;
  margin-bottom: vw(24);
  padding: vw(24) vw(32);
  background-color: var(--bg-wht);
  background-image: url(../images/common/feather.png);
  background-repeat: no-repeat;
  background-position: top -26% left 4%;
  background-size: vw(272);
  border-radius: 0 vw(16) vw(16) 0;
  box-shadow: 0 0 32px rgba(102, 15, 38, 0.15);
  border-left: 8px solid;
  border-image: linear-gradient(to bottom, #1f1828, #cf436b, #dbddd7) 1;
  border-image-slice: 1;
  @include mq(sp) {
    width: calc(100% + spvw(22));
    margin-inline: spvw(-11);
    margin-bottom: spvw(24);
    padding: spvw(15);
    border-left: 4px solid;
    background-size: spvw(145);
  }
}
.hero--counter .ttl {
  position: relative;
  padding-right: vw(16);
  color: transparent;
  font-size: vw(32);
  line-height: 1.3;
  background: linear-gradient(138deg, #4c2302 19%, #9b7937 30%, #97732f 35%, #4c2302 76%);
  -webkit-background-clip: text;
  @include mq(sp) {
    padding-right: spvw(10);
    font-size: spvw(16);
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    inset-block: 0;
    width: 3px;
    border-inline: 1px solid #c8bfa0;
  }
}
.hero--counter .ttl span {
  display: block;
  font-size: vw(18);
  @include mq(sp) {
    font-size: spvw(11);
  }
}
.hero--counter .counter-wrap {
  display: flex;
  align-items: baseline;
  padding-left: vw(16);
  font-size: vw(68);
  line-height: 1;
  background: rgb(226, 61, 88);
  background: linear-gradient(180deg, rgba(226, 61, 88, 1) 0%, rgba(221, 27, 59, 1) 50%, rgba(185, 15, 43, 1) 100%);
  text-decoration: blink;
  color: transparent;
  -webkit-background-clip: text;
  @include mq(sp) {
    padding-left: spvw(10);
    font-size: spvw(35);
  }
}
.hero--counter .counter-wrap span {
  font-size: vw(44);
  @include mq(sp) {
    font-size: spvw(24);
  }
}

// hero--catch
.hero--catch-shadow {
  z-index: 0;
  color: transparent !important;
  text-shadow: 0 0 rem(20) #fff;
}
.hero--catch {
  font-size: vw(40);
  white-space: nowrap;
  @include mq(sp) {
    font-size: spvw(20);
  }
}
.hero--catch span {
  display: inline-block;
}
.hero--catch p {
  color: transparent;
  @include lh(52, 63);
  background: linear-gradient(138deg, #4c2302 19%, #9b7937 30%, #97732f 35%, #4c2302 76%);
  -webkit-background-clip: text;
}
.hero--catch ul {
  color: #222222;
  font-size: min(perc(24, 1600, "vw"), rem(24));
  @include lh(24, 40);
  letter-spacing: 0.03em;
  @include mq(sp) {
    font-size: spvw(14);
  }
}
.hero--catch li {
  padding-left: rem(40);
  background-image: url(../images/check.png);
  background-size: rem(34);
  background-position: 0 rem(3);
  background-repeat: no-repeat;
  @include mq(sp) {
    padding-left: sprem(20);
    background-size: sprem(14);
  }
}
.txt-red {
  color: transparent;
  background: linear-gradient(138deg, #8e0b0b 19%, #d4112f 50%, #8e0b0b 76%);
  -webkit-background-clip: text;
}
.txt-red-light {
  position: absolute;
  background-image: url(../images/hero-txt-deco.png);
  background-position: left top rem(8);
  background-repeat: repeat-x;
  background-clip: text;
  -webkit-background-clip: text;
  @include mq(sp) {
    background-position: left top -6px;
  }
}

/* home_article_line
********************************************** */
.home_article_line {
  .inner {
    display: grid;
    grid-template-columns: 1fr rem(770);
    gap: rem(16);
    border-bottom: 1px solid #c8bfa0;
    padding-bottom: rem(16);
    @include mq(sp) {
      display: flex;
      flex-direction: column;
    }
  }
  .post {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .more-link {
    order: 1;
    color: var(--clr-main);
    margin-left: rem(8);
    padding: 0.5em 1em;
    font-size: rem(14);
    line-height: 1;
    border-radius: 9999px;
    border: 1px solid var(--clr-main-sub);
    &:hover {
      color: var(--clr-wht);
      background-color: var(--clr-main-sub);
    }
  }
}
.slide-article_line {
  padding-right: rem(180);
  @include mq(sp) {
    padding-right: sprem(70);
  }
}
.slide-article_line--arrow {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  translate: 0 -50%;
  .slick-arrow {
    display: grid;
    place-content: center;
    width: rem(36);
    height: rem(36);
    &::before {
      content: "";
      width: rem(12);
      height: rem(12);
      color: var(--clr-main);
      rotate: 45deg;
    }
  }
  .slick-prev {
    &::before {
      border-left: 2px solid;
      border-bottom: 2px solid;
    }
  }
  .slick-next {
    &::before {
      border-right: 2px solid;
      border-top: 2px solid;
    }
  }
}

/* home_anchor
********************************************** */
.home_anchor {
  padding-top: vw(80);
}
.home_anchor ul {
  display: grid;
  @include mq(sp, min) {
    grid-template-columns: repeat(5, 1fr);
  }
  @include mq(sp) {
    justify-content: center;
    padding-block: rem(16);
    border-block: 3px double #c8bfa0;
  }
}
@include mq(sp, min) {
  .home_anchor li {
    border-right: 3px double #c8bfa0;
  }
  .home_anchor li:first-child {
    border-left: 3px double #c8bfa0;
  }
}
.home_anchor li a {
  display: block;
  padding: 0.5em;
  color: var(--clr-main);
  font-size: rem(22);
  @include mq(pc) {
    font-size: rem(20);
  }
  @include mq(sp) {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5em;
    font-size: sprem(22);
  }
  &::before {
    @include mq(sp) {
      display: none;
    }
  }
}
.home_anchor li .font-num {
  line-height: var(--line-height-none);
  letter-spacing: 0;
  @include mq(sp) {
    font-size: sprem(22);
  }
}
.home_anchor li .font-jp {
  line-height: 1.5;
  letter-spacing: 0.03em;
  @include mq(sp, min) {
    margin-top: 1rem;
  }
}

/* home_article_cat
********************************************** */
.article_cat--bg {
  .bg-lft .bg-circle {
    left: vw(-346);
    @include mq(sp) {
      left: spvw(-150);
    }
  }
  .bg-rgt .bg-circle {
    right: vw(-236);
    @include mq(sp) {
      right: spvw(-150);
    }
  }
}
.home_article_cat {
  background-image: url(../images/article_cat-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
}
.home_article_cat .section_ttl img {
  width: rem(930);
  height: auto;
  @include mq(sp) {
    width: 80%;
  }
}
.home_article_cat--intro {
  position: relative;
  width: 90%;
  max-width: 1200px;
  margin-inline: auto;
}
.home_article_cat--intro .txtarea {
  margin-bottom: vw(106);
  font-size: rem(22);
  @include lh(22, 48);
  @include mq(sp, min) {
    margin-left: vw(370);
    padding-inline: 4%;
    border-inline: 4px double #c8bfa0;
  }
  @include mq(sp) {
    display: flex;
    flex-wrap: wrap;
    padding-inline: sprem(24);
    font-size: sprem(16);
    text-align: justify;
    @include lh(16, 30);
  }
  &::before {
    content: "";
    width: vw(318);
    aspect-ratio: 318/436;
    background-image: url(../images/article_cat-img.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    @include mq(sp, min) {
      position: absolute;
      left: vw(67);
      bottom: vw(-170);
    }
    @include mq(sp) {
      order: 1;
      width: sprem(144);
      margin-right: calc(50% - 50vw);
      margin-bottom: sprem(-100);
      margin-top: sprem(-20);
      background-image: url(../images/article_cat-img_sp.png);
      aspect-ratio: 288/464;
    }
  }
}
@include mq(sp) {
  .home_article_cat--intro p:last-child {
    flex: 1;
  }
}
.article_cat {
  display: grid;
  gap: rem(29);
  @include mq(sp, min) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mq(sp) {
    gap: sprem(10);
  }
}
.article_cat a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: vw(28) vw(40);
  padding-left: perc(190, 2100, "vw");
  border-left: 8px solid;
  border-image: linear-gradient(to bottom, #1f1828, #cf436b, #dbddd7) 1;
  border-image-slice: 1;
  background-color: var(--bg-wht);
  border-radius: 0 16px 0 0;
  box-shadow: 0 0 52px rgba(103, 15, 39, 0.15);
  @include mq(sp) {
    padding: 0.5em 1em;
    padding-left: sprem(86);
    border-left: 4px solid;
    box-shadow: 0 0 10px rgba(103, 15, 39, 0.15);
  }
  &::before {
    content: "";
    position: absolute;
    left: vw(40);
    top: 50%;
    display: block;
    width: vw(100);
    height: vw(100);
    background-image: var(--icon);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    translate: 0 -50%;
    @include mq(sp) {
      left: sprem(15);
      width: sprem(50);
      height: sprem(50);
    }
  }
  .icon-arrow {
    width: rem(12);
    height: rem(24);
    fill: #999;
  }
}
.cat-ttl {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.cat-txt {
  opacity: 0.5;
  @include mq(sp) {
    font-size: sprem(14);
  }
}

/* home_article
********************************************** */
.home_article {
  padding-bottom: vw(160);
  min-height: perc(1009, 1920, "vw");
  background-image: url(../images/article-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
  @include mq(sp) {
    padding-top: 0;
  }
}
.home_article .inner {
  max-width: 1200px;
}
.home_article .section_ttl img {
  width: rem(872);
  height: auto;
  @include mq(sp) {
    width: 80%;
  }
}
.home_article .posts-article {
  background-color: var(--bg-wht);
  box-shadow: 0 0 52px rgba(103, 15, 39, 0.15);
}
.home_article .posts-article .post {
  padding: rem(25) rem(40);
  @include mq(sp) {
    padding: 1rem;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #eedfdd;
  }
}

/* column_box
********************************************** */
.column_box {
  display: grid;
  gap: vw(50);
  @include mq(sp, min) {
    grid-template-columns: vw(526) 1fr;
  }
}
.column_box--ttl img {
  display: block;
}
@include mq(sp) {
  .column_box--more {
    margin-top: sprem(16);
  }
}
.column_box--more a {
  font-size: rem(22);
  @include mq(sp) {
    display: block;
    width: fit-content;
    margin-inline: auto;
    font-size: sprem(16);
  }
}
.section_ttl span {
  display: block;
  letter-spacing: 0.1em;
  @include mq(sp) {
    &.fz-56 {
      font-size: sprem(36);
    }
  }
}
.home_voice .column_box--ttl img,
.home_news .column_box--ttl img {
  width: auto;
  height: rem(53);
}
.home_youtube .column_box--ttl img.ttl {
  width: auto;
  height: rem(78);
}
.home_youtube .column_box--ttl img.balloon {
  width: auto;
  height: rem(60);
}
@include mq(sp) {
  .home_voice .column_box--ttl img,
  .home_news .column_box--ttl img,
  .home_youtube .column_box--ttl img.ttl,
  .home_youtube .column_box--ttl img.balloon {
    height: sprem(26);
    margin-inline: auto;
  }
  .home_youtube .column_box--ttl img.balloon {
    height: sprem(40);
    margin-bottom: 8px;
  }
  .home_youtube .column_box--ttl img.ttl {
    height: sprem(70);
  }
}

/* home_flow
********************************************** */
.home_flow {
  padding-bottom: rem(150);
  background-image: url(../images/flow-bg01.jpg), url(../images/flow-bg02.jpg), url(../images/flow-bg03.jpg);
  background-position: top left, top 873px left, top 2014px left;
  background-repeat: no-repeat;
  background-size: 1920px;
  @include mq(sp) {
    padding-bottom: sprem(40);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 1920/1200;
    background-image: url(../images/flow-bg-btm.png);
  }
}
.home_flow .inner {
  position: relative;
  z-index: 1;
  max-width: 1150px;
}
.home_flow .btn a {
  width: min(100%, 280px);
  padding: rem(16) rem(36);
  font-size: rem(18);
  @include mq(sp) {
    padding: sprem(8) sprem(20);
    font-size: sprem(14);
  }
  &::after {
    content: "";
    right: rem(24);
  }
}
.okuraku_flow--img {
  position: relative;
  flex-shrink: 0;
  width: fit-content;
  @include mq(sp) {
    width: 30%;
  }
}
.okuraku_flow--img img {
  position: relative;
  z-index: 1;
}
.okuraku_flow li {
  display: flex;
  gap: vw(46);
  max-width: 940px;
  @include mq(sp, min) {
    width: 80%;
    min-height: 232px;
  }
  &:not(:last-child) {
    padding-bottom: rem(64);
    @include mq(sp) {
      padding-bottom: sprem(32);
    }
  }
  @include mq(sp, min) {
    &:not(:last-child) {
      .okuraku_flow--img::before {
        content: "";
        position: absolute;
        top: calc(100% - rem(50));
        left: calc(50% - 4px / 2);
        width: 4px;
        bottom: calc(-100% - rem(100));
        background-color: #b2c8d1;
        transform-origin: top center;
      }
    }
    &:nth-child(2n + 1) {
      margin-left: auto;
      .okuraku_flow--img::before {
        transform: rotate(40deg);
      }
    }
    &:nth-child(n + 3) {
      .okuraku_flow--img::before {
        background-color: #e62662;
      }
    }
    &:nth-child(2n) {
      .okuraku_flow--img::before {
        transform: rotate(-40deg);
      }
    }
  }
}
@include mq(sp) {
  .okuraku_flow li {
    position: relative;
    &:not(:last-child)::before {
      content: "";
      position: absolute;
      left: 15%;
      width: 2px;
      top: 0;
      bottom: sprem(-50);
      background-color: #b2c8d1;
    }
    &:nth-child(4),
    &:first-child {
      &::before {
        top: sprem(50);
      }
    }
    &:nth-last-child(2) {
      &::before {
        bottom: 0;
      }
    }
    &:nth-child(n + 4) {
      &::before {
        background-color: #e62662;
      }
    }
  }
  .okuraku_flow--flow2 .okuraku_flow li::before {
    display: none;
  }
}
.okuraku_flow--icon {
  display: inline-block;
  width: fit-content;
  color: var(--clr-wht);
  font-size: rem(24);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 80%, 0 100%);
  background-image: linear-gradient(135deg, #d5ae35, #875909);
  padding: 18px 11px 22px;
  flex-shrink: 0;
  @include mq(sp) {
    font-size: sprem(13) !important;
    padding: sprem(10) sprem(6) sprem(18);
  }
}
.okuraku_flow--ttl {
  display: flex;
  align-items: flex-start;
  gap: rem(16);
}
.okuraku_flow--ttl span {
  display: block;
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.okuraku_flow--ttl span i {
  display: block;
  width: fit-content;
  padding: 0.5em;
  color: var(--clr-wht);
  font-size: rem(14);
  line-height: var(--line-height-none);
  background-color: var(--bg-red);
  border-radius: 4px;
}
@include mq(sp) {
  .okuraku_flow--lead {
    font-size: sprem(20);
  }
}
.okuraku_flow--ttl .font-en {
  margin-top: 0.5em;
  @include mq(sp) {
    display: none;
  }
}
@include mq(sp) {
  .okuraku_flow--txt {
    font-size: sprem(13);
  }
}
.okuraku_flow--flow2 {
  padding-top: rem(160);
  @include mq(sp) {
    padding-top: sprem(60);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    z-index: -1;
    width: 130%;
    border-radius: 50%;
    background-color: var(--bg-wht);
    aspect-ratio: 1.8/1;
    @include mq(sp) {
      display: none;
    }
  }
  &::after {
    width: calc(130% + 80px);
    opacity: 0.5;
  }
  .okuraku_flow li {
    .okuraku_flow--img::before {
      display: none;
    }
    &:nth-child(2n + 1) {
      margin-left: 0;
    }
    &:nth-child(2n) {
      margin-left: auto;
    }
  }
}

/* home_program
********************************************** */
.bg-program {
  background-image: url(../images/program-bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
  @include mq(sp) {
    background-image: url(../images/program-bg-sp.jpg);
    background-position: bottom;
    background-size: 100%;
  }
}
.home_program .section_ttl img {
  width: auto;
  height: rem(76);
  @include mq(sp) {
    height: sprem(30);
  }
}
.home_program--lead {
  color: transparent;
  background: linear-gradient(138deg, #483008 0%, #5f4c24 50%, #483008 100%);
  -webkit-background-clip: text;
  font-size: rem(36);
  @include lh(40, 56);
  letter-spacing: 0.11em;
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.program_list.cat-okuraku {
  padding-bottom: vw(160);
  @include mq(sp) {
    padding-bottom: spvw(70);
  }
}
.program_list.cat-english {
  background-image: url(../images/program-bg-english.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
  .program_list--bg {
    display: none;
  }
}
.program_list--ttl {
  @include mq(sp) {
    font-size: sprem(32);
  }
}
@include mq(sp) {
  .program_list--txt br {
    display: none;
  }
}
.home_program .program_list.cat-okuraku {
  .sec-round {
    display: none;
  }
}
.home_program .program_list.cat-english {
  padding-bottom: rem(120);
  @include mq(sp) {
    padding-bottom: rem(48);
  }
}

/* sec-become
********************************************** */
.sec-become {
  position: relative;
  z-index: -1;
  overflow: hidden;
  padding-top: vw(320);
  padding-bottom: vw(410);
  color: #fff2de;
  background-image: url(../images/become-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    padding-top: spvw(80);
    padding-bottom: spvw(140);
    background-image: url(../images/become-bg_sp.jpg);
  }
}
.sec-become .inner {
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 70%;
    z-index: -1;
    width: 70%;
    aspect-ratio: 1;
    background-color: #677f1d;
    border-radius: 50%;
    filter: blur(40px);
    translate: -50% -50%;
    z-index: -1;
    opacity: 0.5;
  }
}
.sec-become img {
  width: rem(850);
  height: auto;
  @include mq(sp) {
    width: 80%;
  }
}
.sec-become-txt {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(12);
    margin-bottom: 1em;
  }
}

/* home_story
********************************************** */
.home_story {
  position: relative;
  background-image: url(../images/story-bg.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
  @include mq(sp) {
    background-image: url(../images/story-bg-sp.jpg);
    background-size: 100%;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: vw(-180);
    width: 100%;
    background-image: url(../images/story-bg-btm.png);
    background-position: center bottom;
    background-size: max(100%, 414px);
    aspect-ratio: 1920/358;
    pointer-events: none;
  }
}
.home_story .section_ttl img {
  width: auto;
  height: rem(60);
  @include mq(sp) {
    height: sprem(80);
  }
}
.home_story--ttl {
  @include mq(sp, min) {
    position: absolute;
    right: vw(73);
    top: vw(-100);
    z-index: 1;
    aspect-ratio: 121/534;
    width: vw(121);
  }
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.home_story--ttl img {
  width: 100%;
}
.home_story .more-cont {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 80px;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    width: rem(862);
    aspect-ratio: 862/300;
    background-color: var(--bg-wht);
    border-radius: 50%;
    filter: blur(40px);
    translate: -50% -50%;
    @include mq(sp) {
      width: calc(100% - 130px);
      aspect-ratio: 1;
    }
  }
}
.home_story .more-cont h3 {
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.home_story .more-cont img {
  width: rem(792);
  height: auto;
}
/* home_movie--child
********************************************** */

.home_movie--child {
  position: relative;
  width: 14rem;
  top: 0;
  left: 0;
  height: auto;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.home_movie--child a::before {
  content: "";
  position: absolute;
  top: calc(50% - 26px / 2);
  left: calc(50% - 36.5px / 2);
  z-index: 1;
  display: block;
  width: 36.5px;
  height: 26px;
  background-image: url(../images/common/icon-youtube-color.svg);
}
.home_movie--child img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100% !important;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
@include mq(sp) {
  .home_movie {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .home_movie--child {
    width: 11rem;
    img {
      margin-left: 0 !important;
      margin-right: 0 !important;
      height: 100% !important;
    }
  }
}

/* home_voice
********************************************** */
.home_voice .inner {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: vw(216);
    background-image: url(../images/voice-bg.png);
    background-size: contain;
    aspect-ratio: 216/324;
    @include mq(sp) {
      display: none;
    }
  }
}
@include mq(sp) {
  .home_voice img {
    width: 100vw;
    max-width: initial;
    margin-inline: calc(50% - 50vw);
    height: auto;
  }
}

/* home_news
********************************************** */
@include mq(sp) {
  .home_news {
    padding-bottom: spvw(40);
  }
}
.home_news .news_list {
  flex: 1;
}
.news_list--ttl {
  display: block;
  position: relative;
  margin-right: 40px;
  padding: 0;
  line-height: 1.2;
  @include mq(sp) {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: 16px;
  }
}

/* home_youtube
********************************************** */
@include mq(sp) {
  .home_youtube {
    padding-bottom: spvw(24);
  }
}
.youtube_box img {
  margin-bottom: rem(24);
  border-radius: 16px;
}
.youtube_box--ttl {
  font-size: rem(15);
  font-weight: bold;
  font-family: var(--font-secondary);
}
.youtube_box--time {
  color: #767676;
  font-size: rem(12);
}

/* cta
********************************************** */
.cta {
  background-image: url(../images/common/cta-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    background-image: url(../images/common/cta-bg_sp.jpg);
  }
}
.cta .inner {
  z-index: 0;
  color: #a08043;
  padding-top: vw(290);
  padding-bottom: vw(320);
  @include mq(sp) {
    padding-top: spvw(100);
    padding-bottom: spvw(120);
  }
}
.cta-en {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(12);
    margin-bottom: 1em;
  }
}
.home .cta .inner {
  padding-bottom: vw(150);
  @include mq(sp) {
    padding-bottom: spvw(48);
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: -1;
      width: 100%;
      aspect-ratio: 1;
      background-color: var(--bg-wht);
      border-radius: 50%;
      filter: blur(40px);
      translate: -50% -50%;
      z-index: -1;
      opacity: 0.5;
    }
  }
}
.cta svg {
  fill: #fff;
}
@include mq(sp) {
  .cta-ttl {
    margin-bottom: sprem(32);
  }
}
.cta-ttl img {
  width: rem(1000);
  height: auto;
  @include mq(sp) {
    width: 80%;
  }
}
.cta-txt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: rem(20);
  white-space: nowrap;
  &::before,
  &::after {
    content: "";
    width: rem(7);
    height: rem(20);
    flex-shrink: 0;
    border-inline: 2px solid #bfa684;
  }
  &::before {
    transform: skewX(50deg);
  }
  &::after {
    transform: skewX(-50deg);
  }
}
.cta-btn {
  gap: rem(80);
  @include mq(sp) {
    gap: rem(32);
  }
}
