@use "../abstracts" as *;
@use "sass:math";

/* txt
**************************************** */
.txt-sm {
  font-size: 80%;
}
.txt-lg {
  font-size: 130%;
}
.txt-wh,
.txt-wh * {
  color: var(--clr-wht, #fff);
}
.txt-main,
.txt-main * {
  color: var(--clr-main, #1d7fb7);
}
.txt-sub,
.txt-sub * {
  color: var(--clr-sub, #eee);
}
.ltr-hdr {
  letter-spacing: var(--ltr-space-hdr);
}

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} {
    font-size: math.div($i, $fz-pc) + rem;
  }
}
@include mq(sp) {
  @for $i from 8 to 140 {
    $i2: math.div($i, 1.3);
    .fz-#{$i} {
      font-size: math.div($i2, $fz-sp) + rem;
    }
  }
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}
.font-en {
  font-family: var(--font-en, "Montserrat", sans-serif);
}
.font-num {
  font-family: var(--font-num, "Montserrat", sans-serif);
}

/* --- clip-txt --- */
// 省略行を1から4まで生成
@for $i from 1 through 4 {
  .clip-txt#{$i} {
    @include clip-txt($i);
  }
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
  .txt-ctr-sp {
    text-align: center;
  }
}
