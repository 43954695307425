@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  padding-top: 111px;
  overflow: hidden;
  background-image: url(../images/common/page_ttl-none.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-bottom: 14px solid;
  border-image: linear-gradient(to left, #f85aa5 0%, #ea5078 53%, #c40c40 100%) 1;
  @include mq(sp) {
    padding-top: 64px;
    border-bottom: 6px solid;
    background-image: url(../images/common/page_ttl-none-sp.jpg);
  }
}
.page_ttl-icon {
  display: block;
  width: vw(50);
  min-width: 30px;
  margin-inline: auto;
  margin-bottom: rem(20);
  aspect-ratio: 1;
  background-image: var(--icon);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.page_ttl-none {
  min-height: 242px;
  padding-top: 200px;
  border-bottom: none;
  @include mq(sp) {
    min-height: 102px;
    padding-top: 85px;
  }
}
.single {
  .page_ttl-none {
    margin-bottom: -46px;
    @include mq(sp) {
      margin-bottom: -20px;
    }
  }
}
.page_ttl-none > .inner {
  &::after {
    content: "";
    display: block;
    width: 195px;
    margin-inline: auto;
    background-image: url(../images/common/ttl-01-deco.svg);
    background-size: 100%;
    aspect-ratio: 209/12;
  }
}
.page_ttl--bg-wrap {
  height: 389px;
  @include mq(sp) {
    height: 256px;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url(../images/common/page_ttl-common.jpg);
  background-position: center;
  background-size: cover;
  @include mq(sp) {
    background-image: url(../images/common/page_ttl-common-sp.jpg);
  }
}
.page_ttl-jp {
  position: relative;
  font-family: var(--font-jp);
  font-size: rem(64);
  @include mq(sp) {
    font-size: sprem(32);
  }
}
.page_ttl-none .page_ttl-jp {
  font-size: rem(50);
  @include mq(sp) {
    font-size: sprem(32);
  }
}
.page_ttl-en {
  position: relative;
  display: block;
  margin-top: 0.5em;
  font-size: rem(22);
  font-family: var(--font-en, "Montserrat", sans-serif);
  letter-spacing: 0.03em;
  text-transform: uppercase;
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.page_ttl-en-small {
  text-transform: none;
}
.page_ttl--list_wrap {
  border-bottom: 1px solid rgba(171, 154, 175, 0.2);
  @include mq(sp) {
    white-space: nowrap;
    overflow: scroll;
  }
}
.page_ttl--list {
  @include mq(sp) {
    width: 100%;
    flex-wrap: nowrap !important;
  }
}
.page_ttl--list li {
  @include mq(sp, min, ps) {
    min-height: 96px;
  }
  @include mq(sp) {
    width: fit-content !important;
  }
}
.page_ttl--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em;
  gap: rem(10);
  text-align: left;
  font-size: rem(18);
  letter-spacing: 0.05em;
  line-height: 1;
  color: var(--clr-page-ttl, #b2790a);
  line-height: var(--line-height-hdr, 1.4);
  @include mq(sp) {
    padding: 1em;
    font-size: sprem(15);
  }
  &::before {
    content: "";
    flex-shrink: 0;
    width: rem(24);
    background-image: url(../images/common/icon-light.svg);
    background-size: contain;
    aspect-ratio: 1;
    @include mq(sp) {
      width: sprem(16);
    }
  }
  &:hover {
    color: var(--clr-main, #1d7fb7);
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  color: transparent;
  background: linear-gradient(138deg, rgba(138, 97, 20, 1) 0%, rgba(153, 118, 44, 1) 50%, rgba(138, 97, 20, 1) 100%);
  -webkit-background-clip: text;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
}
:is(.ttl-01, .ttl-02, .ttl-03, .ttl-04) span {
  display: block;
}
.ttl-01 {
  font-size: rem(48);
  letter-spacing: var(--ltr-space-default);
  @include lh(64, 80);
  @include mq(sp) {
    font-size: sprem(26);
  }
}
.ttl-02 {
  font-size: rem(38);
  @include lh(40, 54);
  letter-spacing: var(--ltr-space-default);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.ttl-sub {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1em;
  font-size: rem(18);
  &::before {
    content: "";
    flex: 1;
    border-bottom: 1px solid var(--brd-main);
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: rem(40);
  @include lh(40, 54);
  letter-spacing: var(--ltr-space-default);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.ttl-03 {
  font-size: rem(28);
  line-height: 1.3;
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.page_ttl-en + .ttl-03 {
  display: block;
  margin-top: 1em;
}
@include mq(sp) {
  .page_ttl-en + .ttl-03 br {
    display: none;
  }
}
.ttl-04 {
  font-size: rem(24);
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: rem(32);
  margin-bottom: 16px;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-01-sub {
  display: flex;
  font-size: rem(22);
  padding-top: 16px;
  letter-spacing: 0.03em;
  align-items: center;
  justify-content: center;
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.ttl-01-sub:before,
.ttl-01-sub:after {
  border-top: 1px solid;
  content: "";
  width: 20px;
}
.ttl-01-sub:before {
  margin-right: 10px;
}
.ttl-01-sub:after {
  margin-left: 10px;
}
.ttl-deco {
  &::before {
    content: "";
    display: block;
    width: rem(108);
    margin-bottom: rem(24);
    background: url(../images/common/ttl-deco04.svg) no-repeat;
    background-size: 100%;
    aspect-ratio: 74/15;
    @include mq(sp) {
      width: sprem(80);
    }
  }
  &.txt-ctr::before {
    margin-inline: auto;
  }
}

.ttl-gold {
  color: transparent;
  background: linear-gradient(138deg, rgba(138, 97, 20, 1) 0%, rgba(153, 118, 44, 1) 50%, rgba(138, 97, 20, 1) 100%);
  -webkit-background-clip: text;
}
.ttl-gold2 {
  color: transparent;
  background: linear-gradient(180deg, #9d7b39 0%, #724f0c 100%);
  -webkit-background-clip: text;
}
.ttl-brown {
  color: transparent;
  background: linear-gradient(138deg, #483008 0%, #5f4c24 50%, #483008 100%);
  -webkit-background-clip: text;
  letter-spacing: 0.11em;
}
