@use "../abstracts" as *;

/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-wh .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table { 
  width: 100%;
}
.lps_parts--img_text .lps_parts--child {
  &:not(.bg-wh) {
    gap: 3%;
    align-items: flex-start;
  }
}
.lps_parts--img_text .img_text--img {
  width: 31.33%;
  @include mq(sp) {
    width: 100%;
    margin-bottom: 24px;
  }
}
.lps_parts--img_text .img_text--txt {
  width: 65.66%;
  @include mq(sp) {
    width: 100%;
  }
}
.lps_parts--img_text .img_text--child .img {
  height: 100%;
}
.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  @include auto-margin;
}
.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5%;
  @include mq(sp) {
    padding: 24px;
  }
}
.lps_parts--img_text .bg-wh .img_text--img {
  @include mq(sp) {
    margin-bottom: 0;
  }
}
.lps_parts--img_text .inner.bg-wh:not(.inner-lg) {
  box-shadow: 0 0 40px rgb(103 15 38 / 15%);
}
.lps_parts--img_text .img_text--txt p {
  letter-spacing: 0.1em;
  color: var(--clr-blk, #000);
}
.lps_parts--img_text .ttl-03 {
  font-size: rem(32);
  letter-spacing: var(--ltr-space-default);
  @include lh(32, 46);
  color: var(--clr-main, #875e0e);
}

/* --- L --- */
.lps_parts--img_text .inner-lg {
  .img_text--img {
    position: absolute;
    top: 0;
    @include mq(sp, min, ps) {
      height: 100%;
    }
    @include mq(sp) {
      position: relative;
    }
  }
  &:not(.flex-reverse) .img_text--img {
    left: 0;
  }
  &.flex-reverse .img_text--img {
    right: 0;
  }
  .img_text--txt {
    @include mq(sp, min, ps) {
      min-height: 607.64px;
    }
    @include mq(sp) {
      margin-right: auto;
    }
  }
  &:not(.flex-reverse) .img_text--txt {
    margin-left: auto;
  }
  &.flex-reverse .img_text--txt {
    margin-right: auto;
  }
  &.bg-wh .img_text--txt {
    @include mq(sp, min, ps) {
      padding: 6%;
    }
    @include mq(sp) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
    }
  }
}

// 角丸設定 ==================================

// /* 白背景 */
// .lps_parts--img_text .lps_parts--child.bg-wh {
//   border-radius: rem(20);
//   .img_text--img img {
//     border-radius: rem(20) 0 0 rem(20);
//     @include mq(sp) {
//       border-radius: rem(20) rem(20) 0 0;
//     }
//   }
//   @include mq(sp, min) {
//     &.flex-reverse .img_text--img img {
//       border-radius: 0 rem(20) rem(20) 0;
//     }
//   }
// }

// /* 白背景なし */
// .lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--img img {
//   border-radius: rem(20);
// }

// /* レイアウトL
//    ※Lも全部角丸にしたかったら削除！ */
// .lps_parts--img_text .lps_parts--child.inner-lg {
//   .img_text--img img {
//     border-radius: 0;
//   }
// }
// @include mq(sp, min) {
//   .lps_parts--img_text .lps_parts--child.inner-lg:where(:not(.bg-wh)) {
//     .img_text--img img {
//       border-radius: 0 rem(20) rem(20) 0;
//     }
//     &.flex-reverse .img_text--img img {
//       border-radius: rem(20) 0 0 rem(20);
//     }
//   }
// }

// 角丸設定 ==================================

@include mq(sp, min, ps) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }
  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@include mq(sp) {
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
