@charset "UTF-8";
:root {
  --ltr-space-default: 0.07em;
  --ltr-space-hdr: 0;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-primary: "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";
  --font-secondary: "こぶりなゴシック W6 JIS2004", "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";
  --font-jp: "游明朝体36ポかな D", "游明朝体 Pr6N D", serif;
  --font-en: "Cormorant Garamond", serif;
  --font-num: "Cormorant", serif;
  --font-yu-cothic: "游ゴシック", "Yu Gothic";
  --fz-primary: 1.125rem;
  --fz-half: 0.5rem;
  --clr-main: #875e0e;
  --clr-main-sub: #9b7621;
  --clr-sub: #c4212b;
  --clr-list: #c8192d;
  --clr-body: #000;
  --clr-page-ttl: #b2790a;
  --clr-link: #333;
  --clr-wht: #fff;
  --clr-blk: #000;
  --clr-pink: #b7345e;
  --bg-main: #f6f5f1;
  --bg-sub: #8a6114;
  --bg-wht: #fff;
  --bg-red: #c8192d;
  --bg-off_wht: #f8f8f8;
  --bg-off_wht2: #f6f2ef;
  --bg-off_wht2: #f6f2ef;
  --bg-blk: #000;
  --btn-bg-main: var(--clr-main);
  --btn-bg-main-hvr: var(--clr-dark-blue);
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;
  --btn-clr-wht: #c8192d;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: var(--clr-dark-blue);
  --brd-main: #eadece;
  --transit-default: all 0.4s ease-out;
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes caret {
  0%, 50% {
    opacity: 0;
  }
  51%, 100% {
    opacity: 1;
  }
}
@keyframes fade_In {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade_slide_In {
  0% {
    opacity: 0;
    translate: 0 10px;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #333;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #333;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  @-moz-document url-prefix() {
    * {
      font-feature-settings: "palt";
    }
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  * {
    font-feature-settings: "palt";
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: clamp(14px, 1.1428571429vw, 16px);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: 14px;
  }
}

html.win body {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: var(--font-primary, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  line-height: var(--line-height-default, 1.8);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, 0.07em);
  color: var(--clr-body, #333);
  font-size: 1.125rem;
  height: 100%;
  word-break: break-word;
  *font-size: small;
  *font: x-small;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  body:not(.mce-content-body) {
    min-width: 1080px;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    -webkit-text-size-adjust: none;
  }
}

ul,
ol {
  list-style: none;
}

a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #333);
  text-decoration: none;
  transition: var(--transit-default, all 0.4s ease-out);
  outline: none;
}

a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus {
  text-decoration: none !important;
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-hdr, 1.4);
}

svg {
  transition: var(--transit-default, all 0.4s ease-out);
}
svg[preserveAspectRatio=none] {
  height: auto;
}

/* Wysiwyg editor
 **************************************** */
.mce-content-body {
  text-align: justify;
}

.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:where(:not(.ez-toc-list)):not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:where(:not(.ez-toc-list)):not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body ul:where(:not(.ez-toc-list)) li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2.2em;
  padding-top: 0.5em;
  line-height: 2;
  color: var(--bg-blk, #000);
}

.mce-content-body ol li {
  list-style-type: decimal-leading-zero;
}
.mce-content-body ol li::marker {
  font-family: var(--font-num);
  color: var(--clr-list, #c8192d);
  font-size: 1.5rem;
}

.mce-content-body ul:where(:not(.ez-toc-list)) li {
  list-style: none;
}
.mce-content-body ul:where(:not(.ez-toc-list)) li::before {
  content: "";
  position: absolute;
  top: 1.2em;
  left: -1.5em;
  width: 0.625rem;
  height: 0.625rem;
  background-color: var(--clr-list, #c8192d);
}

.mce-content-body .check_list li {
  margin-left: 2.2em;
  padding-top: 0.5em;
  list-style: none;
}
.mce-content-body .check_list li::before {
  content: "";
  position: absolute;
  top: 0.8em;
  left: -2.2em;
  width: 1.5rem;
  height: 1.5rem;
  background-image: linear-gradient(-35deg, #1f1828, #cf436b, #dbddd7);
}
.mce-content-body .check_list li::after {
  content: "";
  display: block;
  position: absolute;
  top: 1.3125rem;
  left: -2rem;
  width: 9px;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--bg-wht, #fff);
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body hr {
  width: 100%;
  border-top: 4px double #c8bfa0;
  border: none;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body hr {
    height: 1.1428571429rem;
  }
}

.box {
  padding: 24px 32px;
  background-color: rgba(206, 193, 141, 0.1);
  border: 1px solid rgba(151, 89, 11, 0.1);
  line-height: 2;
  letter-spacing: 0.1em;
}
.box:not(:first-child) {
  margin-top: 2em;
}
.box:not(:last-child) {
  margin-bottom: 2em;
}

.box-wh {
  background-color: var(--bg-wht, #fff);
  border: 0;
  border: 1px solid rgba(151, 89, 11, 0.1);
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img.aligncenter {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
  line-height: 2;
  letter-spacing: 0.1em;
}

.mce-content-body strong {
  font-weight: bold;
}

.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: var(--bg-off_wht);
}
.mce-content-body blockquote::before, .mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}
.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 13px;
  left: 10px;
}
.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 13px;
  right: 10px;
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}

.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

table {
  border: none;
  height: auto !important;
}

table th,
table td {
  padding: 1em;
  border: 1px solid rgba(151, 89, 11, 0.2);
  vertical-align: top;
}

table th {
  min-width: 120px;
  text-align: left;
  font-family: var(--font-secondary);
}

.mce-content-body sup {
  font-size: 0.75rem;
  color: #555;
}

.bold {
  font-weight: bold;
  color: var(--clr-sub, #c4212b);
}

.mce-content-body .bold {
  font-family: var(--font-secondary);
  color: var(--clr-body);
}

.mce-content-body .font-jp .bold {
  font-family: "游明朝体36ポかな B", "游明朝体 StdN B", serif;
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

.single-story .mce-content-body iframe {
  display: block;
  max-width: 700px;
  margin-inline: auto;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}

.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}

.scroll table {
  margin: 0;
}

.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: 0.25;
  font-size: 0.875rem;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .alignright,
.mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }

  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }

  .mce-content-body ul li::before {
    width: 0.5rem;
    height: 0.5rem;
  }

  .mce-content-body table {
    max-width: none !important;
  }

  .mce-content-body th {
    font-weight: bold;
  }

  .mce-content-body th,
.mce-content-body td {
    padding: 0.5em 1em;
  }

  .table-sp-full {
    width: 100% !important;
  }

  .table-sp-full th,
.table-sp-full td {
    flex: 1;
  }

  .table-sp-block,
.table-sp-block th,
.table-sp-block td {
    width: 100% !important;
  }

  .table-sp-block th,
.table-sp-block td {
    display: block;
  }

  .box {
    padding: 24px;
  }

  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}

.mce-content-body.wp-editor {
  padding: 24px !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
.mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(--font-jp, --font-primary, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  color: transparent;
  background: linear-gradient(138deg, rgb(138, 97, 20) 0%, rgb(153, 118, 44) 50%, rgb(138, 97, 20) 100%);
  -webkit-background-clip: text;
  letter-spacing: var(--ltr-space-default);
}
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 2em;
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}

.editor-ttl {
  font-size: 1.625rem;
  line-height: 1.35;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.7142857143rem;
  }
}

.editor-ttl2 {
  font-size: 1.5rem;
  line-height: 1.4375;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl2 {
    font-size: 1.4285714286rem;
  }
}

.editor-ttl3 {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl3 {
    font-size: 1.1428571429rem;
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: 0.875rem;
  color: #999;
}
@media screen and (max-width: 47.9375em) {
  .txt-attention {
    font-size: 0.9285714286rem;
  }
}
.txt-attention::before {
  content: "※";
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  font-size: 0.9375rem;
  padding: 1em 1.5em;
  letter-spacing: 0.1em;
}
.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 3px);
}

.table1 th {
  color: var(--clr-main, #875e0e);
}
.table1 th::before {
  left: 0;
  height: 2px;
  background-color: var(--clr-main, #875e0e);
}

.table1 td {
  color: var(--clr-blk, #000);
}
.table1 td::before {
  right: 0;
  height: 1px;
  background-color: rgba(151, 89, 11, 0.2);
}

.table2 th,
.table2 td {
  color: var(--clr-blk, #000);
}

.table2 th {
  background-color: rgba(206, 193, 141, 0.1);
}
.table2 td {
  background-color: var(--bg-wht, #fff);
}

@media screen and (max-width: 47.9375em) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }

  .table2.table-sp-block th,
.table2.table-sp-block td {
    border-bottom: 0;
  }

  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #d3d3d3;
  }
}
/* タイトル装飾 */
.ttl-deco01--txt {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  color: var(--clr-page-ttl, #b2790a);
  font-weight: bold;
  font-size: 1.125rem;
  letter-spacing: 0.05em;
}
.ttl-deco01--txt::before {
  content: "";
  flex-shrink: 0;
  width: 1.5rem;
  background-image: url(../images/common/icon-light.svg);
  background-size: contain;
  aspect-ratio: 1;
}
@media screen and (max-width: 47.9375em) {
  .ttl-deco01--txt::before {
    width: 1.1428571429rem;
  }
}

.ttl-deco03--txt {
  display: inline-block;
  font-weight: bold;
  letter-spacing: 0.12em;
  color: var(--clr-wht, #fff);
  padding: 0.625rem 1.25rem;
  line-height: var(--line-height-none) !important;
  background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
}

.ttl-deco03--txt-2 {
  background: var(--clr-main);
}

.ttl-deco04--cont {
  display: inline-block;
  width: fit-content;
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0 !important;
  color: #986708;
  padding-top: 8px;
}
.ttl-deco04--cont::before {
  content: "";
  display: block;
  width: 74px;
  height: 15px;
  margin-bottom: -15px;
  margin-inline: auto;
  background: url(../images/common/ttl-deco04.svg) no-repeat;
  background-size: 100%;
}

.ttl-deco05--cont {
  display: inline-block;
  width: fit-content;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 80%, 0 100%);
  background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  padding: 16px 26px 36px;
}

.ttl-deco05--txt {
  display: block;
  font-size: 0.75rem;
  letter-spacing: 0.12em !important;
  color: var(--clr-wht, #fff);
  margin-bottom: 0 !important;
  line-height: 1 !important;
  font-weight: bold;
}

.ttl-deco05--num {
  font-size: 2.5rem;
  letter-spacing: 0;
  color: var(--clr-wht, #fff);
}

.ttl-deco06--txt {
  font-size: 1rem;
  letter-spacing: 0.12em !important;
  color: var(--clr-list, #c8192d);
  margin-bottom: 0 !important;
  position: relative;
  line-height: 1 !important;
  font-weight: bold;
}
.ttl-deco06--txt::before {
  content: "";
  position: absolute;
  content: "";
  top: 0;
  right: -10px;
  width: 2px;
  height: 18px;
  background-color: var(--clr-list, #c8192d);
  transform: rotate(40deg);
  border-radius: 5px;
}

.ttl-deco06--num {
  font-size: 3rem;
  letter-spacing: 0.02em;
  color: var(--clr-list, #c8192d);
  line-height: 1;
  padding-left: 18px;
}

/* 装飾ボックス　その方法はコレ!! */
.box-addition01 {
  padding-block: 80px 129px;
}
.box-addition01::after {
  content: "";
  display: block;
  border-top: 4px double #c8bfa0;
}

.box-addition01 .ttl-02 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 0 !important;
  font-size: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .box-addition01 .ttl-02 {
    font-size: 1.7142857143rem;
  }
}
.box-addition01 .ttl-02::before, .box-addition01 .ttl-02::after {
  content: "";
  flex: 1;
  border-top: 4px double #c8bfa0;
}
.box-addition01 .ttl-02::after {
  left: 0;
}

.addition01--txtarea {
  position: relative;
  margin-bottom: 1em;
  padding: 3.5rem;
  font-size: 1.25rem;
  color: var(--clr-blk, #000);
  letter-spacing: 0.075em;
  line-height: 1.75;
  background-color: #faf9f3;
  background-image: url(../images/common/addition01-bg-lft.png), url(../images/common/addition01-bg-rgt.png);
  background-position: left bottom, right top;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .addition01--txtarea {
    padding: 1.7142857143rem;
    font-size: 1.2857142857rem;
  }
}

/* 装飾ボックス　コラムボックス */
.box-addition02 {
  margin-block: 3rem;
  padding: 3.5rem 4rem;
  background-color: var(--bg-wht, #fff);
  border-left: 14px solid;
  border-image: linear-gradient(to top, #f85aa5 0%, #ea5078 53%, #c40c40 100%) 1;
  border-image-slice: 1;
  box-shadow: 0 0 21px rgba(103, 15, 38, 0.15);
}
@media screen and (max-width: 47.9375em) {
  .box-addition02 {
    padding: 1.7142857143rem;
    border-left: 6px solid;
  }
}

.box-addition02 .editor-ttl2 {
  margin-top: 0.5em;
}

.addition02--txt {
  letter-spacing: 0.1em;
  line-height: 2;
}

/* next_page
********************************************** */
.next_page_list {
  box-shadow: 0 0 21px rgba(103, 15, 38, 0.15);
}
.next_page_list li:nth-child(1) {
  background-color: #e27a89;
}
.next_page_list li:nth-child(2) {
  background-color: #c93f67;
}
.next_page_list li:nth-child(3) {
  background-color: #7f2848;
}

.next_page {
  flex: 1;
}

.next_page a {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  color: var(--clr-wht);
  font-size: 2rem;
  line-height: 1.5;
  padding: 4rem;
  letter-spacing: 0.12em;
}
@media screen and (min-width: 48em) {
  .next_page a {
    height: 100%;
    min-height: 240px;
  }
}
@media screen and (max-width: 47.9375em) {
  .next_page a {
    padding: 1.7142857143rem;
    font-size: 1.4285714286rem;
  }
}
.next_page a:hover {
  opacity: 0.5;
}
.next_page a::after {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 1.5rem;
  background: url(../images/common/right-arrow.svg) no-repeat;
  background-size: cover;
  aspect-ratio: 24/48;
}
@media screen and (max-width: 47.9375em) {
  .next_page a::after {
    width: 0.7142857143rem;
  }
}

.next_page .font-en {
  display: block;
  margin-top: 1em;
  font-size: 1rem;
  line-height: var(--line-height-none);
  text-transform: uppercase;
  opacity: 0.7;
  font-weight: 600;
  letter-spacing: 0.09em;
}

.next_page-bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0.2;
  transition: var(--transit-default);
}

/* txt
**************************************** */
.txt-sm {
  font-size: 80%;
}

.txt-lg {
  font-size: 130%;
}

.txt-wh,
.txt-wh * {
  color: var(--clr-wht, #fff);
}

.txt-main,
.txt-main * {
  color: var(--clr-main, #1d7fb7);
}

.txt-sub,
.txt-sub * {
  color: var(--clr-sub, #eee);
}

.ltr-hdr {
  letter-spacing: var(--ltr-space-hdr);
}

/* --- font-size --- */
.fz-8 {
  font-size: 0.5rem;
}

.fz-9 {
  font-size: 0.5625rem;
}

.fz-10 {
  font-size: 0.625rem;
}

.fz-11 {
  font-size: 0.6875rem;
}

.fz-12 {
  font-size: 0.75rem;
}

.fz-13 {
  font-size: 0.8125rem;
}

.fz-14 {
  font-size: 0.875rem;
}

.fz-15 {
  font-size: 0.9375rem;
}

.fz-16 {
  font-size: 1rem;
}

.fz-17 {
  font-size: 1.0625rem;
}

.fz-18 {
  font-size: 1.125rem;
}

.fz-19 {
  font-size: 1.1875rem;
}

.fz-20 {
  font-size: 1.25rem;
}

.fz-21 {
  font-size: 1.3125rem;
}

.fz-22 {
  font-size: 1.375rem;
}

.fz-23 {
  font-size: 1.4375rem;
}

.fz-24 {
  font-size: 1.5rem;
}

.fz-25 {
  font-size: 1.5625rem;
}

.fz-26 {
  font-size: 1.625rem;
}

.fz-27 {
  font-size: 1.6875rem;
}

.fz-28 {
  font-size: 1.75rem;
}

.fz-29 {
  font-size: 1.8125rem;
}

.fz-30 {
  font-size: 1.875rem;
}

.fz-31 {
  font-size: 1.9375rem;
}

.fz-32 {
  font-size: 2rem;
}

.fz-33 {
  font-size: 2.0625rem;
}

.fz-34 {
  font-size: 2.125rem;
}

.fz-35 {
  font-size: 2.1875rem;
}

.fz-36 {
  font-size: 2.25rem;
}

.fz-37 {
  font-size: 2.3125rem;
}

.fz-38 {
  font-size: 2.375rem;
}

.fz-39 {
  font-size: 2.4375rem;
}

.fz-40 {
  font-size: 2.5rem;
}

.fz-41 {
  font-size: 2.5625rem;
}

.fz-42 {
  font-size: 2.625rem;
}

.fz-43 {
  font-size: 2.6875rem;
}

.fz-44 {
  font-size: 2.75rem;
}

.fz-45 {
  font-size: 2.8125rem;
}

.fz-46 {
  font-size: 2.875rem;
}

.fz-47 {
  font-size: 2.9375rem;
}

.fz-48 {
  font-size: 3rem;
}

.fz-49 {
  font-size: 3.0625rem;
}

.fz-50 {
  font-size: 3.125rem;
}

.fz-51 {
  font-size: 3.1875rem;
}

.fz-52 {
  font-size: 3.25rem;
}

.fz-53 {
  font-size: 3.3125rem;
}

.fz-54 {
  font-size: 3.375rem;
}

.fz-55 {
  font-size: 3.4375rem;
}

.fz-56 {
  font-size: 3.5rem;
}

.fz-57 {
  font-size: 3.5625rem;
}

.fz-58 {
  font-size: 3.625rem;
}

.fz-59 {
  font-size: 3.6875rem;
}

.fz-60 {
  font-size: 3.75rem;
}

.fz-61 {
  font-size: 3.8125rem;
}

.fz-62 {
  font-size: 3.875rem;
}

.fz-63 {
  font-size: 3.9375rem;
}

.fz-64 {
  font-size: 4rem;
}

.fz-65 {
  font-size: 4.0625rem;
}

.fz-66 {
  font-size: 4.125rem;
}

.fz-67 {
  font-size: 4.1875rem;
}

.fz-68 {
  font-size: 4.25rem;
}

.fz-69 {
  font-size: 4.3125rem;
}

.fz-70 {
  font-size: 4.375rem;
}

.fz-71 {
  font-size: 4.4375rem;
}

.fz-72 {
  font-size: 4.5rem;
}

.fz-73 {
  font-size: 4.5625rem;
}

.fz-74 {
  font-size: 4.625rem;
}

.fz-75 {
  font-size: 4.6875rem;
}

.fz-76 {
  font-size: 4.75rem;
}

.fz-77 {
  font-size: 4.8125rem;
}

.fz-78 {
  font-size: 4.875rem;
}

.fz-79 {
  font-size: 4.9375rem;
}

.fz-80 {
  font-size: 5rem;
}

.fz-81 {
  font-size: 5.0625rem;
}

.fz-82 {
  font-size: 5.125rem;
}

.fz-83 {
  font-size: 5.1875rem;
}

.fz-84 {
  font-size: 5.25rem;
}

.fz-85 {
  font-size: 5.3125rem;
}

.fz-86 {
  font-size: 5.375rem;
}

.fz-87 {
  font-size: 5.4375rem;
}

.fz-88 {
  font-size: 5.5rem;
}

.fz-89 {
  font-size: 5.5625rem;
}

.fz-90 {
  font-size: 5.625rem;
}

.fz-91 {
  font-size: 5.6875rem;
}

.fz-92 {
  font-size: 5.75rem;
}

.fz-93 {
  font-size: 5.8125rem;
}

.fz-94 {
  font-size: 5.875rem;
}

.fz-95 {
  font-size: 5.9375rem;
}

.fz-96 {
  font-size: 6rem;
}

.fz-97 {
  font-size: 6.0625rem;
}

.fz-98 {
  font-size: 6.125rem;
}

.fz-99 {
  font-size: 6.1875rem;
}

.fz-100 {
  font-size: 6.25rem;
}

.fz-101 {
  font-size: 6.3125rem;
}

.fz-102 {
  font-size: 6.375rem;
}

.fz-103 {
  font-size: 6.4375rem;
}

.fz-104 {
  font-size: 6.5rem;
}

.fz-105 {
  font-size: 6.5625rem;
}

.fz-106 {
  font-size: 6.625rem;
}

.fz-107 {
  font-size: 6.6875rem;
}

.fz-108 {
  font-size: 6.75rem;
}

.fz-109 {
  font-size: 6.8125rem;
}

.fz-110 {
  font-size: 6.875rem;
}

.fz-111 {
  font-size: 6.9375rem;
}

.fz-112 {
  font-size: 7rem;
}

.fz-113 {
  font-size: 7.0625rem;
}

.fz-114 {
  font-size: 7.125rem;
}

.fz-115 {
  font-size: 7.1875rem;
}

.fz-116 {
  font-size: 7.25rem;
}

.fz-117 {
  font-size: 7.3125rem;
}

.fz-118 {
  font-size: 7.375rem;
}

.fz-119 {
  font-size: 7.4375rem;
}

.fz-120 {
  font-size: 7.5rem;
}

.fz-121 {
  font-size: 7.5625rem;
}

.fz-122 {
  font-size: 7.625rem;
}

.fz-123 {
  font-size: 7.6875rem;
}

.fz-124 {
  font-size: 7.75rem;
}

.fz-125 {
  font-size: 7.8125rem;
}

.fz-126 {
  font-size: 7.875rem;
}

.fz-127 {
  font-size: 7.9375rem;
}

.fz-128 {
  font-size: 8rem;
}

.fz-129 {
  font-size: 8.0625rem;
}

.fz-130 {
  font-size: 8.125rem;
}

.fz-131 {
  font-size: 8.1875rem;
}

.fz-132 {
  font-size: 8.25rem;
}

.fz-133 {
  font-size: 8.3125rem;
}

.fz-134 {
  font-size: 8.375rem;
}

.fz-135 {
  font-size: 8.4375rem;
}

.fz-136 {
  font-size: 8.5rem;
}

.fz-137 {
  font-size: 8.5625rem;
}

.fz-138 {
  font-size: 8.625rem;
}

.fz-139 {
  font-size: 8.6875rem;
}

@media screen and (max-width: 47.9375em) {
  .fz-8 {
    font-size: 0.4395604396rem;
  }

  .fz-9 {
    font-size: 0.4945054945rem;
  }

  .fz-10 {
    font-size: 0.5494505495rem;
  }

  .fz-11 {
    font-size: 0.6043956044rem;
  }

  .fz-12 {
    font-size: 0.6593406593rem;
  }

  .fz-13 {
    font-size: 0.7142857143rem;
  }

  .fz-14 {
    font-size: 0.7692307692rem;
  }

  .fz-15 {
    font-size: 0.8241758242rem;
  }

  .fz-16 {
    font-size: 0.8791208791rem;
  }

  .fz-17 {
    font-size: 0.9340659341rem;
  }

  .fz-18 {
    font-size: 0.989010989rem;
  }

  .fz-19 {
    font-size: 1.043956044rem;
  }

  .fz-20 {
    font-size: 1.0989010989rem;
  }

  .fz-21 {
    font-size: 1.1538461538rem;
  }

  .fz-22 {
    font-size: 1.2087912088rem;
  }

  .fz-23 {
    font-size: 1.2637362637rem;
  }

  .fz-24 {
    font-size: 1.3186813187rem;
  }

  .fz-25 {
    font-size: 1.3736263736rem;
  }

  .fz-26 {
    font-size: 1.4285714286rem;
  }

  .fz-27 {
    font-size: 1.4835164835rem;
  }

  .fz-28 {
    font-size: 1.5384615385rem;
  }

  .fz-29 {
    font-size: 1.5934065934rem;
  }

  .fz-30 {
    font-size: 1.6483516484rem;
  }

  .fz-31 {
    font-size: 1.7032967033rem;
  }

  .fz-32 {
    font-size: 1.7582417582rem;
  }

  .fz-33 {
    font-size: 1.8131868132rem;
  }

  .fz-34 {
    font-size: 1.8681318681rem;
  }

  .fz-35 {
    font-size: 1.9230769231rem;
  }

  .fz-36 {
    font-size: 1.978021978rem;
  }

  .fz-37 {
    font-size: 2.032967033rem;
  }

  .fz-38 {
    font-size: 2.0879120879rem;
  }

  .fz-39 {
    font-size: 2.1428571429rem;
  }

  .fz-40 {
    font-size: 2.1978021978rem;
  }

  .fz-41 {
    font-size: 2.2527472527rem;
  }

  .fz-42 {
    font-size: 2.3076923077rem;
  }

  .fz-43 {
    font-size: 2.3626373626rem;
  }

  .fz-44 {
    font-size: 2.4175824176rem;
  }

  .fz-45 {
    font-size: 2.4725274725rem;
  }

  .fz-46 {
    font-size: 2.5274725275rem;
  }

  .fz-47 {
    font-size: 2.5824175824rem;
  }

  .fz-48 {
    font-size: 2.6373626374rem;
  }

  .fz-49 {
    font-size: 2.6923076923rem;
  }

  .fz-50 {
    font-size: 2.7472527473rem;
  }

  .fz-51 {
    font-size: 2.8021978022rem;
  }

  .fz-52 {
    font-size: 2.8571428571rem;
  }

  .fz-53 {
    font-size: 2.9120879121rem;
  }

  .fz-54 {
    font-size: 2.967032967rem;
  }

  .fz-55 {
    font-size: 3.021978022rem;
  }

  .fz-56 {
    font-size: 3.0769230769rem;
  }

  .fz-57 {
    font-size: 3.1318681319rem;
  }

  .fz-58 {
    font-size: 3.1868131868rem;
  }

  .fz-59 {
    font-size: 3.2417582418rem;
  }

  .fz-60 {
    font-size: 3.2967032967rem;
  }

  .fz-61 {
    font-size: 3.3516483516rem;
  }

  .fz-62 {
    font-size: 3.4065934066rem;
  }

  .fz-63 {
    font-size: 3.4615384615rem;
  }

  .fz-64 {
    font-size: 3.5164835165rem;
  }

  .fz-65 {
    font-size: 3.5714285714rem;
  }

  .fz-66 {
    font-size: 3.6263736264rem;
  }

  .fz-67 {
    font-size: 3.6813186813rem;
  }

  .fz-68 {
    font-size: 3.7362637363rem;
  }

  .fz-69 {
    font-size: 3.7912087912rem;
  }

  .fz-70 {
    font-size: 3.8461538462rem;
  }

  .fz-71 {
    font-size: 3.9010989011rem;
  }

  .fz-72 {
    font-size: 3.956043956rem;
  }

  .fz-73 {
    font-size: 4.010989011rem;
  }

  .fz-74 {
    font-size: 4.0659340659rem;
  }

  .fz-75 {
    font-size: 4.1208791209rem;
  }

  .fz-76 {
    font-size: 4.1758241758rem;
  }

  .fz-77 {
    font-size: 4.2307692308rem;
  }

  .fz-78 {
    font-size: 4.2857142857rem;
  }

  .fz-79 {
    font-size: 4.3406593407rem;
  }

  .fz-80 {
    font-size: 4.3956043956rem;
  }

  .fz-81 {
    font-size: 4.4505494505rem;
  }

  .fz-82 {
    font-size: 4.5054945055rem;
  }

  .fz-83 {
    font-size: 4.5604395604rem;
  }

  .fz-84 {
    font-size: 4.6153846154rem;
  }

  .fz-85 {
    font-size: 4.6703296703rem;
  }

  .fz-86 {
    font-size: 4.7252747253rem;
  }

  .fz-87 {
    font-size: 4.7802197802rem;
  }

  .fz-88 {
    font-size: 4.8351648352rem;
  }

  .fz-89 {
    font-size: 4.8901098901rem;
  }

  .fz-90 {
    font-size: 4.9450549451rem;
  }

  .fz-91 {
    font-size: 5rem;
  }

  .fz-92 {
    font-size: 5.0549450549rem;
  }

  .fz-93 {
    font-size: 5.1098901099rem;
  }

  .fz-94 {
    font-size: 5.1648351648rem;
  }

  .fz-95 {
    font-size: 5.2197802198rem;
  }

  .fz-96 {
    font-size: 5.2747252747rem;
  }

  .fz-97 {
    font-size: 5.3296703297rem;
  }

  .fz-98 {
    font-size: 5.3846153846rem;
  }

  .fz-99 {
    font-size: 5.4395604396rem;
  }

  .fz-100 {
    font-size: 5.4945054945rem;
  }

  .fz-101 {
    font-size: 5.5494505495rem;
  }

  .fz-102 {
    font-size: 5.6043956044rem;
  }

  .fz-103 {
    font-size: 5.6593406593rem;
  }

  .fz-104 {
    font-size: 5.7142857143rem;
  }

  .fz-105 {
    font-size: 5.7692307692rem;
  }

  .fz-106 {
    font-size: 5.8241758242rem;
  }

  .fz-107 {
    font-size: 5.8791208791rem;
  }

  .fz-108 {
    font-size: 5.9340659341rem;
  }

  .fz-109 {
    font-size: 5.989010989rem;
  }

  .fz-110 {
    font-size: 6.043956044rem;
  }

  .fz-111 {
    font-size: 6.0989010989rem;
  }

  .fz-112 {
    font-size: 6.1538461538rem;
  }

  .fz-113 {
    font-size: 6.2087912088rem;
  }

  .fz-114 {
    font-size: 6.2637362637rem;
  }

  .fz-115 {
    font-size: 6.3186813187rem;
  }

  .fz-116 {
    font-size: 6.3736263736rem;
  }

  .fz-117 {
    font-size: 6.4285714286rem;
  }

  .fz-118 {
    font-size: 6.4835164835rem;
  }

  .fz-119 {
    font-size: 6.5384615385rem;
  }

  .fz-120 {
    font-size: 6.5934065934rem;
  }

  .fz-121 {
    font-size: 6.6483516484rem;
  }

  .fz-122 {
    font-size: 6.7032967033rem;
  }

  .fz-123 {
    font-size: 6.7582417582rem;
  }

  .fz-124 {
    font-size: 6.8131868132rem;
  }

  .fz-125 {
    font-size: 6.8681318681rem;
  }

  .fz-126 {
    font-size: 6.9230769231rem;
  }

  .fz-127 {
    font-size: 6.978021978rem;
  }

  .fz-128 {
    font-size: 7.032967033rem;
  }

  .fz-129 {
    font-size: 7.0879120879rem;
  }

  .fz-130 {
    font-size: 7.1428571429rem;
  }

  .fz-131 {
    font-size: 7.1978021978rem;
  }

  .fz-132 {
    font-size: 7.2527472527rem;
  }

  .fz-133 {
    font-size: 7.3076923077rem;
  }

  .fz-134 {
    font-size: 7.3626373626rem;
  }

  .fz-135 {
    font-size: 7.4175824176rem;
  }

  .fz-136 {
    font-size: 7.4725274725rem;
  }

  .fz-137 {
    font-size: 7.5274725275rem;
  }

  .fz-138 {
    font-size: 7.5824175824rem;
  }

  .fz-139 {
    font-size: 7.6373626374rem;
  }
}
/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}

.font-en {
  font-family: var(--font-en, "Montserrat", sans-serif);
}

.font-num {
  font-family: var(--font-num, "Montserrat", sans-serif);
}

/* --- clip-txt --- */
.clip-txt1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.clip-txt3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.clip-txt4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@media screen and (max-width: 47.9375em) {
  .txt-ctr-pc,
.txt-rgt-pc {
    text-align: left;
  }

  .txt-ctr-sp {
    text-align: center;
  }
}
/* bg
********************************************** */
.lps_sec:nth-child(2n) {
  background-color: var(--bg-main, #f6f5f1) !important;
  padding-bottom: 150px;
}

.lps_sec:first-child .sec-round {
  display: none;
}

.sec-round {
  position: relative;
  background-color: var(--bg-main, #f6f5f1) !important;
}
.sec-round span {
  position: absolute;
  top: -8.9583333333vw;
  left: 0;
  width: 100vw;
  background-image: url(../images/common/bg-round.svg);
  background-size: 100% !important;
  background-repeat: no-repeat;
  aspect-ratio: 1920/172;
}
@media screen and (max-width: 47.9375em) {
  .sec-round span {
    top: -12.5vw;
    background-image: url(../images/common/bg-round-sp.svg);
    aspect-ratio: 414/52;
  }
}
.sec-round span:nth-child(2) {
  opacity: 0.5;
  top: -10.8333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .sec-round span:nth-child(2) {
    top: -15vw;
  }
}
.sec-round span:nth-child(3) {
  opacity: 0.25;
  top: -12.7083333333vw;
}
@media screen and (max-width: 47.9375em) {
  .sec-round span:nth-child(3) {
    top: -17.5vw;
  }
}

.sec-round-wh span {
  background-image: url(../images/common/bg-round-wh.svg);
}

.sec-wave {
  position: relative;
  background-color: var(--bg-main, #f6f5f1) !important;
}
.sec-wave span {
  position: absolute;
  top: -7.8125vw;
  left: 0;
  width: 100vw;
  background-image: url(../images/common/bg-wave.svg);
  background-size: 100% !important;
  background-repeat: no-repeat;
  aspect-ratio: 1920/150;
}
@media screen and (max-width: 47.9375em) {
  .sec-wave span {
    top: -13.5265700483vw;
    background-image: url(../images/common/bg-wave-sp.svg);
    aspect-ratio: 414/62;
  }
}
.sec-wave span:nth-child(2) {
  opacity: 0.8;
  top: -9.6875vw;
}
@media screen and (max-width: 47.9375em) {
  .sec-wave span:nth-child(2) {
    top: -16.6666666667vw;
  }
}
.sec-wave span:nth-child(3) {
  opacity: 0.75;
  top: -11.5625vw;
}
@media screen and (max-width: 47.9375em) {
  .sec-wave span:nth-child(3) {
    top: -19.806763285vw;
  }
}

.sec-wave-top {
  transform: rotate(180deg);
}

.home_program > .sec-round span,
.program_list:first-child .sec-round span,
.lps_sec:nth-child(2n+1) .sec-round span {
  background-image: url(../images/common/bg-round-wh.svg);
}
@media screen and (max-width: 47.9375em) {
  .home_program > .sec-round span,
.program_list:first-child .sec-round span,
.lps_sec:nth-child(2n+1) .sec-round span {
    background-image: url(../images/common/bg-round-wh-sp.svg);
  }
}

.bg-main {
  background-color: var(--bg-main, #f6f5f1);
}

.bg-sub {
  background-color: #eee;
}

.bg-wh {
  background-color: var(--bg-wht, #fff);
}

.bg-off_wht {
  background-color: var(--bg-off_wht, #fff);
}

/* deco
********************************************** */
.icon-arrow {
  display: inline-block;
  width: 6px;
  height: auto;
  aspect-ratio: 6/12;
  flex-shrink: 0;
}
.icon-arrow::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  background-image: url(../images/common/icon-arrow.svg);
  background-size: 100%;
}

.icon-heart {
  aspect-ratio: 43/38;
}
.icon-heart::before {
  content: "";
  display: block;
  width: 40px;
  background-image: url(../images/common/icon-heart-line.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  aspect-ratio: 43/38;
}
@media screen and (max-width: 47.9375em) {
  .icon-heart::before {
    width: 24px;
  }
}
.icon-heart.fill::before {
  background-image: url(../images/common/icon-heart-fill.svg);
}

.icon-light {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-inline: auto;
  aspect-ratio: 1;
  flex-shrink: 0;
}

.fill-wht {
  fill: var(--bg-wht);
}

.fill-blk {
  fill: var(--bg-blk);
}

.fill-main {
  fill: var(--clr-main-sub);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
.sp-none-inline,
.sp-none-table,
.sp-none-flex {
    display: none;
  }

  .pc-none {
    display: block;
  }

  .pc-none-inline {
    display: inline;
  }

  .pc-none-table {
    display: table;
  }

  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trim-widescreen {
  aspect-ratio: 3/2;
}

.trim-widescreen-2 {
  aspect-ratio: 16/9;
}

.trim-square {
  aspect-ratio: 1/1;
}

.trim-portrait {
  aspect-ratio: 2/3;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.news--img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mgn-0 {
  margin: 0rem;
}

.mgn-top0 {
  margin-top: 0rem;
}

.mgn-rgt0 {
  margin-right: 0rem;
}

.mgn-btm0 {
  margin-bottom: 0rem;
}

.mgn-lft0 {
  margin-left: 0rem;
}

.mgn-1 {
  margin: 0.0625rem;
}

.mgn-top1 {
  margin-top: 0.0625rem;
}

.mgn-rgt1 {
  margin-right: 0.0625rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625rem;
}

.mgn-lft1 {
  margin-left: 0.0625rem;
}

.mgn-2 {
  margin: 0.125rem;
}

.mgn-top2 {
  margin-top: 0.125rem;
}

.mgn-rgt2 {
  margin-right: 0.125rem;
}

.mgn-btm2 {
  margin-bottom: 0.125rem;
}

.mgn-lft2 {
  margin-left: 0.125rem;
}

.mgn-3 {
  margin: 0.1875rem;
}

.mgn-top3 {
  margin-top: 0.1875rem;
}

.mgn-rgt3 {
  margin-right: 0.1875rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875rem;
}

.mgn-lft3 {
  margin-left: 0.1875rem;
}

.mgn-4 {
  margin: 0.25rem;
}

.mgn-top4 {
  margin-top: 0.25rem;
}

.mgn-rgt4 {
  margin-right: 0.25rem;
}

.mgn-btm4 {
  margin-bottom: 0.25rem;
}

.mgn-lft4 {
  margin-left: 0.25rem;
}

.mgn-5 {
  margin: 0.3125rem;
}

.mgn-top5 {
  margin-top: 0.3125rem;
}

.mgn-rgt5 {
  margin-right: 0.3125rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125rem;
}

.mgn-lft5 {
  margin-left: 0.3125rem;
}

.mgn-6 {
  margin: 0.375rem;
}

.mgn-top6 {
  margin-top: 0.375rem;
}

.mgn-rgt6 {
  margin-right: 0.375rem;
}

.mgn-btm6 {
  margin-bottom: 0.375rem;
}

.mgn-lft6 {
  margin-left: 0.375rem;
}

.mgn-7 {
  margin: 0.4375rem;
}

.mgn-top7 {
  margin-top: 0.4375rem;
}

.mgn-rgt7 {
  margin-right: 0.4375rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375rem;
}

.mgn-lft7 {
  margin-left: 0.4375rem;
}

.mgn-8 {
  margin: 0.5rem;
}

.mgn-top8 {
  margin-top: 0.5rem;
}

.mgn-rgt8 {
  margin-right: 0.5rem;
}

.mgn-btm8 {
  margin-bottom: 0.5rem;
}

.mgn-lft8 {
  margin-left: 0.5rem;
}

.mgn-9 {
  margin: 0.5625rem;
}

.mgn-top9 {
  margin-top: 0.5625rem;
}

.mgn-rgt9 {
  margin-right: 0.5625rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625rem;
}

.mgn-lft9 {
  margin-left: 0.5625rem;
}

.mgn-10 {
  margin: 0.625rem;
}

.mgn-top10 {
  margin-top: 0.625rem;
}

.mgn-rgt10 {
  margin-right: 0.625rem;
}

.mgn-btm10 {
  margin-bottom: 0.625rem;
}

.mgn-lft10 {
  margin-left: 0.625rem;
}

.mgn-11 {
  margin: 0.6875rem;
}

.mgn-top11 {
  margin-top: 0.6875rem;
}

.mgn-rgt11 {
  margin-right: 0.6875rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875rem;
}

.mgn-lft11 {
  margin-left: 0.6875rem;
}

.mgn-12 {
  margin: 0.75rem;
}

.mgn-top12 {
  margin-top: 0.75rem;
}

.mgn-rgt12 {
  margin-right: 0.75rem;
}

.mgn-btm12 {
  margin-bottom: 0.75rem;
}

.mgn-lft12 {
  margin-left: 0.75rem;
}

.mgn-13 {
  margin: 0.8125rem;
}

.mgn-top13 {
  margin-top: 0.8125rem;
}

.mgn-rgt13 {
  margin-right: 0.8125rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125rem;
}

.mgn-lft13 {
  margin-left: 0.8125rem;
}

.mgn-14 {
  margin: 0.875rem;
}

.mgn-top14 {
  margin-top: 0.875rem;
}

.mgn-rgt14 {
  margin-right: 0.875rem;
}

.mgn-btm14 {
  margin-bottom: 0.875rem;
}

.mgn-lft14 {
  margin-left: 0.875rem;
}

.mgn-15 {
  margin: 0.9375rem;
}

.mgn-top15 {
  margin-top: 0.9375rem;
}

.mgn-rgt15 {
  margin-right: 0.9375rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375rem;
}

.mgn-lft15 {
  margin-left: 0.9375rem;
}

.mgn-16 {
  margin: 1rem;
}

.mgn-top16 {
  margin-top: 1rem;
}

.mgn-rgt16 {
  margin-right: 1rem;
}

.mgn-btm16 {
  margin-bottom: 1rem;
}

.mgn-lft16 {
  margin-left: 1rem;
}

.mgn-17 {
  margin: 1.0625rem;
}

.mgn-top17 {
  margin-top: 1.0625rem;
}

.mgn-rgt17 {
  margin-right: 1.0625rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625rem;
}

.mgn-lft17 {
  margin-left: 1.0625rem;
}

.mgn-18 {
  margin: 1.125rem;
}

.mgn-top18 {
  margin-top: 1.125rem;
}

.mgn-rgt18 {
  margin-right: 1.125rem;
}

.mgn-btm18 {
  margin-bottom: 1.125rem;
}

.mgn-lft18 {
  margin-left: 1.125rem;
}

.mgn-19 {
  margin: 1.1875rem;
}

.mgn-top19 {
  margin-top: 1.1875rem;
}

.mgn-rgt19 {
  margin-right: 1.1875rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875rem;
}

.mgn-lft19 {
  margin-left: 1.1875rem;
}

.mgn-20 {
  margin: 1.25rem;
}

.mgn-top20 {
  margin-top: 1.25rem;
}

.mgn-rgt20 {
  margin-right: 1.25rem;
}

.mgn-btm20 {
  margin-bottom: 1.25rem;
}

.mgn-lft20 {
  margin-left: 1.25rem;
}

.mgn-21 {
  margin: 1.3125rem;
}

.mgn-top21 {
  margin-top: 1.3125rem;
}

.mgn-rgt21 {
  margin-right: 1.3125rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125rem;
}

.mgn-lft21 {
  margin-left: 1.3125rem;
}

.mgn-22 {
  margin: 1.375rem;
}

.mgn-top22 {
  margin-top: 1.375rem;
}

.mgn-rgt22 {
  margin-right: 1.375rem;
}

.mgn-btm22 {
  margin-bottom: 1.375rem;
}

.mgn-lft22 {
  margin-left: 1.375rem;
}

.mgn-23 {
  margin: 1.4375rem;
}

.mgn-top23 {
  margin-top: 1.4375rem;
}

.mgn-rgt23 {
  margin-right: 1.4375rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375rem;
}

.mgn-lft23 {
  margin-left: 1.4375rem;
}

.mgn-24 {
  margin: 1.5rem;
}

.mgn-top24 {
  margin-top: 1.5rem;
}

.mgn-rgt24 {
  margin-right: 1.5rem;
}

.mgn-btm24 {
  margin-bottom: 1.5rem;
}

.mgn-lft24 {
  margin-left: 1.5rem;
}

.mgn-25 {
  margin: 1.5625rem;
}

.mgn-top25 {
  margin-top: 1.5625rem;
}

.mgn-rgt25 {
  margin-right: 1.5625rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625rem;
}

.mgn-lft25 {
  margin-left: 1.5625rem;
}

.mgn-26 {
  margin: 1.625rem;
}

.mgn-top26 {
  margin-top: 1.625rem;
}

.mgn-rgt26 {
  margin-right: 1.625rem;
}

.mgn-btm26 {
  margin-bottom: 1.625rem;
}

.mgn-lft26 {
  margin-left: 1.625rem;
}

.mgn-27 {
  margin: 1.6875rem;
}

.mgn-top27 {
  margin-top: 1.6875rem;
}

.mgn-rgt27 {
  margin-right: 1.6875rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875rem;
}

.mgn-lft27 {
  margin-left: 1.6875rem;
}

.mgn-28 {
  margin: 1.75rem;
}

.mgn-top28 {
  margin-top: 1.75rem;
}

.mgn-rgt28 {
  margin-right: 1.75rem;
}

.mgn-btm28 {
  margin-bottom: 1.75rem;
}

.mgn-lft28 {
  margin-left: 1.75rem;
}

.mgn-29 {
  margin: 1.8125rem;
}

.mgn-top29 {
  margin-top: 1.8125rem;
}

.mgn-rgt29 {
  margin-right: 1.8125rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125rem;
}

.mgn-lft29 {
  margin-left: 1.8125rem;
}

.mgn-30 {
  margin: 1.875rem;
}

.mgn-top30 {
  margin-top: 1.875rem;
}

.mgn-rgt30 {
  margin-right: 1.875rem;
}

.mgn-btm30 {
  margin-bottom: 1.875rem;
}

.mgn-lft30 {
  margin-left: 1.875rem;
}

.mgn-31 {
  margin: 1.9375rem;
}

.mgn-top31 {
  margin-top: 1.9375rem;
}

.mgn-rgt31 {
  margin-right: 1.9375rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375rem;
}

.mgn-lft31 {
  margin-left: 1.9375rem;
}

.mgn-32 {
  margin: 2rem;
}

.mgn-top32 {
  margin-top: 2rem;
}

.mgn-rgt32 {
  margin-right: 2rem;
}

.mgn-btm32 {
  margin-bottom: 2rem;
}

.mgn-lft32 {
  margin-left: 2rem;
}

.mgn-33 {
  margin: 2.0625rem;
}

.mgn-top33 {
  margin-top: 2.0625rem;
}

.mgn-rgt33 {
  margin-right: 2.0625rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625rem;
}

.mgn-lft33 {
  margin-left: 2.0625rem;
}

.mgn-34 {
  margin: 2.125rem;
}

.mgn-top34 {
  margin-top: 2.125rem;
}

.mgn-rgt34 {
  margin-right: 2.125rem;
}

.mgn-btm34 {
  margin-bottom: 2.125rem;
}

.mgn-lft34 {
  margin-left: 2.125rem;
}

.mgn-35 {
  margin: 2.1875rem;
}

.mgn-top35 {
  margin-top: 2.1875rem;
}

.mgn-rgt35 {
  margin-right: 2.1875rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875rem;
}

.mgn-lft35 {
  margin-left: 2.1875rem;
}

.mgn-36 {
  margin: 2.25rem;
}

.mgn-top36 {
  margin-top: 2.25rem;
}

.mgn-rgt36 {
  margin-right: 2.25rem;
}

.mgn-btm36 {
  margin-bottom: 2.25rem;
}

.mgn-lft36 {
  margin-left: 2.25rem;
}

.mgn-37 {
  margin: 2.3125rem;
}

.mgn-top37 {
  margin-top: 2.3125rem;
}

.mgn-rgt37 {
  margin-right: 2.3125rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125rem;
}

.mgn-lft37 {
  margin-left: 2.3125rem;
}

.mgn-38 {
  margin: 2.375rem;
}

.mgn-top38 {
  margin-top: 2.375rem;
}

.mgn-rgt38 {
  margin-right: 2.375rem;
}

.mgn-btm38 {
  margin-bottom: 2.375rem;
}

.mgn-lft38 {
  margin-left: 2.375rem;
}

.mgn-39 {
  margin: 2.4375rem;
}

.mgn-top39 {
  margin-top: 2.4375rem;
}

.mgn-rgt39 {
  margin-right: 2.4375rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375rem;
}

.mgn-lft39 {
  margin-left: 2.4375rem;
}

.mgn-40 {
  margin: 2.5rem;
}

.mgn-top40 {
  margin-top: 2.5rem;
}

.mgn-rgt40 {
  margin-right: 2.5rem;
}

.mgn-btm40 {
  margin-bottom: 2.5rem;
}

.mgn-lft40 {
  margin-left: 2.5rem;
}

.mgn-41 {
  margin: 2.5625rem;
}

.mgn-top41 {
  margin-top: 2.5625rem;
}

.mgn-rgt41 {
  margin-right: 2.5625rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625rem;
}

.mgn-lft41 {
  margin-left: 2.5625rem;
}

.mgn-42 {
  margin: 2.625rem;
}

.mgn-top42 {
  margin-top: 2.625rem;
}

.mgn-rgt42 {
  margin-right: 2.625rem;
}

.mgn-btm42 {
  margin-bottom: 2.625rem;
}

.mgn-lft42 {
  margin-left: 2.625rem;
}

.mgn-43 {
  margin: 2.6875rem;
}

.mgn-top43 {
  margin-top: 2.6875rem;
}

.mgn-rgt43 {
  margin-right: 2.6875rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875rem;
}

.mgn-lft43 {
  margin-left: 2.6875rem;
}

.mgn-44 {
  margin: 2.75rem;
}

.mgn-top44 {
  margin-top: 2.75rem;
}

.mgn-rgt44 {
  margin-right: 2.75rem;
}

.mgn-btm44 {
  margin-bottom: 2.75rem;
}

.mgn-lft44 {
  margin-left: 2.75rem;
}

.mgn-45 {
  margin: 2.8125rem;
}

.mgn-top45 {
  margin-top: 2.8125rem;
}

.mgn-rgt45 {
  margin-right: 2.8125rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125rem;
}

.mgn-lft45 {
  margin-left: 2.8125rem;
}

.mgn-46 {
  margin: 2.875rem;
}

.mgn-top46 {
  margin-top: 2.875rem;
}

.mgn-rgt46 {
  margin-right: 2.875rem;
}

.mgn-btm46 {
  margin-bottom: 2.875rem;
}

.mgn-lft46 {
  margin-left: 2.875rem;
}

.mgn-47 {
  margin: 2.9375rem;
}

.mgn-top47 {
  margin-top: 2.9375rem;
}

.mgn-rgt47 {
  margin-right: 2.9375rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375rem;
}

.mgn-lft47 {
  margin-left: 2.9375rem;
}

.mgn-48 {
  margin: 3rem;
}

.mgn-top48 {
  margin-top: 3rem;
}

.mgn-rgt48 {
  margin-right: 3rem;
}

.mgn-btm48 {
  margin-bottom: 3rem;
}

.mgn-lft48 {
  margin-left: 3rem;
}

.mgn-49 {
  margin: 3.0625rem;
}

.mgn-top49 {
  margin-top: 3.0625rem;
}

.mgn-rgt49 {
  margin-right: 3.0625rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625rem;
}

.mgn-lft49 {
  margin-left: 3.0625rem;
}

.mgn-50 {
  margin: 3.125rem;
}

.mgn-top50 {
  margin-top: 3.125rem;
}

.mgn-rgt50 {
  margin-right: 3.125rem;
}

.mgn-btm50 {
  margin-bottom: 3.125rem;
}

.mgn-lft50 {
  margin-left: 3.125rem;
}

.mgn-51 {
  margin: 3.1875rem;
}

.mgn-top51 {
  margin-top: 3.1875rem;
}

.mgn-rgt51 {
  margin-right: 3.1875rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875rem;
}

.mgn-lft51 {
  margin-left: 3.1875rem;
}

.mgn-52 {
  margin: 3.25rem;
}

.mgn-top52 {
  margin-top: 3.25rem;
}

.mgn-rgt52 {
  margin-right: 3.25rem;
}

.mgn-btm52 {
  margin-bottom: 3.25rem;
}

.mgn-lft52 {
  margin-left: 3.25rem;
}

.mgn-53 {
  margin: 3.3125rem;
}

.mgn-top53 {
  margin-top: 3.3125rem;
}

.mgn-rgt53 {
  margin-right: 3.3125rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125rem;
}

.mgn-lft53 {
  margin-left: 3.3125rem;
}

.mgn-54 {
  margin: 3.375rem;
}

.mgn-top54 {
  margin-top: 3.375rem;
}

.mgn-rgt54 {
  margin-right: 3.375rem;
}

.mgn-btm54 {
  margin-bottom: 3.375rem;
}

.mgn-lft54 {
  margin-left: 3.375rem;
}

.mgn-55 {
  margin: 3.4375rem;
}

.mgn-top55 {
  margin-top: 3.4375rem;
}

.mgn-rgt55 {
  margin-right: 3.4375rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375rem;
}

.mgn-lft55 {
  margin-left: 3.4375rem;
}

.mgn-56 {
  margin: 3.5rem;
}

.mgn-top56 {
  margin-top: 3.5rem;
}

.mgn-rgt56 {
  margin-right: 3.5rem;
}

.mgn-btm56 {
  margin-bottom: 3.5rem;
}

.mgn-lft56 {
  margin-left: 3.5rem;
}

.mgn-57 {
  margin: 3.5625rem;
}

.mgn-top57 {
  margin-top: 3.5625rem;
}

.mgn-rgt57 {
  margin-right: 3.5625rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625rem;
}

.mgn-lft57 {
  margin-left: 3.5625rem;
}

.mgn-58 {
  margin: 3.625rem;
}

.mgn-top58 {
  margin-top: 3.625rem;
}

.mgn-rgt58 {
  margin-right: 3.625rem;
}

.mgn-btm58 {
  margin-bottom: 3.625rem;
}

.mgn-lft58 {
  margin-left: 3.625rem;
}

.mgn-59 {
  margin: 3.6875rem;
}

.mgn-top59 {
  margin-top: 3.6875rem;
}

.mgn-rgt59 {
  margin-right: 3.6875rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875rem;
}

.mgn-lft59 {
  margin-left: 3.6875rem;
}

.mgn-60 {
  margin: 3.75rem;
}

.mgn-top60 {
  margin-top: 3.75rem;
}

.mgn-rgt60 {
  margin-right: 3.75rem;
}

.mgn-btm60 {
  margin-bottom: 3.75rem;
}

.mgn-lft60 {
  margin-left: 3.75rem;
}

.mgn-61 {
  margin: 3.8125rem;
}

.mgn-top61 {
  margin-top: 3.8125rem;
}

.mgn-rgt61 {
  margin-right: 3.8125rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125rem;
}

.mgn-lft61 {
  margin-left: 3.8125rem;
}

.mgn-62 {
  margin: 3.875rem;
}

.mgn-top62 {
  margin-top: 3.875rem;
}

.mgn-rgt62 {
  margin-right: 3.875rem;
}

.mgn-btm62 {
  margin-bottom: 3.875rem;
}

.mgn-lft62 {
  margin-left: 3.875rem;
}

.mgn-63 {
  margin: 3.9375rem;
}

.mgn-top63 {
  margin-top: 3.9375rem;
}

.mgn-rgt63 {
  margin-right: 3.9375rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375rem;
}

.mgn-lft63 {
  margin-left: 3.9375rem;
}

.mgn-64 {
  margin: 4rem;
}

.mgn-top64 {
  margin-top: 4rem;
}

.mgn-rgt64 {
  margin-right: 4rem;
}

.mgn-btm64 {
  margin-bottom: 4rem;
}

.mgn-lft64 {
  margin-left: 4rem;
}

.mgn-65 {
  margin: 4.0625rem;
}

.mgn-top65 {
  margin-top: 4.0625rem;
}

.mgn-rgt65 {
  margin-right: 4.0625rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625rem;
}

.mgn-lft65 {
  margin-left: 4.0625rem;
}

.mgn-66 {
  margin: 4.125rem;
}

.mgn-top66 {
  margin-top: 4.125rem;
}

.mgn-rgt66 {
  margin-right: 4.125rem;
}

.mgn-btm66 {
  margin-bottom: 4.125rem;
}

.mgn-lft66 {
  margin-left: 4.125rem;
}

.mgn-67 {
  margin: 4.1875rem;
}

.mgn-top67 {
  margin-top: 4.1875rem;
}

.mgn-rgt67 {
  margin-right: 4.1875rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875rem;
}

.mgn-lft67 {
  margin-left: 4.1875rem;
}

.mgn-68 {
  margin: 4.25rem;
}

.mgn-top68 {
  margin-top: 4.25rem;
}

.mgn-rgt68 {
  margin-right: 4.25rem;
}

.mgn-btm68 {
  margin-bottom: 4.25rem;
}

.mgn-lft68 {
  margin-left: 4.25rem;
}

.mgn-69 {
  margin: 4.3125rem;
}

.mgn-top69 {
  margin-top: 4.3125rem;
}

.mgn-rgt69 {
  margin-right: 4.3125rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125rem;
}

.mgn-lft69 {
  margin-left: 4.3125rem;
}

.mgn-70 {
  margin: 4.375rem;
}

.mgn-top70 {
  margin-top: 4.375rem;
}

.mgn-rgt70 {
  margin-right: 4.375rem;
}

.mgn-btm70 {
  margin-bottom: 4.375rem;
}

.mgn-lft70 {
  margin-left: 4.375rem;
}

.mgn-71 {
  margin: 4.4375rem;
}

.mgn-top71 {
  margin-top: 4.4375rem;
}

.mgn-rgt71 {
  margin-right: 4.4375rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375rem;
}

.mgn-lft71 {
  margin-left: 4.4375rem;
}

.mgn-72 {
  margin: 4.5rem;
}

.mgn-top72 {
  margin-top: 4.5rem;
}

.mgn-rgt72 {
  margin-right: 4.5rem;
}

.mgn-btm72 {
  margin-bottom: 4.5rem;
}

.mgn-lft72 {
  margin-left: 4.5rem;
}

.mgn-73 {
  margin: 4.5625rem;
}

.mgn-top73 {
  margin-top: 4.5625rem;
}

.mgn-rgt73 {
  margin-right: 4.5625rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625rem;
}

.mgn-lft73 {
  margin-left: 4.5625rem;
}

.mgn-74 {
  margin: 4.625rem;
}

.mgn-top74 {
  margin-top: 4.625rem;
}

.mgn-rgt74 {
  margin-right: 4.625rem;
}

.mgn-btm74 {
  margin-bottom: 4.625rem;
}

.mgn-lft74 {
  margin-left: 4.625rem;
}

.mgn-75 {
  margin: 4.6875rem;
}

.mgn-top75 {
  margin-top: 4.6875rem;
}

.mgn-rgt75 {
  margin-right: 4.6875rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875rem;
}

.mgn-lft75 {
  margin-left: 4.6875rem;
}

.mgn-76 {
  margin: 4.75rem;
}

.mgn-top76 {
  margin-top: 4.75rem;
}

.mgn-rgt76 {
  margin-right: 4.75rem;
}

.mgn-btm76 {
  margin-bottom: 4.75rem;
}

.mgn-lft76 {
  margin-left: 4.75rem;
}

.mgn-77 {
  margin: 4.8125rem;
}

.mgn-top77 {
  margin-top: 4.8125rem;
}

.mgn-rgt77 {
  margin-right: 4.8125rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125rem;
}

.mgn-lft77 {
  margin-left: 4.8125rem;
}

.mgn-78 {
  margin: 4.875rem;
}

.mgn-top78 {
  margin-top: 4.875rem;
}

.mgn-rgt78 {
  margin-right: 4.875rem;
}

.mgn-btm78 {
  margin-bottom: 4.875rem;
}

.mgn-lft78 {
  margin-left: 4.875rem;
}

.mgn-79 {
  margin: 4.9375rem;
}

.mgn-top79 {
  margin-top: 4.9375rem;
}

.mgn-rgt79 {
  margin-right: 4.9375rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375rem;
}

.mgn-lft79 {
  margin-left: 4.9375rem;
}

.mgn-80 {
  margin: 5rem;
}

.mgn-top80 {
  margin-top: 5rem;
}

.mgn-rgt80 {
  margin-right: 5rem;
}

.mgn-btm80 {
  margin-bottom: 5rem;
}

.mgn-lft80 {
  margin-left: 5rem;
}

.mgn-81 {
  margin: 5.0625rem;
}

.mgn-top81 {
  margin-top: 5.0625rem;
}

.mgn-rgt81 {
  margin-right: 5.0625rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625rem;
}

.mgn-lft81 {
  margin-left: 5.0625rem;
}

.mgn-82 {
  margin: 5.125rem;
}

.mgn-top82 {
  margin-top: 5.125rem;
}

.mgn-rgt82 {
  margin-right: 5.125rem;
}

.mgn-btm82 {
  margin-bottom: 5.125rem;
}

.mgn-lft82 {
  margin-left: 5.125rem;
}

.mgn-83 {
  margin: 5.1875rem;
}

.mgn-top83 {
  margin-top: 5.1875rem;
}

.mgn-rgt83 {
  margin-right: 5.1875rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875rem;
}

.mgn-lft83 {
  margin-left: 5.1875rem;
}

.mgn-84 {
  margin: 5.25rem;
}

.mgn-top84 {
  margin-top: 5.25rem;
}

.mgn-rgt84 {
  margin-right: 5.25rem;
}

.mgn-btm84 {
  margin-bottom: 5.25rem;
}

.mgn-lft84 {
  margin-left: 5.25rem;
}

.mgn-85 {
  margin: 5.3125rem;
}

.mgn-top85 {
  margin-top: 5.3125rem;
}

.mgn-rgt85 {
  margin-right: 5.3125rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125rem;
}

.mgn-lft85 {
  margin-left: 5.3125rem;
}

.mgn-86 {
  margin: 5.375rem;
}

.mgn-top86 {
  margin-top: 5.375rem;
}

.mgn-rgt86 {
  margin-right: 5.375rem;
}

.mgn-btm86 {
  margin-bottom: 5.375rem;
}

.mgn-lft86 {
  margin-left: 5.375rem;
}

.mgn-87 {
  margin: 5.4375rem;
}

.mgn-top87 {
  margin-top: 5.4375rem;
}

.mgn-rgt87 {
  margin-right: 5.4375rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375rem;
}

.mgn-lft87 {
  margin-left: 5.4375rem;
}

.mgn-88 {
  margin: 5.5rem;
}

.mgn-top88 {
  margin-top: 5.5rem;
}

.mgn-rgt88 {
  margin-right: 5.5rem;
}

.mgn-btm88 {
  margin-bottom: 5.5rem;
}

.mgn-lft88 {
  margin-left: 5.5rem;
}

.mgn-89 {
  margin: 5.5625rem;
}

.mgn-top89 {
  margin-top: 5.5625rem;
}

.mgn-rgt89 {
  margin-right: 5.5625rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625rem;
}

.mgn-lft89 {
  margin-left: 5.5625rem;
}

.mgn-90 {
  margin: 5.625rem;
}

.mgn-top90 {
  margin-top: 5.625rem;
}

.mgn-rgt90 {
  margin-right: 5.625rem;
}

.mgn-btm90 {
  margin-bottom: 5.625rem;
}

.mgn-lft90 {
  margin-left: 5.625rem;
}

.mgn-91 {
  margin: 5.6875rem;
}

.mgn-top91 {
  margin-top: 5.6875rem;
}

.mgn-rgt91 {
  margin-right: 5.6875rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875rem;
}

.mgn-lft91 {
  margin-left: 5.6875rem;
}

.mgn-92 {
  margin: 5.75rem;
}

.mgn-top92 {
  margin-top: 5.75rem;
}

.mgn-rgt92 {
  margin-right: 5.75rem;
}

.mgn-btm92 {
  margin-bottom: 5.75rem;
}

.mgn-lft92 {
  margin-left: 5.75rem;
}

.mgn-93 {
  margin: 5.8125rem;
}

.mgn-top93 {
  margin-top: 5.8125rem;
}

.mgn-rgt93 {
  margin-right: 5.8125rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125rem;
}

.mgn-lft93 {
  margin-left: 5.8125rem;
}

.mgn-94 {
  margin: 5.875rem;
}

.mgn-top94 {
  margin-top: 5.875rem;
}

.mgn-rgt94 {
  margin-right: 5.875rem;
}

.mgn-btm94 {
  margin-bottom: 5.875rem;
}

.mgn-lft94 {
  margin-left: 5.875rem;
}

.mgn-95 {
  margin: 5.9375rem;
}

.mgn-top95 {
  margin-top: 5.9375rem;
}

.mgn-rgt95 {
  margin-right: 5.9375rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375rem;
}

.mgn-lft95 {
  margin-left: 5.9375rem;
}

.mgn-96 {
  margin: 6rem;
}

.mgn-top96 {
  margin-top: 6rem;
}

.mgn-rgt96 {
  margin-right: 6rem;
}

.mgn-btm96 {
  margin-bottom: 6rem;
}

.mgn-lft96 {
  margin-left: 6rem;
}

.mgn-97 {
  margin: 6.0625rem;
}

.mgn-top97 {
  margin-top: 6.0625rem;
}

.mgn-rgt97 {
  margin-right: 6.0625rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625rem;
}

.mgn-lft97 {
  margin-left: 6.0625rem;
}

.mgn-98 {
  margin: 6.125rem;
}

.mgn-top98 {
  margin-top: 6.125rem;
}

.mgn-rgt98 {
  margin-right: 6.125rem;
}

.mgn-btm98 {
  margin-bottom: 6.125rem;
}

.mgn-lft98 {
  margin-left: 6.125rem;
}

.mgn-99 {
  margin: 6.1875rem;
}

.mgn-top99 {
  margin-top: 6.1875rem;
}

.mgn-rgt99 {
  margin-right: 6.1875rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875rem;
}

.mgn-lft99 {
  margin-left: 6.1875rem;
}

.mgn-100 {
  margin: 6.25rem;
}

.mgn-top100 {
  margin-top: 6.25rem;
}

.mgn-rgt100 {
  margin-right: 6.25rem;
}

.mgn-btm100 {
  margin-bottom: 6.25rem;
}

.mgn-lft100 {
  margin-left: 6.25rem;
}

.mgn-101 {
  margin: 6.3125rem;
}

.mgn-top101 {
  margin-top: 6.3125rem;
}

.mgn-rgt101 {
  margin-right: 6.3125rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125rem;
}

.mgn-lft101 {
  margin-left: 6.3125rem;
}

.mgn-102 {
  margin: 6.375rem;
}

.mgn-top102 {
  margin-top: 6.375rem;
}

.mgn-rgt102 {
  margin-right: 6.375rem;
}

.mgn-btm102 {
  margin-bottom: 6.375rem;
}

.mgn-lft102 {
  margin-left: 6.375rem;
}

.mgn-103 {
  margin: 6.4375rem;
}

.mgn-top103 {
  margin-top: 6.4375rem;
}

.mgn-rgt103 {
  margin-right: 6.4375rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375rem;
}

.mgn-lft103 {
  margin-left: 6.4375rem;
}

.mgn-104 {
  margin: 6.5rem;
}

.mgn-top104 {
  margin-top: 6.5rem;
}

.mgn-rgt104 {
  margin-right: 6.5rem;
}

.mgn-btm104 {
  margin-bottom: 6.5rem;
}

.mgn-lft104 {
  margin-left: 6.5rem;
}

.mgn-105 {
  margin: 6.5625rem;
}

.mgn-top105 {
  margin-top: 6.5625rem;
}

.mgn-rgt105 {
  margin-right: 6.5625rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625rem;
}

.mgn-lft105 {
  margin-left: 6.5625rem;
}

.mgn-106 {
  margin: 6.625rem;
}

.mgn-top106 {
  margin-top: 6.625rem;
}

.mgn-rgt106 {
  margin-right: 6.625rem;
}

.mgn-btm106 {
  margin-bottom: 6.625rem;
}

.mgn-lft106 {
  margin-left: 6.625rem;
}

.mgn-107 {
  margin: 6.6875rem;
}

.mgn-top107 {
  margin-top: 6.6875rem;
}

.mgn-rgt107 {
  margin-right: 6.6875rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875rem;
}

.mgn-lft107 {
  margin-left: 6.6875rem;
}

.mgn-108 {
  margin: 6.75rem;
}

.mgn-top108 {
  margin-top: 6.75rem;
}

.mgn-rgt108 {
  margin-right: 6.75rem;
}

.mgn-btm108 {
  margin-bottom: 6.75rem;
}

.mgn-lft108 {
  margin-left: 6.75rem;
}

.mgn-109 {
  margin: 6.8125rem;
}

.mgn-top109 {
  margin-top: 6.8125rem;
}

.mgn-rgt109 {
  margin-right: 6.8125rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125rem;
}

.mgn-lft109 {
  margin-left: 6.8125rem;
}

.mgn-110 {
  margin: 6.875rem;
}

.mgn-top110 {
  margin-top: 6.875rem;
}

.mgn-rgt110 {
  margin-right: 6.875rem;
}

.mgn-btm110 {
  margin-bottom: 6.875rem;
}

.mgn-lft110 {
  margin-left: 6.875rem;
}

.mgn-111 {
  margin: 6.9375rem;
}

.mgn-top111 {
  margin-top: 6.9375rem;
}

.mgn-rgt111 {
  margin-right: 6.9375rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375rem;
}

.mgn-lft111 {
  margin-left: 6.9375rem;
}

.mgn-112 {
  margin: 7rem;
}

.mgn-top112 {
  margin-top: 7rem;
}

.mgn-rgt112 {
  margin-right: 7rem;
}

.mgn-btm112 {
  margin-bottom: 7rem;
}

.mgn-lft112 {
  margin-left: 7rem;
}

.mgn-113 {
  margin: 7.0625rem;
}

.mgn-top113 {
  margin-top: 7.0625rem;
}

.mgn-rgt113 {
  margin-right: 7.0625rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625rem;
}

.mgn-lft113 {
  margin-left: 7.0625rem;
}

.mgn-114 {
  margin: 7.125rem;
}

.mgn-top114 {
  margin-top: 7.125rem;
}

.mgn-rgt114 {
  margin-right: 7.125rem;
}

.mgn-btm114 {
  margin-bottom: 7.125rem;
}

.mgn-lft114 {
  margin-left: 7.125rem;
}

.mgn-115 {
  margin: 7.1875rem;
}

.mgn-top115 {
  margin-top: 7.1875rem;
}

.mgn-rgt115 {
  margin-right: 7.1875rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875rem;
}

.mgn-lft115 {
  margin-left: 7.1875rem;
}

.mgn-116 {
  margin: 7.25rem;
}

.mgn-top116 {
  margin-top: 7.25rem;
}

.mgn-rgt116 {
  margin-right: 7.25rem;
}

.mgn-btm116 {
  margin-bottom: 7.25rem;
}

.mgn-lft116 {
  margin-left: 7.25rem;
}

.mgn-117 {
  margin: 7.3125rem;
}

.mgn-top117 {
  margin-top: 7.3125rem;
}

.mgn-rgt117 {
  margin-right: 7.3125rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125rem;
}

.mgn-lft117 {
  margin-left: 7.3125rem;
}

.mgn-118 {
  margin: 7.375rem;
}

.mgn-top118 {
  margin-top: 7.375rem;
}

.mgn-rgt118 {
  margin-right: 7.375rem;
}

.mgn-btm118 {
  margin-bottom: 7.375rem;
}

.mgn-lft118 {
  margin-left: 7.375rem;
}

.mgn-119 {
  margin: 7.4375rem;
}

.mgn-top119 {
  margin-top: 7.4375rem;
}

.mgn-rgt119 {
  margin-right: 7.4375rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375rem;
}

.mgn-lft119 {
  margin-left: 7.4375rem;
}

.mgn-120 {
  margin: 7.5rem;
}

.mgn-top120 {
  margin-top: 7.5rem;
}

.mgn-rgt120 {
  margin-right: 7.5rem;
}

.mgn-btm120 {
  margin-bottom: 7.5rem;
}

.mgn-lft120 {
  margin-left: 7.5rem;
}

.mgn-121 {
  margin: 7.5625rem;
}

.mgn-top121 {
  margin-top: 7.5625rem;
}

.mgn-rgt121 {
  margin-right: 7.5625rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625rem;
}

.mgn-lft121 {
  margin-left: 7.5625rem;
}

.mgn-122 {
  margin: 7.625rem;
}

.mgn-top122 {
  margin-top: 7.625rem;
}

.mgn-rgt122 {
  margin-right: 7.625rem;
}

.mgn-btm122 {
  margin-bottom: 7.625rem;
}

.mgn-lft122 {
  margin-left: 7.625rem;
}

.mgn-123 {
  margin: 7.6875rem;
}

.mgn-top123 {
  margin-top: 7.6875rem;
}

.mgn-rgt123 {
  margin-right: 7.6875rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875rem;
}

.mgn-lft123 {
  margin-left: 7.6875rem;
}

.mgn-124 {
  margin: 7.75rem;
}

.mgn-top124 {
  margin-top: 7.75rem;
}

.mgn-rgt124 {
  margin-right: 7.75rem;
}

.mgn-btm124 {
  margin-bottom: 7.75rem;
}

.mgn-lft124 {
  margin-left: 7.75rem;
}

.mgn-125 {
  margin: 7.8125rem;
}

.mgn-top125 {
  margin-top: 7.8125rem;
}

.mgn-rgt125 {
  margin-right: 7.8125rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125rem;
}

.mgn-lft125 {
  margin-left: 7.8125rem;
}

.mgn-126 {
  margin: 7.875rem;
}

.mgn-top126 {
  margin-top: 7.875rem;
}

.mgn-rgt126 {
  margin-right: 7.875rem;
}

.mgn-btm126 {
  margin-bottom: 7.875rem;
}

.mgn-lft126 {
  margin-left: 7.875rem;
}

.mgn-127 {
  margin: 7.9375rem;
}

.mgn-top127 {
  margin-top: 7.9375rem;
}

.mgn-rgt127 {
  margin-right: 7.9375rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375rem;
}

.mgn-lft127 {
  margin-left: 7.9375rem;
}

.mgn-128 {
  margin: 8rem;
}

.mgn-top128 {
  margin-top: 8rem;
}

.mgn-rgt128 {
  margin-right: 8rem;
}

.mgn-btm128 {
  margin-bottom: 8rem;
}

.mgn-lft128 {
  margin-left: 8rem;
}

.mgn-129 {
  margin: 8.0625rem;
}

.mgn-top129 {
  margin-top: 8.0625rem;
}

.mgn-rgt129 {
  margin-right: 8.0625rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625rem;
}

.mgn-lft129 {
  margin-left: 8.0625rem;
}

.mgn-130 {
  margin: 8.125rem;
}

.mgn-top130 {
  margin-top: 8.125rem;
}

.mgn-rgt130 {
  margin-right: 8.125rem;
}

.mgn-btm130 {
  margin-bottom: 8.125rem;
}

.mgn-lft130 {
  margin-left: 8.125rem;
}

.mgn-131 {
  margin: 8.1875rem;
}

.mgn-top131 {
  margin-top: 8.1875rem;
}

.mgn-rgt131 {
  margin-right: 8.1875rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875rem;
}

.mgn-lft131 {
  margin-left: 8.1875rem;
}

.mgn-132 {
  margin: 8.25rem;
}

.mgn-top132 {
  margin-top: 8.25rem;
}

.mgn-rgt132 {
  margin-right: 8.25rem;
}

.mgn-btm132 {
  margin-bottom: 8.25rem;
}

.mgn-lft132 {
  margin-left: 8.25rem;
}

.mgn-133 {
  margin: 8.3125rem;
}

.mgn-top133 {
  margin-top: 8.3125rem;
}

.mgn-rgt133 {
  margin-right: 8.3125rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125rem;
}

.mgn-lft133 {
  margin-left: 8.3125rem;
}

.mgn-134 {
  margin: 8.375rem;
}

.mgn-top134 {
  margin-top: 8.375rem;
}

.mgn-rgt134 {
  margin-right: 8.375rem;
}

.mgn-btm134 {
  margin-bottom: 8.375rem;
}

.mgn-lft134 {
  margin-left: 8.375rem;
}

.mgn-135 {
  margin: 8.4375rem;
}

.mgn-top135 {
  margin-top: 8.4375rem;
}

.mgn-rgt135 {
  margin-right: 8.4375rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375rem;
}

.mgn-lft135 {
  margin-left: 8.4375rem;
}

.mgn-136 {
  margin: 8.5rem;
}

.mgn-top136 {
  margin-top: 8.5rem;
}

.mgn-rgt136 {
  margin-right: 8.5rem;
}

.mgn-btm136 {
  margin-bottom: 8.5rem;
}

.mgn-lft136 {
  margin-left: 8.5rem;
}

.mgn-137 {
  margin: 8.5625rem;
}

.mgn-top137 {
  margin-top: 8.5625rem;
}

.mgn-rgt137 {
  margin-right: 8.5625rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625rem;
}

.mgn-lft137 {
  margin-left: 8.5625rem;
}

.mgn-138 {
  margin: 8.625rem;
}

.mgn-top138 {
  margin-top: 8.625rem;
}

.mgn-rgt138 {
  margin-right: 8.625rem;
}

.mgn-btm138 {
  margin-bottom: 8.625rem;
}

.mgn-lft138 {
  margin-left: 8.625rem;
}

.mgn-139 {
  margin: 8.6875rem;
}

.mgn-top139 {
  margin-top: 8.6875rem;
}

.mgn-rgt139 {
  margin-right: 8.6875rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875rem;
}

.mgn-lft139 {
  margin-left: 8.6875rem;
}

.mgn-140 {
  margin: 8.75rem;
}

.mgn-top140 {
  margin-top: 8.75rem;
}

.mgn-rgt140 {
  margin-right: 8.75rem;
}

.mgn-btm140 {
  margin-bottom: 8.75rem;
}

.mgn-lft140 {
  margin-left: 8.75rem;
}

.mgn-141 {
  margin: 8.8125rem;
}

.mgn-top141 {
  margin-top: 8.8125rem;
}

.mgn-rgt141 {
  margin-right: 8.8125rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125rem;
}

.mgn-lft141 {
  margin-left: 8.8125rem;
}

.mgn-142 {
  margin: 8.875rem;
}

.mgn-top142 {
  margin-top: 8.875rem;
}

.mgn-rgt142 {
  margin-right: 8.875rem;
}

.mgn-btm142 {
  margin-bottom: 8.875rem;
}

.mgn-lft142 {
  margin-left: 8.875rem;
}

.mgn-143 {
  margin: 8.9375rem;
}

.mgn-top143 {
  margin-top: 8.9375rem;
}

.mgn-rgt143 {
  margin-right: 8.9375rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375rem;
}

.mgn-lft143 {
  margin-left: 8.9375rem;
}

.mgn-144 {
  margin: 9rem;
}

.mgn-top144 {
  margin-top: 9rem;
}

.mgn-rgt144 {
  margin-right: 9rem;
}

.mgn-btm144 {
  margin-bottom: 9rem;
}

.mgn-lft144 {
  margin-left: 9rem;
}

.mgn-145 {
  margin: 9.0625rem;
}

.mgn-top145 {
  margin-top: 9.0625rem;
}

.mgn-rgt145 {
  margin-right: 9.0625rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625rem;
}

.mgn-lft145 {
  margin-left: 9.0625rem;
}

.mgn-146 {
  margin: 9.125rem;
}

.mgn-top146 {
  margin-top: 9.125rem;
}

.mgn-rgt146 {
  margin-right: 9.125rem;
}

.mgn-btm146 {
  margin-bottom: 9.125rem;
}

.mgn-lft146 {
  margin-left: 9.125rem;
}

.mgn-147 {
  margin: 9.1875rem;
}

.mgn-top147 {
  margin-top: 9.1875rem;
}

.mgn-rgt147 {
  margin-right: 9.1875rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875rem;
}

.mgn-lft147 {
  margin-left: 9.1875rem;
}

.mgn-148 {
  margin: 9.25rem;
}

.mgn-top148 {
  margin-top: 9.25rem;
}

.mgn-rgt148 {
  margin-right: 9.25rem;
}

.mgn-btm148 {
  margin-bottom: 9.25rem;
}

.mgn-lft148 {
  margin-left: 9.25rem;
}

.mgn-149 {
  margin: 9.3125rem;
}

.mgn-top149 {
  margin-top: 9.3125rem;
}

.mgn-rgt149 {
  margin-right: 9.3125rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125rem;
}

.mgn-lft149 {
  margin-left: 9.3125rem;
}

.mgn-150 {
  margin: 9.375rem;
}

.mgn-top150 {
  margin-top: 9.375rem;
}

.mgn-rgt150 {
  margin-right: 9.375rem;
}

.mgn-btm150 {
  margin-bottom: 9.375rem;
}

.mgn-lft150 {
  margin-left: 9.375rem;
}

@media screen and (max-width: 47.9375em) {
  .mgn-0 {
    margin: 0rem;
  }

  .mgn-top0 {
    margin-top: 0rem;
  }

  .mgn-rgt0 {
    margin-right: 0rem;
  }

  .mgn-btm0 {
    margin-bottom: 0rem;
  }

  .mgn-lft0 {
    margin-left: 0rem;
  }

  .mgn-1 {
    margin: 0.0357142857rem;
  }

  .mgn-top1 {
    margin-top: 0.0357142857rem;
  }

  .mgn-rgt1 {
    margin-right: 0.0357142857rem;
  }

  .mgn-btm1 {
    margin-bottom: 0.0357142857rem;
  }

  .mgn-lft1 {
    margin-left: 0.0357142857rem;
  }

  .mgn-2 {
    margin: 0.0714285714rem;
  }

  .mgn-top2 {
    margin-top: 0.0714285714rem;
  }

  .mgn-rgt2 {
    margin-right: 0.0714285714rem;
  }

  .mgn-btm2 {
    margin-bottom: 0.0714285714rem;
  }

  .mgn-lft2 {
    margin-left: 0.0714285714rem;
  }

  .mgn-3 {
    margin: 0.1071428571rem;
  }

  .mgn-top3 {
    margin-top: 0.1071428571rem;
  }

  .mgn-rgt3 {
    margin-right: 0.1071428571rem;
  }

  .mgn-btm3 {
    margin-bottom: 0.1071428571rem;
  }

  .mgn-lft3 {
    margin-left: 0.1071428571rem;
  }

  .mgn-4 {
    margin: 0.1428571429rem;
  }

  .mgn-top4 {
    margin-top: 0.1428571429rem;
  }

  .mgn-rgt4 {
    margin-right: 0.1428571429rem;
  }

  .mgn-btm4 {
    margin-bottom: 0.1428571429rem;
  }

  .mgn-lft4 {
    margin-left: 0.1428571429rem;
  }

  .mgn-5 {
    margin: 0.1785714286rem;
  }

  .mgn-top5 {
    margin-top: 0.1785714286rem;
  }

  .mgn-rgt5 {
    margin-right: 0.1785714286rem;
  }

  .mgn-btm5 {
    margin-bottom: 0.1785714286rem;
  }

  .mgn-lft5 {
    margin-left: 0.1785714286rem;
  }

  .mgn-6 {
    margin: 0.2142857143rem;
  }

  .mgn-top6 {
    margin-top: 0.2142857143rem;
  }

  .mgn-rgt6 {
    margin-right: 0.2142857143rem;
  }

  .mgn-btm6 {
    margin-bottom: 0.2142857143rem;
  }

  .mgn-lft6 {
    margin-left: 0.2142857143rem;
  }

  .mgn-7 {
    margin: 0.25rem;
  }

  .mgn-top7 {
    margin-top: 0.25rem;
  }

  .mgn-rgt7 {
    margin-right: 0.25rem;
  }

  .mgn-btm7 {
    margin-bottom: 0.25rem;
  }

  .mgn-lft7 {
    margin-left: 0.25rem;
  }

  .mgn-8 {
    margin: 0.2857142857rem;
  }

  .mgn-top8 {
    margin-top: 0.2857142857rem;
  }

  .mgn-rgt8 {
    margin-right: 0.2857142857rem;
  }

  .mgn-btm8 {
    margin-bottom: 0.2857142857rem;
  }

  .mgn-lft8 {
    margin-left: 0.2857142857rem;
  }

  .mgn-9 {
    margin: 0.3214285714rem;
  }

  .mgn-top9 {
    margin-top: 0.3214285714rem;
  }

  .mgn-rgt9 {
    margin-right: 0.3214285714rem;
  }

  .mgn-btm9 {
    margin-bottom: 0.3214285714rem;
  }

  .mgn-lft9 {
    margin-left: 0.3214285714rem;
  }

  .mgn-10 {
    margin: 0.3571428571rem;
  }

  .mgn-top10 {
    margin-top: 0.3571428571rem;
  }

  .mgn-rgt10 {
    margin-right: 0.3571428571rem;
  }

  .mgn-btm10 {
    margin-bottom: 0.3571428571rem;
  }

  .mgn-lft10 {
    margin-left: 0.3571428571rem;
  }

  .mgn-11 {
    margin: 0.3928571429rem;
  }

  .mgn-top11 {
    margin-top: 0.3928571429rem;
  }

  .mgn-rgt11 {
    margin-right: 0.3928571429rem;
  }

  .mgn-btm11 {
    margin-bottom: 0.3928571429rem;
  }

  .mgn-lft11 {
    margin-left: 0.3928571429rem;
  }

  .mgn-12 {
    margin: 0.4285714286rem;
  }

  .mgn-top12 {
    margin-top: 0.4285714286rem;
  }

  .mgn-rgt12 {
    margin-right: 0.4285714286rem;
  }

  .mgn-btm12 {
    margin-bottom: 0.4285714286rem;
  }

  .mgn-lft12 {
    margin-left: 0.4285714286rem;
  }

  .mgn-13 {
    margin: 0.4642857143rem;
  }

  .mgn-top13 {
    margin-top: 0.4642857143rem;
  }

  .mgn-rgt13 {
    margin-right: 0.4642857143rem;
  }

  .mgn-btm13 {
    margin-bottom: 0.4642857143rem;
  }

  .mgn-lft13 {
    margin-left: 0.4642857143rem;
  }

  .mgn-14 {
    margin: 0.5rem;
  }

  .mgn-top14 {
    margin-top: 0.5rem;
  }

  .mgn-rgt14 {
    margin-right: 0.5rem;
  }

  .mgn-btm14 {
    margin-bottom: 0.5rem;
  }

  .mgn-lft14 {
    margin-left: 0.5rem;
  }

  .mgn-15 {
    margin: 0.5357142857rem;
  }

  .mgn-top15 {
    margin-top: 0.5357142857rem;
  }

  .mgn-rgt15 {
    margin-right: 0.5357142857rem;
  }

  .mgn-btm15 {
    margin-bottom: 0.5357142857rem;
  }

  .mgn-lft15 {
    margin-left: 0.5357142857rem;
  }

  .mgn-16 {
    margin: 0.5714285714rem;
  }

  .mgn-top16 {
    margin-top: 0.5714285714rem;
  }

  .mgn-rgt16 {
    margin-right: 0.5714285714rem;
  }

  .mgn-btm16 {
    margin-bottom: 0.5714285714rem;
  }

  .mgn-lft16 {
    margin-left: 0.5714285714rem;
  }

  .mgn-17 {
    margin: 0.6071428571rem;
  }

  .mgn-top17 {
    margin-top: 0.6071428571rem;
  }

  .mgn-rgt17 {
    margin-right: 0.6071428571rem;
  }

  .mgn-btm17 {
    margin-bottom: 0.6071428571rem;
  }

  .mgn-lft17 {
    margin-left: 0.6071428571rem;
  }

  .mgn-18 {
    margin: 0.6428571429rem;
  }

  .mgn-top18 {
    margin-top: 0.6428571429rem;
  }

  .mgn-rgt18 {
    margin-right: 0.6428571429rem;
  }

  .mgn-btm18 {
    margin-bottom: 0.6428571429rem;
  }

  .mgn-lft18 {
    margin-left: 0.6428571429rem;
  }

  .mgn-19 {
    margin: 0.6785714286rem;
  }

  .mgn-top19 {
    margin-top: 0.6785714286rem;
  }

  .mgn-rgt19 {
    margin-right: 0.6785714286rem;
  }

  .mgn-btm19 {
    margin-bottom: 0.6785714286rem;
  }

  .mgn-lft19 {
    margin-left: 0.6785714286rem;
  }

  .mgn-20 {
    margin: 0.7142857143rem;
  }

  .mgn-top20 {
    margin-top: 0.7142857143rem;
  }

  .mgn-rgt20 {
    margin-right: 0.7142857143rem;
  }

  .mgn-btm20 {
    margin-bottom: 0.7142857143rem;
  }

  .mgn-lft20 {
    margin-left: 0.7142857143rem;
  }

  .mgn-21 {
    margin: 0.75rem;
  }

  .mgn-top21 {
    margin-top: 0.75rem;
  }

  .mgn-rgt21 {
    margin-right: 0.75rem;
  }

  .mgn-btm21 {
    margin-bottom: 0.75rem;
  }

  .mgn-lft21 {
    margin-left: 0.75rem;
  }

  .mgn-22 {
    margin: 0.7857142857rem;
  }

  .mgn-top22 {
    margin-top: 0.7857142857rem;
  }

  .mgn-rgt22 {
    margin-right: 0.7857142857rem;
  }

  .mgn-btm22 {
    margin-bottom: 0.7857142857rem;
  }

  .mgn-lft22 {
    margin-left: 0.7857142857rem;
  }

  .mgn-23 {
    margin: 0.8214285714rem;
  }

  .mgn-top23 {
    margin-top: 0.8214285714rem;
  }

  .mgn-rgt23 {
    margin-right: 0.8214285714rem;
  }

  .mgn-btm23 {
    margin-bottom: 0.8214285714rem;
  }

  .mgn-lft23 {
    margin-left: 0.8214285714rem;
  }

  .mgn-24 {
    margin: 0.8571428571rem;
  }

  .mgn-top24 {
    margin-top: 0.8571428571rem;
  }

  .mgn-rgt24 {
    margin-right: 0.8571428571rem;
  }

  .mgn-btm24 {
    margin-bottom: 0.8571428571rem;
  }

  .mgn-lft24 {
    margin-left: 0.8571428571rem;
  }

  .mgn-25 {
    margin: 0.8928571429rem;
  }

  .mgn-top25 {
    margin-top: 0.8928571429rem;
  }

  .mgn-rgt25 {
    margin-right: 0.8928571429rem;
  }

  .mgn-btm25 {
    margin-bottom: 0.8928571429rem;
  }

  .mgn-lft25 {
    margin-left: 0.8928571429rem;
  }

  .mgn-26 {
    margin: 0.9285714286rem;
  }

  .mgn-top26 {
    margin-top: 0.9285714286rem;
  }

  .mgn-rgt26 {
    margin-right: 0.9285714286rem;
  }

  .mgn-btm26 {
    margin-bottom: 0.9285714286rem;
  }

  .mgn-lft26 {
    margin-left: 0.9285714286rem;
  }

  .mgn-27 {
    margin: 0.9642857143rem;
  }

  .mgn-top27 {
    margin-top: 0.9642857143rem;
  }

  .mgn-rgt27 {
    margin-right: 0.9642857143rem;
  }

  .mgn-btm27 {
    margin-bottom: 0.9642857143rem;
  }

  .mgn-lft27 {
    margin-left: 0.9642857143rem;
  }

  .mgn-28 {
    margin: 1rem;
  }

  .mgn-top28 {
    margin-top: 1rem;
  }

  .mgn-rgt28 {
    margin-right: 1rem;
  }

  .mgn-btm28 {
    margin-bottom: 1rem;
  }

  .mgn-lft28 {
    margin-left: 1rem;
  }

  .mgn-29 {
    margin: 1.0357142857rem;
  }

  .mgn-top29 {
    margin-top: 1.0357142857rem;
  }

  .mgn-rgt29 {
    margin-right: 1.0357142857rem;
  }

  .mgn-btm29 {
    margin-bottom: 1.0357142857rem;
  }

  .mgn-lft29 {
    margin-left: 1.0357142857rem;
  }

  .mgn-30 {
    margin: 1.0714285714rem;
  }

  .mgn-top30 {
    margin-top: 1.0714285714rem;
  }

  .mgn-rgt30 {
    margin-right: 1.0714285714rem;
  }

  .mgn-btm30 {
    margin-bottom: 1.0714285714rem;
  }

  .mgn-lft30 {
    margin-left: 1.0714285714rem;
  }

  .mgn-31 {
    margin: 1.1071428571rem;
  }

  .mgn-top31 {
    margin-top: 1.1071428571rem;
  }

  .mgn-rgt31 {
    margin-right: 1.1071428571rem;
  }

  .mgn-btm31 {
    margin-bottom: 1.1071428571rem;
  }

  .mgn-lft31 {
    margin-left: 1.1071428571rem;
  }

  .mgn-32 {
    margin: 1.1428571429rem;
  }

  .mgn-top32 {
    margin-top: 1.1428571429rem;
  }

  .mgn-rgt32 {
    margin-right: 1.1428571429rem;
  }

  .mgn-btm32 {
    margin-bottom: 1.1428571429rem;
  }

  .mgn-lft32 {
    margin-left: 1.1428571429rem;
  }

  .mgn-33 {
    margin: 1.1785714286rem;
  }

  .mgn-top33 {
    margin-top: 1.1785714286rem;
  }

  .mgn-rgt33 {
    margin-right: 1.1785714286rem;
  }

  .mgn-btm33 {
    margin-bottom: 1.1785714286rem;
  }

  .mgn-lft33 {
    margin-left: 1.1785714286rem;
  }

  .mgn-34 {
    margin: 1.2142857143rem;
  }

  .mgn-top34 {
    margin-top: 1.2142857143rem;
  }

  .mgn-rgt34 {
    margin-right: 1.2142857143rem;
  }

  .mgn-btm34 {
    margin-bottom: 1.2142857143rem;
  }

  .mgn-lft34 {
    margin-left: 1.2142857143rem;
  }

  .mgn-35 {
    margin: 1.25rem;
  }

  .mgn-top35 {
    margin-top: 1.25rem;
  }

  .mgn-rgt35 {
    margin-right: 1.25rem;
  }

  .mgn-btm35 {
    margin-bottom: 1.25rem;
  }

  .mgn-lft35 {
    margin-left: 1.25rem;
  }

  .mgn-36 {
    margin: 1.2857142857rem;
  }

  .mgn-top36 {
    margin-top: 1.2857142857rem;
  }

  .mgn-rgt36 {
    margin-right: 1.2857142857rem;
  }

  .mgn-btm36 {
    margin-bottom: 1.2857142857rem;
  }

  .mgn-lft36 {
    margin-left: 1.2857142857rem;
  }

  .mgn-37 {
    margin: 1.3214285714rem;
  }

  .mgn-top37 {
    margin-top: 1.3214285714rem;
  }

  .mgn-rgt37 {
    margin-right: 1.3214285714rem;
  }

  .mgn-btm37 {
    margin-bottom: 1.3214285714rem;
  }

  .mgn-lft37 {
    margin-left: 1.3214285714rem;
  }

  .mgn-38 {
    margin: 1.3571428571rem;
  }

  .mgn-top38 {
    margin-top: 1.3571428571rem;
  }

  .mgn-rgt38 {
    margin-right: 1.3571428571rem;
  }

  .mgn-btm38 {
    margin-bottom: 1.3571428571rem;
  }

  .mgn-lft38 {
    margin-left: 1.3571428571rem;
  }

  .mgn-39 {
    margin: 1.3928571429rem;
  }

  .mgn-top39 {
    margin-top: 1.3928571429rem;
  }

  .mgn-rgt39 {
    margin-right: 1.3928571429rem;
  }

  .mgn-btm39 {
    margin-bottom: 1.3928571429rem;
  }

  .mgn-lft39 {
    margin-left: 1.3928571429rem;
  }

  .mgn-40 {
    margin: 1.4285714286rem;
  }

  .mgn-top40 {
    margin-top: 1.4285714286rem;
  }

  .mgn-rgt40 {
    margin-right: 1.4285714286rem;
  }

  .mgn-btm40 {
    margin-bottom: 1.4285714286rem;
  }

  .mgn-lft40 {
    margin-left: 1.4285714286rem;
  }

  .mgn-41 {
    margin: 1.4642857143rem;
  }

  .mgn-top41 {
    margin-top: 1.4642857143rem;
  }

  .mgn-rgt41 {
    margin-right: 1.4642857143rem;
  }

  .mgn-btm41 {
    margin-bottom: 1.4642857143rem;
  }

  .mgn-lft41 {
    margin-left: 1.4642857143rem;
  }

  .mgn-42 {
    margin: 1.5rem;
  }

  .mgn-top42 {
    margin-top: 1.5rem;
  }

  .mgn-rgt42 {
    margin-right: 1.5rem;
  }

  .mgn-btm42 {
    margin-bottom: 1.5rem;
  }

  .mgn-lft42 {
    margin-left: 1.5rem;
  }

  .mgn-43 {
    margin: 1.5357142857rem;
  }

  .mgn-top43 {
    margin-top: 1.5357142857rem;
  }

  .mgn-rgt43 {
    margin-right: 1.5357142857rem;
  }

  .mgn-btm43 {
    margin-bottom: 1.5357142857rem;
  }

  .mgn-lft43 {
    margin-left: 1.5357142857rem;
  }

  .mgn-44 {
    margin: 1.5714285714rem;
  }

  .mgn-top44 {
    margin-top: 1.5714285714rem;
  }

  .mgn-rgt44 {
    margin-right: 1.5714285714rem;
  }

  .mgn-btm44 {
    margin-bottom: 1.5714285714rem;
  }

  .mgn-lft44 {
    margin-left: 1.5714285714rem;
  }

  .mgn-45 {
    margin: 1.6071428571rem;
  }

  .mgn-top45 {
    margin-top: 1.6071428571rem;
  }

  .mgn-rgt45 {
    margin-right: 1.6071428571rem;
  }

  .mgn-btm45 {
    margin-bottom: 1.6071428571rem;
  }

  .mgn-lft45 {
    margin-left: 1.6071428571rem;
  }

  .mgn-46 {
    margin: 1.6428571429rem;
  }

  .mgn-top46 {
    margin-top: 1.6428571429rem;
  }

  .mgn-rgt46 {
    margin-right: 1.6428571429rem;
  }

  .mgn-btm46 {
    margin-bottom: 1.6428571429rem;
  }

  .mgn-lft46 {
    margin-left: 1.6428571429rem;
  }

  .mgn-47 {
    margin: 1.6785714286rem;
  }

  .mgn-top47 {
    margin-top: 1.6785714286rem;
  }

  .mgn-rgt47 {
    margin-right: 1.6785714286rem;
  }

  .mgn-btm47 {
    margin-bottom: 1.6785714286rem;
  }

  .mgn-lft47 {
    margin-left: 1.6785714286rem;
  }

  .mgn-48 {
    margin: 1.7142857143rem;
  }

  .mgn-top48 {
    margin-top: 1.7142857143rem;
  }

  .mgn-rgt48 {
    margin-right: 1.7142857143rem;
  }

  .mgn-btm48 {
    margin-bottom: 1.7142857143rem;
  }

  .mgn-lft48 {
    margin-left: 1.7142857143rem;
  }

  .mgn-49 {
    margin: 1.75rem;
  }

  .mgn-top49 {
    margin-top: 1.75rem;
  }

  .mgn-rgt49 {
    margin-right: 1.75rem;
  }

  .mgn-btm49 {
    margin-bottom: 1.75rem;
  }

  .mgn-lft49 {
    margin-left: 1.75rem;
  }

  .mgn-50 {
    margin: 1.7857142857rem;
  }

  .mgn-top50 {
    margin-top: 1.7857142857rem;
  }

  .mgn-rgt50 {
    margin-right: 1.7857142857rem;
  }

  .mgn-btm50 {
    margin-bottom: 1.7857142857rem;
  }

  .mgn-lft50 {
    margin-left: 1.7857142857rem;
  }

  .mgn-51 {
    margin: 1.8214285714rem;
  }

  .mgn-top51 {
    margin-top: 1.8214285714rem;
  }

  .mgn-rgt51 {
    margin-right: 1.8214285714rem;
  }

  .mgn-btm51 {
    margin-bottom: 1.8214285714rem;
  }

  .mgn-lft51 {
    margin-left: 1.8214285714rem;
  }

  .mgn-52 {
    margin: 1.8571428571rem;
  }

  .mgn-top52 {
    margin-top: 1.8571428571rem;
  }

  .mgn-rgt52 {
    margin-right: 1.8571428571rem;
  }

  .mgn-btm52 {
    margin-bottom: 1.8571428571rem;
  }

  .mgn-lft52 {
    margin-left: 1.8571428571rem;
  }

  .mgn-53 {
    margin: 1.8928571429rem;
  }

  .mgn-top53 {
    margin-top: 1.8928571429rem;
  }

  .mgn-rgt53 {
    margin-right: 1.8928571429rem;
  }

  .mgn-btm53 {
    margin-bottom: 1.8928571429rem;
  }

  .mgn-lft53 {
    margin-left: 1.8928571429rem;
  }

  .mgn-54 {
    margin: 1.9285714286rem;
  }

  .mgn-top54 {
    margin-top: 1.9285714286rem;
  }

  .mgn-rgt54 {
    margin-right: 1.9285714286rem;
  }

  .mgn-btm54 {
    margin-bottom: 1.9285714286rem;
  }

  .mgn-lft54 {
    margin-left: 1.9285714286rem;
  }

  .mgn-55 {
    margin: 1.9642857143rem;
  }

  .mgn-top55 {
    margin-top: 1.9642857143rem;
  }

  .mgn-rgt55 {
    margin-right: 1.9642857143rem;
  }

  .mgn-btm55 {
    margin-bottom: 1.9642857143rem;
  }

  .mgn-lft55 {
    margin-left: 1.9642857143rem;
  }

  .mgn-56 {
    margin: 2rem;
  }

  .mgn-top56 {
    margin-top: 2rem;
  }

  .mgn-rgt56 {
    margin-right: 2rem;
  }

  .mgn-btm56 {
    margin-bottom: 2rem;
  }

  .mgn-lft56 {
    margin-left: 2rem;
  }

  .mgn-57 {
    margin: 2.0357142857rem;
  }

  .mgn-top57 {
    margin-top: 2.0357142857rem;
  }

  .mgn-rgt57 {
    margin-right: 2.0357142857rem;
  }

  .mgn-btm57 {
    margin-bottom: 2.0357142857rem;
  }

  .mgn-lft57 {
    margin-left: 2.0357142857rem;
  }

  .mgn-58 {
    margin: 2.0714285714rem;
  }

  .mgn-top58 {
    margin-top: 2.0714285714rem;
  }

  .mgn-rgt58 {
    margin-right: 2.0714285714rem;
  }

  .mgn-btm58 {
    margin-bottom: 2.0714285714rem;
  }

  .mgn-lft58 {
    margin-left: 2.0714285714rem;
  }

  .mgn-59 {
    margin: 2.1071428571rem;
  }

  .mgn-top59 {
    margin-top: 2.1071428571rem;
  }

  .mgn-rgt59 {
    margin-right: 2.1071428571rem;
  }

  .mgn-btm59 {
    margin-bottom: 2.1071428571rem;
  }

  .mgn-lft59 {
    margin-left: 2.1071428571rem;
  }

  .mgn-60 {
    margin: 2.1428571429rem;
  }

  .mgn-top60 {
    margin-top: 2.1428571429rem;
  }

  .mgn-rgt60 {
    margin-right: 2.1428571429rem;
  }

  .mgn-btm60 {
    margin-bottom: 2.1428571429rem;
  }

  .mgn-lft60 {
    margin-left: 2.1428571429rem;
  }

  .mgn-61 {
    margin: 2.1785714286rem;
  }

  .mgn-top61 {
    margin-top: 2.1785714286rem;
  }

  .mgn-rgt61 {
    margin-right: 2.1785714286rem;
  }

  .mgn-btm61 {
    margin-bottom: 2.1785714286rem;
  }

  .mgn-lft61 {
    margin-left: 2.1785714286rem;
  }

  .mgn-62 {
    margin: 2.2142857143rem;
  }

  .mgn-top62 {
    margin-top: 2.2142857143rem;
  }

  .mgn-rgt62 {
    margin-right: 2.2142857143rem;
  }

  .mgn-btm62 {
    margin-bottom: 2.2142857143rem;
  }

  .mgn-lft62 {
    margin-left: 2.2142857143rem;
  }

  .mgn-63 {
    margin: 2.25rem;
  }

  .mgn-top63 {
    margin-top: 2.25rem;
  }

  .mgn-rgt63 {
    margin-right: 2.25rem;
  }

  .mgn-btm63 {
    margin-bottom: 2.25rem;
  }

  .mgn-lft63 {
    margin-left: 2.25rem;
  }

  .mgn-64 {
    margin: 2.2857142857rem;
  }

  .mgn-top64 {
    margin-top: 2.2857142857rem;
  }

  .mgn-rgt64 {
    margin-right: 2.2857142857rem;
  }

  .mgn-btm64 {
    margin-bottom: 2.2857142857rem;
  }

  .mgn-lft64 {
    margin-left: 2.2857142857rem;
  }

  .mgn-65 {
    margin: 2.3214285714rem;
  }

  .mgn-top65 {
    margin-top: 2.3214285714rem;
  }

  .mgn-rgt65 {
    margin-right: 2.3214285714rem;
  }

  .mgn-btm65 {
    margin-bottom: 2.3214285714rem;
  }

  .mgn-lft65 {
    margin-left: 2.3214285714rem;
  }

  .mgn-66 {
    margin: 2.3571428571rem;
  }

  .mgn-top66 {
    margin-top: 2.3571428571rem;
  }

  .mgn-rgt66 {
    margin-right: 2.3571428571rem;
  }

  .mgn-btm66 {
    margin-bottom: 2.3571428571rem;
  }

  .mgn-lft66 {
    margin-left: 2.3571428571rem;
  }

  .mgn-67 {
    margin: 2.3928571429rem;
  }

  .mgn-top67 {
    margin-top: 2.3928571429rem;
  }

  .mgn-rgt67 {
    margin-right: 2.3928571429rem;
  }

  .mgn-btm67 {
    margin-bottom: 2.3928571429rem;
  }

  .mgn-lft67 {
    margin-left: 2.3928571429rem;
  }

  .mgn-68 {
    margin: 2.4285714286rem;
  }

  .mgn-top68 {
    margin-top: 2.4285714286rem;
  }

  .mgn-rgt68 {
    margin-right: 2.4285714286rem;
  }

  .mgn-btm68 {
    margin-bottom: 2.4285714286rem;
  }

  .mgn-lft68 {
    margin-left: 2.4285714286rem;
  }

  .mgn-69 {
    margin: 2.4642857143rem;
  }

  .mgn-top69 {
    margin-top: 2.4642857143rem;
  }

  .mgn-rgt69 {
    margin-right: 2.4642857143rem;
  }

  .mgn-btm69 {
    margin-bottom: 2.4642857143rem;
  }

  .mgn-lft69 {
    margin-left: 2.4642857143rem;
  }

  .mgn-70 {
    margin: 2.5rem;
  }

  .mgn-top70 {
    margin-top: 2.5rem;
  }

  .mgn-rgt70 {
    margin-right: 2.5rem;
  }

  .mgn-btm70 {
    margin-bottom: 2.5rem;
  }

  .mgn-lft70 {
    margin-left: 2.5rem;
  }

  .mgn-71 {
    margin: 2.5357142857rem;
  }

  .mgn-top71 {
    margin-top: 2.5357142857rem;
  }

  .mgn-rgt71 {
    margin-right: 2.5357142857rem;
  }

  .mgn-btm71 {
    margin-bottom: 2.5357142857rem;
  }

  .mgn-lft71 {
    margin-left: 2.5357142857rem;
  }

  .mgn-72 {
    margin: 2.5714285714rem;
  }

  .mgn-top72 {
    margin-top: 2.5714285714rem;
  }

  .mgn-rgt72 {
    margin-right: 2.5714285714rem;
  }

  .mgn-btm72 {
    margin-bottom: 2.5714285714rem;
  }

  .mgn-lft72 {
    margin-left: 2.5714285714rem;
  }

  .mgn-73 {
    margin: 2.6071428571rem;
  }

  .mgn-top73 {
    margin-top: 2.6071428571rem;
  }

  .mgn-rgt73 {
    margin-right: 2.6071428571rem;
  }

  .mgn-btm73 {
    margin-bottom: 2.6071428571rem;
  }

  .mgn-lft73 {
    margin-left: 2.6071428571rem;
  }

  .mgn-74 {
    margin: 2.6428571429rem;
  }

  .mgn-top74 {
    margin-top: 2.6428571429rem;
  }

  .mgn-rgt74 {
    margin-right: 2.6428571429rem;
  }

  .mgn-btm74 {
    margin-bottom: 2.6428571429rem;
  }

  .mgn-lft74 {
    margin-left: 2.6428571429rem;
  }

  .mgn-75 {
    margin: 2.6785714286rem;
  }

  .mgn-top75 {
    margin-top: 2.6785714286rem;
  }

  .mgn-rgt75 {
    margin-right: 2.6785714286rem;
  }

  .mgn-btm75 {
    margin-bottom: 2.6785714286rem;
  }

  .mgn-lft75 {
    margin-left: 2.6785714286rem;
  }

  .mgn-76 {
    margin: 2.7142857143rem;
  }

  .mgn-top76 {
    margin-top: 2.7142857143rem;
  }

  .mgn-rgt76 {
    margin-right: 2.7142857143rem;
  }

  .mgn-btm76 {
    margin-bottom: 2.7142857143rem;
  }

  .mgn-lft76 {
    margin-left: 2.7142857143rem;
  }

  .mgn-77 {
    margin: 2.75rem;
  }

  .mgn-top77 {
    margin-top: 2.75rem;
  }

  .mgn-rgt77 {
    margin-right: 2.75rem;
  }

  .mgn-btm77 {
    margin-bottom: 2.75rem;
  }

  .mgn-lft77 {
    margin-left: 2.75rem;
  }

  .mgn-78 {
    margin: 2.7857142857rem;
  }

  .mgn-top78 {
    margin-top: 2.7857142857rem;
  }

  .mgn-rgt78 {
    margin-right: 2.7857142857rem;
  }

  .mgn-btm78 {
    margin-bottom: 2.7857142857rem;
  }

  .mgn-lft78 {
    margin-left: 2.7857142857rem;
  }

  .mgn-79 {
    margin: 2.8214285714rem;
  }

  .mgn-top79 {
    margin-top: 2.8214285714rem;
  }

  .mgn-rgt79 {
    margin-right: 2.8214285714rem;
  }

  .mgn-btm79 {
    margin-bottom: 2.8214285714rem;
  }

  .mgn-lft79 {
    margin-left: 2.8214285714rem;
  }

  .mgn-80 {
    margin: 2.8571428571rem;
  }

  .mgn-top80 {
    margin-top: 2.8571428571rem;
  }

  .mgn-rgt80 {
    margin-right: 2.8571428571rem;
  }

  .mgn-btm80 {
    margin-bottom: 2.8571428571rem;
  }

  .mgn-lft80 {
    margin-left: 2.8571428571rem;
  }

  .mgn-81 {
    margin: 2.8928571429rem;
  }

  .mgn-top81 {
    margin-top: 2.8928571429rem;
  }

  .mgn-rgt81 {
    margin-right: 2.8928571429rem;
  }

  .mgn-btm81 {
    margin-bottom: 2.8928571429rem;
  }

  .mgn-lft81 {
    margin-left: 2.8928571429rem;
  }

  .mgn-82 {
    margin: 2.9285714286rem;
  }

  .mgn-top82 {
    margin-top: 2.9285714286rem;
  }

  .mgn-rgt82 {
    margin-right: 2.9285714286rem;
  }

  .mgn-btm82 {
    margin-bottom: 2.9285714286rem;
  }

  .mgn-lft82 {
    margin-left: 2.9285714286rem;
  }

  .mgn-83 {
    margin: 2.9642857143rem;
  }

  .mgn-top83 {
    margin-top: 2.9642857143rem;
  }

  .mgn-rgt83 {
    margin-right: 2.9642857143rem;
  }

  .mgn-btm83 {
    margin-bottom: 2.9642857143rem;
  }

  .mgn-lft83 {
    margin-left: 2.9642857143rem;
  }

  .mgn-84 {
    margin: 3rem;
  }

  .mgn-top84 {
    margin-top: 3rem;
  }

  .mgn-rgt84 {
    margin-right: 3rem;
  }

  .mgn-btm84 {
    margin-bottom: 3rem;
  }

  .mgn-lft84 {
    margin-left: 3rem;
  }

  .mgn-85 {
    margin: 3.0357142857rem;
  }

  .mgn-top85 {
    margin-top: 3.0357142857rem;
  }

  .mgn-rgt85 {
    margin-right: 3.0357142857rem;
  }

  .mgn-btm85 {
    margin-bottom: 3.0357142857rem;
  }

  .mgn-lft85 {
    margin-left: 3.0357142857rem;
  }

  .mgn-86 {
    margin: 3.0714285714rem;
  }

  .mgn-top86 {
    margin-top: 3.0714285714rem;
  }

  .mgn-rgt86 {
    margin-right: 3.0714285714rem;
  }

  .mgn-btm86 {
    margin-bottom: 3.0714285714rem;
  }

  .mgn-lft86 {
    margin-left: 3.0714285714rem;
  }

  .mgn-87 {
    margin: 3.1071428571rem;
  }

  .mgn-top87 {
    margin-top: 3.1071428571rem;
  }

  .mgn-rgt87 {
    margin-right: 3.1071428571rem;
  }

  .mgn-btm87 {
    margin-bottom: 3.1071428571rem;
  }

  .mgn-lft87 {
    margin-left: 3.1071428571rem;
  }

  .mgn-88 {
    margin: 3.1428571429rem;
  }

  .mgn-top88 {
    margin-top: 3.1428571429rem;
  }

  .mgn-rgt88 {
    margin-right: 3.1428571429rem;
  }

  .mgn-btm88 {
    margin-bottom: 3.1428571429rem;
  }

  .mgn-lft88 {
    margin-left: 3.1428571429rem;
  }

  .mgn-89 {
    margin: 3.1785714286rem;
  }

  .mgn-top89 {
    margin-top: 3.1785714286rem;
  }

  .mgn-rgt89 {
    margin-right: 3.1785714286rem;
  }

  .mgn-btm89 {
    margin-bottom: 3.1785714286rem;
  }

  .mgn-lft89 {
    margin-left: 3.1785714286rem;
  }

  .mgn-90 {
    margin: 3.2142857143rem;
  }

  .mgn-top90 {
    margin-top: 3.2142857143rem;
  }

  .mgn-rgt90 {
    margin-right: 3.2142857143rem;
  }

  .mgn-btm90 {
    margin-bottom: 3.2142857143rem;
  }

  .mgn-lft90 {
    margin-left: 3.2142857143rem;
  }

  .mgn-91 {
    margin: 3.25rem;
  }

  .mgn-top91 {
    margin-top: 3.25rem;
  }

  .mgn-rgt91 {
    margin-right: 3.25rem;
  }

  .mgn-btm91 {
    margin-bottom: 3.25rem;
  }

  .mgn-lft91 {
    margin-left: 3.25rem;
  }

  .mgn-92 {
    margin: 3.2857142857rem;
  }

  .mgn-top92 {
    margin-top: 3.2857142857rem;
  }

  .mgn-rgt92 {
    margin-right: 3.2857142857rem;
  }

  .mgn-btm92 {
    margin-bottom: 3.2857142857rem;
  }

  .mgn-lft92 {
    margin-left: 3.2857142857rem;
  }

  .mgn-93 {
    margin: 3.3214285714rem;
  }

  .mgn-top93 {
    margin-top: 3.3214285714rem;
  }

  .mgn-rgt93 {
    margin-right: 3.3214285714rem;
  }

  .mgn-btm93 {
    margin-bottom: 3.3214285714rem;
  }

  .mgn-lft93 {
    margin-left: 3.3214285714rem;
  }

  .mgn-94 {
    margin: 3.3571428571rem;
  }

  .mgn-top94 {
    margin-top: 3.3571428571rem;
  }

  .mgn-rgt94 {
    margin-right: 3.3571428571rem;
  }

  .mgn-btm94 {
    margin-bottom: 3.3571428571rem;
  }

  .mgn-lft94 {
    margin-left: 3.3571428571rem;
  }

  .mgn-95 {
    margin: 3.3928571429rem;
  }

  .mgn-top95 {
    margin-top: 3.3928571429rem;
  }

  .mgn-rgt95 {
    margin-right: 3.3928571429rem;
  }

  .mgn-btm95 {
    margin-bottom: 3.3928571429rem;
  }

  .mgn-lft95 {
    margin-left: 3.3928571429rem;
  }

  .mgn-96 {
    margin: 3.4285714286rem;
  }

  .mgn-top96 {
    margin-top: 3.4285714286rem;
  }

  .mgn-rgt96 {
    margin-right: 3.4285714286rem;
  }

  .mgn-btm96 {
    margin-bottom: 3.4285714286rem;
  }

  .mgn-lft96 {
    margin-left: 3.4285714286rem;
  }

  .mgn-97 {
    margin: 3.4642857143rem;
  }

  .mgn-top97 {
    margin-top: 3.4642857143rem;
  }

  .mgn-rgt97 {
    margin-right: 3.4642857143rem;
  }

  .mgn-btm97 {
    margin-bottom: 3.4642857143rem;
  }

  .mgn-lft97 {
    margin-left: 3.4642857143rem;
  }

  .mgn-98 {
    margin: 3.5rem;
  }

  .mgn-top98 {
    margin-top: 3.5rem;
  }

  .mgn-rgt98 {
    margin-right: 3.5rem;
  }

  .mgn-btm98 {
    margin-bottom: 3.5rem;
  }

  .mgn-lft98 {
    margin-left: 3.5rem;
  }

  .mgn-99 {
    margin: 3.5357142857rem;
  }

  .mgn-top99 {
    margin-top: 3.5357142857rem;
  }

  .mgn-rgt99 {
    margin-right: 3.5357142857rem;
  }

  .mgn-btm99 {
    margin-bottom: 3.5357142857rem;
  }

  .mgn-lft99 {
    margin-left: 3.5357142857rem;
  }

  .mgn-100 {
    margin: 3.5714285714rem;
  }

  .mgn-top100 {
    margin-top: 3.5714285714rem;
  }

  .mgn-rgt100 {
    margin-right: 3.5714285714rem;
  }

  .mgn-btm100 {
    margin-bottom: 3.5714285714rem;
  }

  .mgn-lft100 {
    margin-left: 3.5714285714rem;
  }

  .mgn-101 {
    margin: 3.6071428571rem;
  }

  .mgn-top101 {
    margin-top: 3.6071428571rem;
  }

  .mgn-rgt101 {
    margin-right: 3.6071428571rem;
  }

  .mgn-btm101 {
    margin-bottom: 3.6071428571rem;
  }

  .mgn-lft101 {
    margin-left: 3.6071428571rem;
  }

  .mgn-102 {
    margin: 3.6428571429rem;
  }

  .mgn-top102 {
    margin-top: 3.6428571429rem;
  }

  .mgn-rgt102 {
    margin-right: 3.6428571429rem;
  }

  .mgn-btm102 {
    margin-bottom: 3.6428571429rem;
  }

  .mgn-lft102 {
    margin-left: 3.6428571429rem;
  }

  .mgn-103 {
    margin: 3.6785714286rem;
  }

  .mgn-top103 {
    margin-top: 3.6785714286rem;
  }

  .mgn-rgt103 {
    margin-right: 3.6785714286rem;
  }

  .mgn-btm103 {
    margin-bottom: 3.6785714286rem;
  }

  .mgn-lft103 {
    margin-left: 3.6785714286rem;
  }

  .mgn-104 {
    margin: 3.7142857143rem;
  }

  .mgn-top104 {
    margin-top: 3.7142857143rem;
  }

  .mgn-rgt104 {
    margin-right: 3.7142857143rem;
  }

  .mgn-btm104 {
    margin-bottom: 3.7142857143rem;
  }

  .mgn-lft104 {
    margin-left: 3.7142857143rem;
  }

  .mgn-105 {
    margin: 3.75rem;
  }

  .mgn-top105 {
    margin-top: 3.75rem;
  }

  .mgn-rgt105 {
    margin-right: 3.75rem;
  }

  .mgn-btm105 {
    margin-bottom: 3.75rem;
  }

  .mgn-lft105 {
    margin-left: 3.75rem;
  }

  .mgn-106 {
    margin: 3.7857142857rem;
  }

  .mgn-top106 {
    margin-top: 3.7857142857rem;
  }

  .mgn-rgt106 {
    margin-right: 3.7857142857rem;
  }

  .mgn-btm106 {
    margin-bottom: 3.7857142857rem;
  }

  .mgn-lft106 {
    margin-left: 3.7857142857rem;
  }

  .mgn-107 {
    margin: 3.8214285714rem;
  }

  .mgn-top107 {
    margin-top: 3.8214285714rem;
  }

  .mgn-rgt107 {
    margin-right: 3.8214285714rem;
  }

  .mgn-btm107 {
    margin-bottom: 3.8214285714rem;
  }

  .mgn-lft107 {
    margin-left: 3.8214285714rem;
  }

  .mgn-108 {
    margin: 3.8571428571rem;
  }

  .mgn-top108 {
    margin-top: 3.8571428571rem;
  }

  .mgn-rgt108 {
    margin-right: 3.8571428571rem;
  }

  .mgn-btm108 {
    margin-bottom: 3.8571428571rem;
  }

  .mgn-lft108 {
    margin-left: 3.8571428571rem;
  }

  .mgn-109 {
    margin: 3.8928571429rem;
  }

  .mgn-top109 {
    margin-top: 3.8928571429rem;
  }

  .mgn-rgt109 {
    margin-right: 3.8928571429rem;
  }

  .mgn-btm109 {
    margin-bottom: 3.8928571429rem;
  }

  .mgn-lft109 {
    margin-left: 3.8928571429rem;
  }

  .mgn-110 {
    margin: 3.9285714286rem;
  }

  .mgn-top110 {
    margin-top: 3.9285714286rem;
  }

  .mgn-rgt110 {
    margin-right: 3.9285714286rem;
  }

  .mgn-btm110 {
    margin-bottom: 3.9285714286rem;
  }

  .mgn-lft110 {
    margin-left: 3.9285714286rem;
  }

  .mgn-111 {
    margin: 3.9642857143rem;
  }

  .mgn-top111 {
    margin-top: 3.9642857143rem;
  }

  .mgn-rgt111 {
    margin-right: 3.9642857143rem;
  }

  .mgn-btm111 {
    margin-bottom: 3.9642857143rem;
  }

  .mgn-lft111 {
    margin-left: 3.9642857143rem;
  }

  .mgn-112 {
    margin: 4rem;
  }

  .mgn-top112 {
    margin-top: 4rem;
  }

  .mgn-rgt112 {
    margin-right: 4rem;
  }

  .mgn-btm112 {
    margin-bottom: 4rem;
  }

  .mgn-lft112 {
    margin-left: 4rem;
  }

  .mgn-113 {
    margin: 4.0357142857rem;
  }

  .mgn-top113 {
    margin-top: 4.0357142857rem;
  }

  .mgn-rgt113 {
    margin-right: 4.0357142857rem;
  }

  .mgn-btm113 {
    margin-bottom: 4.0357142857rem;
  }

  .mgn-lft113 {
    margin-left: 4.0357142857rem;
  }

  .mgn-114 {
    margin: 4.0714285714rem;
  }

  .mgn-top114 {
    margin-top: 4.0714285714rem;
  }

  .mgn-rgt114 {
    margin-right: 4.0714285714rem;
  }

  .mgn-btm114 {
    margin-bottom: 4.0714285714rem;
  }

  .mgn-lft114 {
    margin-left: 4.0714285714rem;
  }

  .mgn-115 {
    margin: 4.1071428571rem;
  }

  .mgn-top115 {
    margin-top: 4.1071428571rem;
  }

  .mgn-rgt115 {
    margin-right: 4.1071428571rem;
  }

  .mgn-btm115 {
    margin-bottom: 4.1071428571rem;
  }

  .mgn-lft115 {
    margin-left: 4.1071428571rem;
  }

  .mgn-116 {
    margin: 4.1428571429rem;
  }

  .mgn-top116 {
    margin-top: 4.1428571429rem;
  }

  .mgn-rgt116 {
    margin-right: 4.1428571429rem;
  }

  .mgn-btm116 {
    margin-bottom: 4.1428571429rem;
  }

  .mgn-lft116 {
    margin-left: 4.1428571429rem;
  }

  .mgn-117 {
    margin: 4.1785714286rem;
  }

  .mgn-top117 {
    margin-top: 4.1785714286rem;
  }

  .mgn-rgt117 {
    margin-right: 4.1785714286rem;
  }

  .mgn-btm117 {
    margin-bottom: 4.1785714286rem;
  }

  .mgn-lft117 {
    margin-left: 4.1785714286rem;
  }

  .mgn-118 {
    margin: 4.2142857143rem;
  }

  .mgn-top118 {
    margin-top: 4.2142857143rem;
  }

  .mgn-rgt118 {
    margin-right: 4.2142857143rem;
  }

  .mgn-btm118 {
    margin-bottom: 4.2142857143rem;
  }

  .mgn-lft118 {
    margin-left: 4.2142857143rem;
  }

  .mgn-119 {
    margin: 4.25rem;
  }

  .mgn-top119 {
    margin-top: 4.25rem;
  }

  .mgn-rgt119 {
    margin-right: 4.25rem;
  }

  .mgn-btm119 {
    margin-bottom: 4.25rem;
  }

  .mgn-lft119 {
    margin-left: 4.25rem;
  }

  .mgn-120 {
    margin: 4.2857142857rem;
  }

  .mgn-top120 {
    margin-top: 4.2857142857rem;
  }

  .mgn-rgt120 {
    margin-right: 4.2857142857rem;
  }

  .mgn-btm120 {
    margin-bottom: 4.2857142857rem;
  }

  .mgn-lft120 {
    margin-left: 4.2857142857rem;
  }

  .mgn-121 {
    margin: 4.3214285714rem;
  }

  .mgn-top121 {
    margin-top: 4.3214285714rem;
  }

  .mgn-rgt121 {
    margin-right: 4.3214285714rem;
  }

  .mgn-btm121 {
    margin-bottom: 4.3214285714rem;
  }

  .mgn-lft121 {
    margin-left: 4.3214285714rem;
  }

  .mgn-122 {
    margin: 4.3571428571rem;
  }

  .mgn-top122 {
    margin-top: 4.3571428571rem;
  }

  .mgn-rgt122 {
    margin-right: 4.3571428571rem;
  }

  .mgn-btm122 {
    margin-bottom: 4.3571428571rem;
  }

  .mgn-lft122 {
    margin-left: 4.3571428571rem;
  }

  .mgn-123 {
    margin: 4.3928571429rem;
  }

  .mgn-top123 {
    margin-top: 4.3928571429rem;
  }

  .mgn-rgt123 {
    margin-right: 4.3928571429rem;
  }

  .mgn-btm123 {
    margin-bottom: 4.3928571429rem;
  }

  .mgn-lft123 {
    margin-left: 4.3928571429rem;
  }

  .mgn-124 {
    margin: 4.4285714286rem;
  }

  .mgn-top124 {
    margin-top: 4.4285714286rem;
  }

  .mgn-rgt124 {
    margin-right: 4.4285714286rem;
  }

  .mgn-btm124 {
    margin-bottom: 4.4285714286rem;
  }

  .mgn-lft124 {
    margin-left: 4.4285714286rem;
  }

  .mgn-125 {
    margin: 4.4642857143rem;
  }

  .mgn-top125 {
    margin-top: 4.4642857143rem;
  }

  .mgn-rgt125 {
    margin-right: 4.4642857143rem;
  }

  .mgn-btm125 {
    margin-bottom: 4.4642857143rem;
  }

  .mgn-lft125 {
    margin-left: 4.4642857143rem;
  }

  .mgn-126 {
    margin: 4.5rem;
  }

  .mgn-top126 {
    margin-top: 4.5rem;
  }

  .mgn-rgt126 {
    margin-right: 4.5rem;
  }

  .mgn-btm126 {
    margin-bottom: 4.5rem;
  }

  .mgn-lft126 {
    margin-left: 4.5rem;
  }

  .mgn-127 {
    margin: 4.5357142857rem;
  }

  .mgn-top127 {
    margin-top: 4.5357142857rem;
  }

  .mgn-rgt127 {
    margin-right: 4.5357142857rem;
  }

  .mgn-btm127 {
    margin-bottom: 4.5357142857rem;
  }

  .mgn-lft127 {
    margin-left: 4.5357142857rem;
  }

  .mgn-128 {
    margin: 4.5714285714rem;
  }

  .mgn-top128 {
    margin-top: 4.5714285714rem;
  }

  .mgn-rgt128 {
    margin-right: 4.5714285714rem;
  }

  .mgn-btm128 {
    margin-bottom: 4.5714285714rem;
  }

  .mgn-lft128 {
    margin-left: 4.5714285714rem;
  }

  .mgn-129 {
    margin: 4.6071428571rem;
  }

  .mgn-top129 {
    margin-top: 4.6071428571rem;
  }

  .mgn-rgt129 {
    margin-right: 4.6071428571rem;
  }

  .mgn-btm129 {
    margin-bottom: 4.6071428571rem;
  }

  .mgn-lft129 {
    margin-left: 4.6071428571rem;
  }

  .mgn-130 {
    margin: 4.6428571429rem;
  }

  .mgn-top130 {
    margin-top: 4.6428571429rem;
  }

  .mgn-rgt130 {
    margin-right: 4.6428571429rem;
  }

  .mgn-btm130 {
    margin-bottom: 4.6428571429rem;
  }

  .mgn-lft130 {
    margin-left: 4.6428571429rem;
  }

  .mgn-131 {
    margin: 4.6785714286rem;
  }

  .mgn-top131 {
    margin-top: 4.6785714286rem;
  }

  .mgn-rgt131 {
    margin-right: 4.6785714286rem;
  }

  .mgn-btm131 {
    margin-bottom: 4.6785714286rem;
  }

  .mgn-lft131 {
    margin-left: 4.6785714286rem;
  }

  .mgn-132 {
    margin: 4.7142857143rem;
  }

  .mgn-top132 {
    margin-top: 4.7142857143rem;
  }

  .mgn-rgt132 {
    margin-right: 4.7142857143rem;
  }

  .mgn-btm132 {
    margin-bottom: 4.7142857143rem;
  }

  .mgn-lft132 {
    margin-left: 4.7142857143rem;
  }

  .mgn-133 {
    margin: 4.75rem;
  }

  .mgn-top133 {
    margin-top: 4.75rem;
  }

  .mgn-rgt133 {
    margin-right: 4.75rem;
  }

  .mgn-btm133 {
    margin-bottom: 4.75rem;
  }

  .mgn-lft133 {
    margin-left: 4.75rem;
  }

  .mgn-134 {
    margin: 4.7857142857rem;
  }

  .mgn-top134 {
    margin-top: 4.7857142857rem;
  }

  .mgn-rgt134 {
    margin-right: 4.7857142857rem;
  }

  .mgn-btm134 {
    margin-bottom: 4.7857142857rem;
  }

  .mgn-lft134 {
    margin-left: 4.7857142857rem;
  }

  .mgn-135 {
    margin: 4.8214285714rem;
  }

  .mgn-top135 {
    margin-top: 4.8214285714rem;
  }

  .mgn-rgt135 {
    margin-right: 4.8214285714rem;
  }

  .mgn-btm135 {
    margin-bottom: 4.8214285714rem;
  }

  .mgn-lft135 {
    margin-left: 4.8214285714rem;
  }

  .mgn-136 {
    margin: 4.8571428571rem;
  }

  .mgn-top136 {
    margin-top: 4.8571428571rem;
  }

  .mgn-rgt136 {
    margin-right: 4.8571428571rem;
  }

  .mgn-btm136 {
    margin-bottom: 4.8571428571rem;
  }

  .mgn-lft136 {
    margin-left: 4.8571428571rem;
  }

  .mgn-137 {
    margin: 4.8928571429rem;
  }

  .mgn-top137 {
    margin-top: 4.8928571429rem;
  }

  .mgn-rgt137 {
    margin-right: 4.8928571429rem;
  }

  .mgn-btm137 {
    margin-bottom: 4.8928571429rem;
  }

  .mgn-lft137 {
    margin-left: 4.8928571429rem;
  }

  .mgn-138 {
    margin: 4.9285714286rem;
  }

  .mgn-top138 {
    margin-top: 4.9285714286rem;
  }

  .mgn-rgt138 {
    margin-right: 4.9285714286rem;
  }

  .mgn-btm138 {
    margin-bottom: 4.9285714286rem;
  }

  .mgn-lft138 {
    margin-left: 4.9285714286rem;
  }

  .mgn-139 {
    margin: 4.9642857143rem;
  }

  .mgn-top139 {
    margin-top: 4.9642857143rem;
  }

  .mgn-rgt139 {
    margin-right: 4.9642857143rem;
  }

  .mgn-btm139 {
    margin-bottom: 4.9642857143rem;
  }

  .mgn-lft139 {
    margin-left: 4.9642857143rem;
  }

  .mgn-140 {
    margin: 5rem;
  }

  .mgn-top140 {
    margin-top: 5rem;
  }

  .mgn-rgt140 {
    margin-right: 5rem;
  }

  .mgn-btm140 {
    margin-bottom: 5rem;
  }

  .mgn-lft140 {
    margin-left: 5rem;
  }

  .mgn-141 {
    margin: 5.0357142857rem;
  }

  .mgn-top141 {
    margin-top: 5.0357142857rem;
  }

  .mgn-rgt141 {
    margin-right: 5.0357142857rem;
  }

  .mgn-btm141 {
    margin-bottom: 5.0357142857rem;
  }

  .mgn-lft141 {
    margin-left: 5.0357142857rem;
  }

  .mgn-142 {
    margin: 5.0714285714rem;
  }

  .mgn-top142 {
    margin-top: 5.0714285714rem;
  }

  .mgn-rgt142 {
    margin-right: 5.0714285714rem;
  }

  .mgn-btm142 {
    margin-bottom: 5.0714285714rem;
  }

  .mgn-lft142 {
    margin-left: 5.0714285714rem;
  }

  .mgn-143 {
    margin: 5.1071428571rem;
  }

  .mgn-top143 {
    margin-top: 5.1071428571rem;
  }

  .mgn-rgt143 {
    margin-right: 5.1071428571rem;
  }

  .mgn-btm143 {
    margin-bottom: 5.1071428571rem;
  }

  .mgn-lft143 {
    margin-left: 5.1071428571rem;
  }

  .mgn-144 {
    margin: 5.1428571429rem;
  }

  .mgn-top144 {
    margin-top: 5.1428571429rem;
  }

  .mgn-rgt144 {
    margin-right: 5.1428571429rem;
  }

  .mgn-btm144 {
    margin-bottom: 5.1428571429rem;
  }

  .mgn-lft144 {
    margin-left: 5.1428571429rem;
  }

  .mgn-145 {
    margin: 5.1785714286rem;
  }

  .mgn-top145 {
    margin-top: 5.1785714286rem;
  }

  .mgn-rgt145 {
    margin-right: 5.1785714286rem;
  }

  .mgn-btm145 {
    margin-bottom: 5.1785714286rem;
  }

  .mgn-lft145 {
    margin-left: 5.1785714286rem;
  }

  .mgn-146 {
    margin: 5.2142857143rem;
  }

  .mgn-top146 {
    margin-top: 5.2142857143rem;
  }

  .mgn-rgt146 {
    margin-right: 5.2142857143rem;
  }

  .mgn-btm146 {
    margin-bottom: 5.2142857143rem;
  }

  .mgn-lft146 {
    margin-left: 5.2142857143rem;
  }

  .mgn-147 {
    margin: 5.25rem;
  }

  .mgn-top147 {
    margin-top: 5.25rem;
  }

  .mgn-rgt147 {
    margin-right: 5.25rem;
  }

  .mgn-btm147 {
    margin-bottom: 5.25rem;
  }

  .mgn-lft147 {
    margin-left: 5.25rem;
  }

  .mgn-148 {
    margin: 5.2857142857rem;
  }

  .mgn-top148 {
    margin-top: 5.2857142857rem;
  }

  .mgn-rgt148 {
    margin-right: 5.2857142857rem;
  }

  .mgn-btm148 {
    margin-bottom: 5.2857142857rem;
  }

  .mgn-lft148 {
    margin-left: 5.2857142857rem;
  }

  .mgn-149 {
    margin: 5.3214285714rem;
  }

  .mgn-top149 {
    margin-top: 5.3214285714rem;
  }

  .mgn-rgt149 {
    margin-right: 5.3214285714rem;
  }

  .mgn-btm149 {
    margin-bottom: 5.3214285714rem;
  }

  .mgn-lft149 {
    margin-left: 5.3214285714rem;
  }

  .mgn-150 {
    margin: 5.3571428571rem;
  }

  .mgn-top150 {
    margin-top: 5.3571428571rem;
  }

  .mgn-rgt150 {
    margin-right: 5.3571428571rem;
  }

  .mgn-btm150 {
    margin-bottom: 5.3571428571rem;
  }

  .mgn-lft150 {
    margin-left: 5.3571428571rem;
  }
}
.mgn-0_em {
  margin: 0em;
}

.mgn-top0_em {
  margin-top: 0em;
}

.mgn-rgt0_em {
  margin-right: 0em;
}

.mgn-btm0_em {
  margin-bottom: 0em;
}

.mgn-lft0_em {
  margin-left: 0em;
}

.mgn-1_em {
  margin: 1em;
}

.mgn-top1_em {
  margin-top: 1em;
}

.mgn-rgt1_em {
  margin-right: 1em;
}

.mgn-btm1_em {
  margin-bottom: 1em;
}

.mgn-lft1_em {
  margin-left: 1em;
}

.mgn-2_em {
  margin: 2em;
}

.mgn-top2_em {
  margin-top: 2em;
}

.mgn-rgt2_em {
  margin-right: 2em;
}

.mgn-btm2_em {
  margin-bottom: 2em;
}

.mgn-lft2_em {
  margin-left: 2em;
}

.mgn-3_em {
  margin: 3em;
}

.mgn-top3_em {
  margin-top: 3em;
}

.mgn-rgt3_em {
  margin-right: 3em;
}

.mgn-btm3_em {
  margin-bottom: 3em;
}

.mgn-lft3_em {
  margin-left: 3em;
}

.mgn-4_em {
  margin: 4em;
}

.mgn-top4_em {
  margin-top: 4em;
}

.mgn-rgt4_em {
  margin-right: 4em;
}

.mgn-btm4_em {
  margin-bottom: 4em;
}

.mgn-lft4_em {
  margin-left: 4em;
}

.mgn-5_em {
  margin: 5em;
}

.mgn-top5_em {
  margin-top: 5em;
}

.mgn-rgt5_em {
  margin-right: 5em;
}

.mgn-btm5_em {
  margin-bottom: 5em;
}

.mgn-lft5_em {
  margin-left: 5em;
}

.mgn-6_em {
  margin: 6em;
}

.mgn-top6_em {
  margin-top: 6em;
}

.mgn-rgt6_em {
  margin-right: 6em;
}

.mgn-btm6_em {
  margin-bottom: 6em;
}

.mgn-lft6_em {
  margin-left: 6em;
}

.mgn-7_em {
  margin: 7em;
}

.mgn-top7_em {
  margin-top: 7em;
}

.mgn-rgt7_em {
  margin-right: 7em;
}

.mgn-btm7_em {
  margin-bottom: 7em;
}

.mgn-lft7_em {
  margin-left: 7em;
}

.mgn-8_em {
  margin: 8em;
}

.mgn-top8_em {
  margin-top: 8em;
}

.mgn-rgt8_em {
  margin-right: 8em;
}

.mgn-btm8_em {
  margin-bottom: 8em;
}

.mgn-lft8_em {
  margin-left: 8em;
}

.mgn-9_em {
  margin: 9em;
}

.mgn-top9_em {
  margin-top: 9em;
}

.mgn-rgt9_em {
  margin-right: 9em;
}

.mgn-btm9_em {
  margin-bottom: 9em;
}

.mgn-lft9_em {
  margin-left: 9em;
}

.mgn-10_em {
  margin: 10em;
}

.mgn-top10_em {
  margin-top: 10em;
}

.mgn-rgt10_em {
  margin-right: 10em;
}

.mgn-btm10_em {
  margin-bottom: 10em;
}

.mgn-lft10_em {
  margin-left: 10em;
}

.mgn-11_em {
  margin: 11em;
}

.mgn-top11_em {
  margin-top: 11em;
}

.mgn-rgt11_em {
  margin-right: 11em;
}

.mgn-btm11_em {
  margin-bottom: 11em;
}

.mgn-lft11_em {
  margin-left: 11em;
}

.mgn-12_em {
  margin: 12em;
}

.mgn-top12_em {
  margin-top: 12em;
}

.mgn-rgt12_em {
  margin-right: 12em;
}

.mgn-btm12_em {
  margin-bottom: 12em;
}

.mgn-lft12_em {
  margin-left: 12em;
}

.mgn-13_em {
  margin: 13em;
}

.mgn-top13_em {
  margin-top: 13em;
}

.mgn-rgt13_em {
  margin-right: 13em;
}

.mgn-btm13_em {
  margin-bottom: 13em;
}

.mgn-lft13_em {
  margin-left: 13em;
}

.mgn-14_em {
  margin: 14em;
}

.mgn-top14_em {
  margin-top: 14em;
}

.mgn-rgt14_em {
  margin-right: 14em;
}

.mgn-btm14_em {
  margin-bottom: 14em;
}

.mgn-lft14_em {
  margin-left: 14em;
}

.mgn-15_em {
  margin: 15em;
}

.mgn-top15_em {
  margin-top: 15em;
}

.mgn-rgt15_em {
  margin-right: 15em;
}

.mgn-btm15_em {
  margin-bottom: 15em;
}

.mgn-lft15_em {
  margin-left: 15em;
}

.pdg-0 {
  padding: 0px;
}

.pdg-top0 {
  padding-top: 0px;
}

.pdg-rgt0 {
  padding-right: 0px;
}

.pdg-btm0 {
  padding-bottom: 0px;
}

.pdg-lft0 {
  padding-left: 0px;
}

.pdg-1 {
  padding: 1px;
}

.pdg-top1 {
  padding-top: 1px;
}

.pdg-rgt1 {
  padding-right: 1px;
}

.pdg-btm1 {
  padding-bottom: 1px;
}

.pdg-lft1 {
  padding-left: 1px;
}

.pdg-2 {
  padding: 2px;
}

.pdg-top2 {
  padding-top: 2px;
}

.pdg-rgt2 {
  padding-right: 2px;
}

.pdg-btm2 {
  padding-bottom: 2px;
}

.pdg-lft2 {
  padding-left: 2px;
}

.pdg-3 {
  padding: 3px;
}

.pdg-top3 {
  padding-top: 3px;
}

.pdg-rgt3 {
  padding-right: 3px;
}

.pdg-btm3 {
  padding-bottom: 3px;
}

.pdg-lft3 {
  padding-left: 3px;
}

.pdg-4 {
  padding: 4px;
}

.pdg-top4 {
  padding-top: 4px;
}

.pdg-rgt4 {
  padding-right: 4px;
}

.pdg-btm4 {
  padding-bottom: 4px;
}

.pdg-lft4 {
  padding-left: 4px;
}

.pdg-5 {
  padding: 5px;
}

.pdg-top5 {
  padding-top: 5px;
}

.pdg-rgt5 {
  padding-right: 5px;
}

.pdg-btm5 {
  padding-bottom: 5px;
}

.pdg-lft5 {
  padding-left: 5px;
}

.pdg-6 {
  padding: 6px;
}

.pdg-top6 {
  padding-top: 6px;
}

.pdg-rgt6 {
  padding-right: 6px;
}

.pdg-btm6 {
  padding-bottom: 6px;
}

.pdg-lft6 {
  padding-left: 6px;
}

.pdg-7 {
  padding: 7px;
}

.pdg-top7 {
  padding-top: 7px;
}

.pdg-rgt7 {
  padding-right: 7px;
}

.pdg-btm7 {
  padding-bottom: 7px;
}

.pdg-lft7 {
  padding-left: 7px;
}

.pdg-8 {
  padding: 8px;
}

.pdg-top8 {
  padding-top: 8px;
}

.pdg-rgt8 {
  padding-right: 8px;
}

.pdg-btm8 {
  padding-bottom: 8px;
}

.pdg-lft8 {
  padding-left: 8px;
}

.pdg-9 {
  padding: 9px;
}

.pdg-top9 {
  padding-top: 9px;
}

.pdg-rgt9 {
  padding-right: 9px;
}

.pdg-btm9 {
  padding-bottom: 9px;
}

.pdg-lft9 {
  padding-left: 9px;
}

.pdg-10 {
  padding: 10px;
}

.pdg-top10 {
  padding-top: 10px;
}

.pdg-rgt10 {
  padding-right: 10px;
}

.pdg-btm10 {
  padding-bottom: 10px;
}

.pdg-lft10 {
  padding-left: 10px;
}

.pdg-11 {
  padding: 11px;
}

.pdg-top11 {
  padding-top: 11px;
}

.pdg-rgt11 {
  padding-right: 11px;
}

.pdg-btm11 {
  padding-bottom: 11px;
}

.pdg-lft11 {
  padding-left: 11px;
}

.pdg-12 {
  padding: 12px;
}

.pdg-top12 {
  padding-top: 12px;
}

.pdg-rgt12 {
  padding-right: 12px;
}

.pdg-btm12 {
  padding-bottom: 12px;
}

.pdg-lft12 {
  padding-left: 12px;
}

.pdg-13 {
  padding: 13px;
}

.pdg-top13 {
  padding-top: 13px;
}

.pdg-rgt13 {
  padding-right: 13px;
}

.pdg-btm13 {
  padding-bottom: 13px;
}

.pdg-lft13 {
  padding-left: 13px;
}

.pdg-14 {
  padding: 14px;
}

.pdg-top14 {
  padding-top: 14px;
}

.pdg-rgt14 {
  padding-right: 14px;
}

.pdg-btm14 {
  padding-bottom: 14px;
}

.pdg-lft14 {
  padding-left: 14px;
}

.pdg-15 {
  padding: 15px;
}

.pdg-top15 {
  padding-top: 15px;
}

.pdg-rgt15 {
  padding-right: 15px;
}

.pdg-btm15 {
  padding-bottom: 15px;
}

.pdg-lft15 {
  padding-left: 15px;
}

.pdg-16 {
  padding: 16px;
}

.pdg-top16 {
  padding-top: 16px;
}

.pdg-rgt16 {
  padding-right: 16px;
}

.pdg-btm16 {
  padding-bottom: 16px;
}

.pdg-lft16 {
  padding-left: 16px;
}

.pdg-17 {
  padding: 17px;
}

.pdg-top17 {
  padding-top: 17px;
}

.pdg-rgt17 {
  padding-right: 17px;
}

.pdg-btm17 {
  padding-bottom: 17px;
}

.pdg-lft17 {
  padding-left: 17px;
}

.pdg-18 {
  padding: 18px;
}

.pdg-top18 {
  padding-top: 18px;
}

.pdg-rgt18 {
  padding-right: 18px;
}

.pdg-btm18 {
  padding-bottom: 18px;
}

.pdg-lft18 {
  padding-left: 18px;
}

.pdg-19 {
  padding: 19px;
}

.pdg-top19 {
  padding-top: 19px;
}

.pdg-rgt19 {
  padding-right: 19px;
}

.pdg-btm19 {
  padding-bottom: 19px;
}

.pdg-lft19 {
  padding-left: 19px;
}

.pdg-20 {
  padding: 20px;
}

.pdg-top20 {
  padding-top: 20px;
}

.pdg-rgt20 {
  padding-right: 20px;
}

.pdg-btm20 {
  padding-bottom: 20px;
}

.pdg-lft20 {
  padding-left: 20px;
}

.pdg-21 {
  padding: 21px;
}

.pdg-top21 {
  padding-top: 21px;
}

.pdg-rgt21 {
  padding-right: 21px;
}

.pdg-btm21 {
  padding-bottom: 21px;
}

.pdg-lft21 {
  padding-left: 21px;
}

.pdg-22 {
  padding: 22px;
}

.pdg-top22 {
  padding-top: 22px;
}

.pdg-rgt22 {
  padding-right: 22px;
}

.pdg-btm22 {
  padding-bottom: 22px;
}

.pdg-lft22 {
  padding-left: 22px;
}

.pdg-23 {
  padding: 23px;
}

.pdg-top23 {
  padding-top: 23px;
}

.pdg-rgt23 {
  padding-right: 23px;
}

.pdg-btm23 {
  padding-bottom: 23px;
}

.pdg-lft23 {
  padding-left: 23px;
}

.pdg-24 {
  padding: 24px;
}

.pdg-top24 {
  padding-top: 24px;
}

.pdg-rgt24 {
  padding-right: 24px;
}

.pdg-btm24 {
  padding-bottom: 24px;
}

.pdg-lft24 {
  padding-left: 24px;
}

.pdg-25 {
  padding: 25px;
}

.pdg-top25 {
  padding-top: 25px;
}

.pdg-rgt25 {
  padding-right: 25px;
}

.pdg-btm25 {
  padding-bottom: 25px;
}

.pdg-lft25 {
  padding-left: 25px;
}

.pdg-26 {
  padding: 26px;
}

.pdg-top26 {
  padding-top: 26px;
}

.pdg-rgt26 {
  padding-right: 26px;
}

.pdg-btm26 {
  padding-bottom: 26px;
}

.pdg-lft26 {
  padding-left: 26px;
}

.pdg-27 {
  padding: 27px;
}

.pdg-top27 {
  padding-top: 27px;
}

.pdg-rgt27 {
  padding-right: 27px;
}

.pdg-btm27 {
  padding-bottom: 27px;
}

.pdg-lft27 {
  padding-left: 27px;
}

.pdg-28 {
  padding: 28px;
}

.pdg-top28 {
  padding-top: 28px;
}

.pdg-rgt28 {
  padding-right: 28px;
}

.pdg-btm28 {
  padding-bottom: 28px;
}

.pdg-lft28 {
  padding-left: 28px;
}

.pdg-29 {
  padding: 29px;
}

.pdg-top29 {
  padding-top: 29px;
}

.pdg-rgt29 {
  padding-right: 29px;
}

.pdg-btm29 {
  padding-bottom: 29px;
}

.pdg-lft29 {
  padding-left: 29px;
}

.pdg-30 {
  padding: 30px;
}

.pdg-top30 {
  padding-top: 30px;
}

.pdg-rgt30 {
  padding-right: 30px;
}

.pdg-btm30 {
  padding-bottom: 30px;
}

.pdg-lft30 {
  padding-left: 30px;
}

.pdg-31 {
  padding: 31px;
}

.pdg-top31 {
  padding-top: 31px;
}

.pdg-rgt31 {
  padding-right: 31px;
}

.pdg-btm31 {
  padding-bottom: 31px;
}

.pdg-lft31 {
  padding-left: 31px;
}

.pdg-32 {
  padding: 32px;
}

.pdg-top32 {
  padding-top: 32px;
}

.pdg-rgt32 {
  padding-right: 32px;
}

.pdg-btm32 {
  padding-bottom: 32px;
}

.pdg-lft32 {
  padding-left: 32px;
}

.pdg-33 {
  padding: 33px;
}

.pdg-top33 {
  padding-top: 33px;
}

.pdg-rgt33 {
  padding-right: 33px;
}

.pdg-btm33 {
  padding-bottom: 33px;
}

.pdg-lft33 {
  padding-left: 33px;
}

.pdg-34 {
  padding: 34px;
}

.pdg-top34 {
  padding-top: 34px;
}

.pdg-rgt34 {
  padding-right: 34px;
}

.pdg-btm34 {
  padding-bottom: 34px;
}

.pdg-lft34 {
  padding-left: 34px;
}

.pdg-35 {
  padding: 35px;
}

.pdg-top35 {
  padding-top: 35px;
}

.pdg-rgt35 {
  padding-right: 35px;
}

.pdg-btm35 {
  padding-bottom: 35px;
}

.pdg-lft35 {
  padding-left: 35px;
}

.pdg-36 {
  padding: 36px;
}

.pdg-top36 {
  padding-top: 36px;
}

.pdg-rgt36 {
  padding-right: 36px;
}

.pdg-btm36 {
  padding-bottom: 36px;
}

.pdg-lft36 {
  padding-left: 36px;
}

.pdg-37 {
  padding: 37px;
}

.pdg-top37 {
  padding-top: 37px;
}

.pdg-rgt37 {
  padding-right: 37px;
}

.pdg-btm37 {
  padding-bottom: 37px;
}

.pdg-lft37 {
  padding-left: 37px;
}

.pdg-38 {
  padding: 38px;
}

.pdg-top38 {
  padding-top: 38px;
}

.pdg-rgt38 {
  padding-right: 38px;
}

.pdg-btm38 {
  padding-bottom: 38px;
}

.pdg-lft38 {
  padding-left: 38px;
}

.pdg-39 {
  padding: 39px;
}

.pdg-top39 {
  padding-top: 39px;
}

.pdg-rgt39 {
  padding-right: 39px;
}

.pdg-btm39 {
  padding-bottom: 39px;
}

.pdg-lft39 {
  padding-left: 39px;
}

.pdg-40 {
  padding: 40px;
}

.pdg-top40 {
  padding-top: 40px;
}

.pdg-rgt40 {
  padding-right: 40px;
}

.pdg-btm40 {
  padding-bottom: 40px;
}

.pdg-lft40 {
  padding-left: 40px;
}

.pdg-41 {
  padding: 41px;
}

.pdg-top41 {
  padding-top: 41px;
}

.pdg-rgt41 {
  padding-right: 41px;
}

.pdg-btm41 {
  padding-bottom: 41px;
}

.pdg-lft41 {
  padding-left: 41px;
}

.pdg-42 {
  padding: 42px;
}

.pdg-top42 {
  padding-top: 42px;
}

.pdg-rgt42 {
  padding-right: 42px;
}

.pdg-btm42 {
  padding-bottom: 42px;
}

.pdg-lft42 {
  padding-left: 42px;
}

.pdg-43 {
  padding: 43px;
}

.pdg-top43 {
  padding-top: 43px;
}

.pdg-rgt43 {
  padding-right: 43px;
}

.pdg-btm43 {
  padding-bottom: 43px;
}

.pdg-lft43 {
  padding-left: 43px;
}

.pdg-44 {
  padding: 44px;
}

.pdg-top44 {
  padding-top: 44px;
}

.pdg-rgt44 {
  padding-right: 44px;
}

.pdg-btm44 {
  padding-bottom: 44px;
}

.pdg-lft44 {
  padding-left: 44px;
}

.pdg-45 {
  padding: 45px;
}

.pdg-top45 {
  padding-top: 45px;
}

.pdg-rgt45 {
  padding-right: 45px;
}

.pdg-btm45 {
  padding-bottom: 45px;
}

.pdg-lft45 {
  padding-left: 45px;
}

.pdg-46 {
  padding: 46px;
}

.pdg-top46 {
  padding-top: 46px;
}

.pdg-rgt46 {
  padding-right: 46px;
}

.pdg-btm46 {
  padding-bottom: 46px;
}

.pdg-lft46 {
  padding-left: 46px;
}

.pdg-47 {
  padding: 47px;
}

.pdg-top47 {
  padding-top: 47px;
}

.pdg-rgt47 {
  padding-right: 47px;
}

.pdg-btm47 {
  padding-bottom: 47px;
}

.pdg-lft47 {
  padding-left: 47px;
}

.pdg-48 {
  padding: 48px;
}

.pdg-top48 {
  padding-top: 48px;
}

.pdg-rgt48 {
  padding-right: 48px;
}

.pdg-btm48 {
  padding-bottom: 48px;
}

.pdg-lft48 {
  padding-left: 48px;
}

.pdg-49 {
  padding: 49px;
}

.pdg-top49 {
  padding-top: 49px;
}

.pdg-rgt49 {
  padding-right: 49px;
}

.pdg-btm49 {
  padding-bottom: 49px;
}

.pdg-lft49 {
  padding-left: 49px;
}

.pdg-50 {
  padding: 50px;
}

.pdg-top50 {
  padding-top: 50px;
}

.pdg-rgt50 {
  padding-right: 50px;
}

.pdg-btm50 {
  padding-bottom: 50px;
}

.pdg-lft50 {
  padding-left: 50px;
}

.pdg-51 {
  padding: 51px;
}

.pdg-top51 {
  padding-top: 51px;
}

.pdg-rgt51 {
  padding-right: 51px;
}

.pdg-btm51 {
  padding-bottom: 51px;
}

.pdg-lft51 {
  padding-left: 51px;
}

.pdg-52 {
  padding: 52px;
}

.pdg-top52 {
  padding-top: 52px;
}

.pdg-rgt52 {
  padding-right: 52px;
}

.pdg-btm52 {
  padding-bottom: 52px;
}

.pdg-lft52 {
  padding-left: 52px;
}

.pdg-53 {
  padding: 53px;
}

.pdg-top53 {
  padding-top: 53px;
}

.pdg-rgt53 {
  padding-right: 53px;
}

.pdg-btm53 {
  padding-bottom: 53px;
}

.pdg-lft53 {
  padding-left: 53px;
}

.pdg-54 {
  padding: 54px;
}

.pdg-top54 {
  padding-top: 54px;
}

.pdg-rgt54 {
  padding-right: 54px;
}

.pdg-btm54 {
  padding-bottom: 54px;
}

.pdg-lft54 {
  padding-left: 54px;
}

.pdg-55 {
  padding: 55px;
}

.pdg-top55 {
  padding-top: 55px;
}

.pdg-rgt55 {
  padding-right: 55px;
}

.pdg-btm55 {
  padding-bottom: 55px;
}

.pdg-lft55 {
  padding-left: 55px;
}

.pdg-56 {
  padding: 56px;
}

.pdg-top56 {
  padding-top: 56px;
}

.pdg-rgt56 {
  padding-right: 56px;
}

.pdg-btm56 {
  padding-bottom: 56px;
}

.pdg-lft56 {
  padding-left: 56px;
}

.pdg-57 {
  padding: 57px;
}

.pdg-top57 {
  padding-top: 57px;
}

.pdg-rgt57 {
  padding-right: 57px;
}

.pdg-btm57 {
  padding-bottom: 57px;
}

.pdg-lft57 {
  padding-left: 57px;
}

.pdg-58 {
  padding: 58px;
}

.pdg-top58 {
  padding-top: 58px;
}

.pdg-rgt58 {
  padding-right: 58px;
}

.pdg-btm58 {
  padding-bottom: 58px;
}

.pdg-lft58 {
  padding-left: 58px;
}

.pdg-59 {
  padding: 59px;
}

.pdg-top59 {
  padding-top: 59px;
}

.pdg-rgt59 {
  padding-right: 59px;
}

.pdg-btm59 {
  padding-bottom: 59px;
}

.pdg-lft59 {
  padding-left: 59px;
}

.pdg-60 {
  padding: 60px;
}

.pdg-top60 {
  padding-top: 60px;
}

.pdg-rgt60 {
  padding-right: 60px;
}

.pdg-btm60 {
  padding-bottom: 60px;
}

.pdg-lft60 {
  padding-left: 60px;
}

.pdg-61 {
  padding: 61px;
}

.pdg-top61 {
  padding-top: 61px;
}

.pdg-rgt61 {
  padding-right: 61px;
}

.pdg-btm61 {
  padding-bottom: 61px;
}

.pdg-lft61 {
  padding-left: 61px;
}

.pdg-62 {
  padding: 62px;
}

.pdg-top62 {
  padding-top: 62px;
}

.pdg-rgt62 {
  padding-right: 62px;
}

.pdg-btm62 {
  padding-bottom: 62px;
}

.pdg-lft62 {
  padding-left: 62px;
}

.pdg-63 {
  padding: 63px;
}

.pdg-top63 {
  padding-top: 63px;
}

.pdg-rgt63 {
  padding-right: 63px;
}

.pdg-btm63 {
  padding-bottom: 63px;
}

.pdg-lft63 {
  padding-left: 63px;
}

.pdg-64 {
  padding: 64px;
}

.pdg-top64 {
  padding-top: 64px;
}

.pdg-rgt64 {
  padding-right: 64px;
}

.pdg-btm64 {
  padding-bottom: 64px;
}

.pdg-lft64 {
  padding-left: 64px;
}

.pdg-65 {
  padding: 65px;
}

.pdg-top65 {
  padding-top: 65px;
}

.pdg-rgt65 {
  padding-right: 65px;
}

.pdg-btm65 {
  padding-bottom: 65px;
}

.pdg-lft65 {
  padding-left: 65px;
}

.pdg-66 {
  padding: 66px;
}

.pdg-top66 {
  padding-top: 66px;
}

.pdg-rgt66 {
  padding-right: 66px;
}

.pdg-btm66 {
  padding-bottom: 66px;
}

.pdg-lft66 {
  padding-left: 66px;
}

.pdg-67 {
  padding: 67px;
}

.pdg-top67 {
  padding-top: 67px;
}

.pdg-rgt67 {
  padding-right: 67px;
}

.pdg-btm67 {
  padding-bottom: 67px;
}

.pdg-lft67 {
  padding-left: 67px;
}

.pdg-68 {
  padding: 68px;
}

.pdg-top68 {
  padding-top: 68px;
}

.pdg-rgt68 {
  padding-right: 68px;
}

.pdg-btm68 {
  padding-bottom: 68px;
}

.pdg-lft68 {
  padding-left: 68px;
}

.pdg-69 {
  padding: 69px;
}

.pdg-top69 {
  padding-top: 69px;
}

.pdg-rgt69 {
  padding-right: 69px;
}

.pdg-btm69 {
  padding-bottom: 69px;
}

.pdg-lft69 {
  padding-left: 69px;
}

.pdg-70 {
  padding: 70px;
}

.pdg-top70 {
  padding-top: 70px;
}

.pdg-rgt70 {
  padding-right: 70px;
}

.pdg-btm70 {
  padding-bottom: 70px;
}

.pdg-lft70 {
  padding-left: 70px;
}

.pdg-71 {
  padding: 71px;
}

.pdg-top71 {
  padding-top: 71px;
}

.pdg-rgt71 {
  padding-right: 71px;
}

.pdg-btm71 {
  padding-bottom: 71px;
}

.pdg-lft71 {
  padding-left: 71px;
}

.pdg-72 {
  padding: 72px;
}

.pdg-top72 {
  padding-top: 72px;
}

.pdg-rgt72 {
  padding-right: 72px;
}

.pdg-btm72 {
  padding-bottom: 72px;
}

.pdg-lft72 {
  padding-left: 72px;
}

.pdg-73 {
  padding: 73px;
}

.pdg-top73 {
  padding-top: 73px;
}

.pdg-rgt73 {
  padding-right: 73px;
}

.pdg-btm73 {
  padding-bottom: 73px;
}

.pdg-lft73 {
  padding-left: 73px;
}

.pdg-74 {
  padding: 74px;
}

.pdg-top74 {
  padding-top: 74px;
}

.pdg-rgt74 {
  padding-right: 74px;
}

.pdg-btm74 {
  padding-bottom: 74px;
}

.pdg-lft74 {
  padding-left: 74px;
}

.pdg-75 {
  padding: 75px;
}

.pdg-top75 {
  padding-top: 75px;
}

.pdg-rgt75 {
  padding-right: 75px;
}

.pdg-btm75 {
  padding-bottom: 75px;
}

.pdg-lft75 {
  padding-left: 75px;
}

.pdg-76 {
  padding: 76px;
}

.pdg-top76 {
  padding-top: 76px;
}

.pdg-rgt76 {
  padding-right: 76px;
}

.pdg-btm76 {
  padding-bottom: 76px;
}

.pdg-lft76 {
  padding-left: 76px;
}

.pdg-77 {
  padding: 77px;
}

.pdg-top77 {
  padding-top: 77px;
}

.pdg-rgt77 {
  padding-right: 77px;
}

.pdg-btm77 {
  padding-bottom: 77px;
}

.pdg-lft77 {
  padding-left: 77px;
}

.pdg-78 {
  padding: 78px;
}

.pdg-top78 {
  padding-top: 78px;
}

.pdg-rgt78 {
  padding-right: 78px;
}

.pdg-btm78 {
  padding-bottom: 78px;
}

.pdg-lft78 {
  padding-left: 78px;
}

.pdg-79 {
  padding: 79px;
}

.pdg-top79 {
  padding-top: 79px;
}

.pdg-rgt79 {
  padding-right: 79px;
}

.pdg-btm79 {
  padding-bottom: 79px;
}

.pdg-lft79 {
  padding-left: 79px;
}

.pdg-80 {
  padding: 80px;
}

.pdg-top80 {
  padding-top: 80px;
}

.pdg-rgt80 {
  padding-right: 80px;
}

.pdg-btm80 {
  padding-bottom: 80px;
}

.pdg-lft80 {
  padding-left: 80px;
}

.pdg-81 {
  padding: 81px;
}

.pdg-top81 {
  padding-top: 81px;
}

.pdg-rgt81 {
  padding-right: 81px;
}

.pdg-btm81 {
  padding-bottom: 81px;
}

.pdg-lft81 {
  padding-left: 81px;
}

.pdg-82 {
  padding: 82px;
}

.pdg-top82 {
  padding-top: 82px;
}

.pdg-rgt82 {
  padding-right: 82px;
}

.pdg-btm82 {
  padding-bottom: 82px;
}

.pdg-lft82 {
  padding-left: 82px;
}

.pdg-83 {
  padding: 83px;
}

.pdg-top83 {
  padding-top: 83px;
}

.pdg-rgt83 {
  padding-right: 83px;
}

.pdg-btm83 {
  padding-bottom: 83px;
}

.pdg-lft83 {
  padding-left: 83px;
}

.pdg-84 {
  padding: 84px;
}

.pdg-top84 {
  padding-top: 84px;
}

.pdg-rgt84 {
  padding-right: 84px;
}

.pdg-btm84 {
  padding-bottom: 84px;
}

.pdg-lft84 {
  padding-left: 84px;
}

.pdg-85 {
  padding: 85px;
}

.pdg-top85 {
  padding-top: 85px;
}

.pdg-rgt85 {
  padding-right: 85px;
}

.pdg-btm85 {
  padding-bottom: 85px;
}

.pdg-lft85 {
  padding-left: 85px;
}

.pdg-86 {
  padding: 86px;
}

.pdg-top86 {
  padding-top: 86px;
}

.pdg-rgt86 {
  padding-right: 86px;
}

.pdg-btm86 {
  padding-bottom: 86px;
}

.pdg-lft86 {
  padding-left: 86px;
}

.pdg-87 {
  padding: 87px;
}

.pdg-top87 {
  padding-top: 87px;
}

.pdg-rgt87 {
  padding-right: 87px;
}

.pdg-btm87 {
  padding-bottom: 87px;
}

.pdg-lft87 {
  padding-left: 87px;
}

.pdg-88 {
  padding: 88px;
}

.pdg-top88 {
  padding-top: 88px;
}

.pdg-rgt88 {
  padding-right: 88px;
}

.pdg-btm88 {
  padding-bottom: 88px;
}

.pdg-lft88 {
  padding-left: 88px;
}

.pdg-89 {
  padding: 89px;
}

.pdg-top89 {
  padding-top: 89px;
}

.pdg-rgt89 {
  padding-right: 89px;
}

.pdg-btm89 {
  padding-bottom: 89px;
}

.pdg-lft89 {
  padding-left: 89px;
}

.pdg-90 {
  padding: 90px;
}

.pdg-top90 {
  padding-top: 90px;
}

.pdg-rgt90 {
  padding-right: 90px;
}

.pdg-btm90 {
  padding-bottom: 90px;
}

.pdg-lft90 {
  padding-left: 90px;
}

.pdg-91 {
  padding: 91px;
}

.pdg-top91 {
  padding-top: 91px;
}

.pdg-rgt91 {
  padding-right: 91px;
}

.pdg-btm91 {
  padding-bottom: 91px;
}

.pdg-lft91 {
  padding-left: 91px;
}

.pdg-92 {
  padding: 92px;
}

.pdg-top92 {
  padding-top: 92px;
}

.pdg-rgt92 {
  padding-right: 92px;
}

.pdg-btm92 {
  padding-bottom: 92px;
}

.pdg-lft92 {
  padding-left: 92px;
}

.pdg-93 {
  padding: 93px;
}

.pdg-top93 {
  padding-top: 93px;
}

.pdg-rgt93 {
  padding-right: 93px;
}

.pdg-btm93 {
  padding-bottom: 93px;
}

.pdg-lft93 {
  padding-left: 93px;
}

.pdg-94 {
  padding: 94px;
}

.pdg-top94 {
  padding-top: 94px;
}

.pdg-rgt94 {
  padding-right: 94px;
}

.pdg-btm94 {
  padding-bottom: 94px;
}

.pdg-lft94 {
  padding-left: 94px;
}

.pdg-95 {
  padding: 95px;
}

.pdg-top95 {
  padding-top: 95px;
}

.pdg-rgt95 {
  padding-right: 95px;
}

.pdg-btm95 {
  padding-bottom: 95px;
}

.pdg-lft95 {
  padding-left: 95px;
}

.pdg-96 {
  padding: 96px;
}

.pdg-top96 {
  padding-top: 96px;
}

.pdg-rgt96 {
  padding-right: 96px;
}

.pdg-btm96 {
  padding-bottom: 96px;
}

.pdg-lft96 {
  padding-left: 96px;
}

.pdg-97 {
  padding: 97px;
}

.pdg-top97 {
  padding-top: 97px;
}

.pdg-rgt97 {
  padding-right: 97px;
}

.pdg-btm97 {
  padding-bottom: 97px;
}

.pdg-lft97 {
  padding-left: 97px;
}

.pdg-98 {
  padding: 98px;
}

.pdg-top98 {
  padding-top: 98px;
}

.pdg-rgt98 {
  padding-right: 98px;
}

.pdg-btm98 {
  padding-bottom: 98px;
}

.pdg-lft98 {
  padding-left: 98px;
}

.pdg-99 {
  padding: 99px;
}

.pdg-top99 {
  padding-top: 99px;
}

.pdg-rgt99 {
  padding-right: 99px;
}

.pdg-btm99 {
  padding-bottom: 99px;
}

.pdg-lft99 {
  padding-left: 99px;
}

.pdg-100 {
  padding: 100px;
}

.pdg-top100 {
  padding-top: 100px;
}

.pdg-rgt100 {
  padding-right: 100px;
}

.pdg-btm100 {
  padding-bottom: 100px;
}

.pdg-lft100 {
  padding-left: 100px;
}

.pdg-101 {
  padding: 101px;
}

.pdg-top101 {
  padding-top: 101px;
}

.pdg-rgt101 {
  padding-right: 101px;
}

.pdg-btm101 {
  padding-bottom: 101px;
}

.pdg-lft101 {
  padding-left: 101px;
}

.pdg-102 {
  padding: 102px;
}

.pdg-top102 {
  padding-top: 102px;
}

.pdg-rgt102 {
  padding-right: 102px;
}

.pdg-btm102 {
  padding-bottom: 102px;
}

.pdg-lft102 {
  padding-left: 102px;
}

.pdg-103 {
  padding: 103px;
}

.pdg-top103 {
  padding-top: 103px;
}

.pdg-rgt103 {
  padding-right: 103px;
}

.pdg-btm103 {
  padding-bottom: 103px;
}

.pdg-lft103 {
  padding-left: 103px;
}

.pdg-104 {
  padding: 104px;
}

.pdg-top104 {
  padding-top: 104px;
}

.pdg-rgt104 {
  padding-right: 104px;
}

.pdg-btm104 {
  padding-bottom: 104px;
}

.pdg-lft104 {
  padding-left: 104px;
}

.pdg-105 {
  padding: 105px;
}

.pdg-top105 {
  padding-top: 105px;
}

.pdg-rgt105 {
  padding-right: 105px;
}

.pdg-btm105 {
  padding-bottom: 105px;
}

.pdg-lft105 {
  padding-left: 105px;
}

.pdg-106 {
  padding: 106px;
}

.pdg-top106 {
  padding-top: 106px;
}

.pdg-rgt106 {
  padding-right: 106px;
}

.pdg-btm106 {
  padding-bottom: 106px;
}

.pdg-lft106 {
  padding-left: 106px;
}

.pdg-107 {
  padding: 107px;
}

.pdg-top107 {
  padding-top: 107px;
}

.pdg-rgt107 {
  padding-right: 107px;
}

.pdg-btm107 {
  padding-bottom: 107px;
}

.pdg-lft107 {
  padding-left: 107px;
}

.pdg-108 {
  padding: 108px;
}

.pdg-top108 {
  padding-top: 108px;
}

.pdg-rgt108 {
  padding-right: 108px;
}

.pdg-btm108 {
  padding-bottom: 108px;
}

.pdg-lft108 {
  padding-left: 108px;
}

.pdg-109 {
  padding: 109px;
}

.pdg-top109 {
  padding-top: 109px;
}

.pdg-rgt109 {
  padding-right: 109px;
}

.pdg-btm109 {
  padding-bottom: 109px;
}

.pdg-lft109 {
  padding-left: 109px;
}

.pdg-110 {
  padding: 110px;
}

.pdg-top110 {
  padding-top: 110px;
}

.pdg-rgt110 {
  padding-right: 110px;
}

.pdg-btm110 {
  padding-bottom: 110px;
}

.pdg-lft110 {
  padding-left: 110px;
}

.pdg-111 {
  padding: 111px;
}

.pdg-top111 {
  padding-top: 111px;
}

.pdg-rgt111 {
  padding-right: 111px;
}

.pdg-btm111 {
  padding-bottom: 111px;
}

.pdg-lft111 {
  padding-left: 111px;
}

.pdg-112 {
  padding: 112px;
}

.pdg-top112 {
  padding-top: 112px;
}

.pdg-rgt112 {
  padding-right: 112px;
}

.pdg-btm112 {
  padding-bottom: 112px;
}

.pdg-lft112 {
  padding-left: 112px;
}

.pdg-113 {
  padding: 113px;
}

.pdg-top113 {
  padding-top: 113px;
}

.pdg-rgt113 {
  padding-right: 113px;
}

.pdg-btm113 {
  padding-bottom: 113px;
}

.pdg-lft113 {
  padding-left: 113px;
}

.pdg-114 {
  padding: 114px;
}

.pdg-top114 {
  padding-top: 114px;
}

.pdg-rgt114 {
  padding-right: 114px;
}

.pdg-btm114 {
  padding-bottom: 114px;
}

.pdg-lft114 {
  padding-left: 114px;
}

.pdg-115 {
  padding: 115px;
}

.pdg-top115 {
  padding-top: 115px;
}

.pdg-rgt115 {
  padding-right: 115px;
}

.pdg-btm115 {
  padding-bottom: 115px;
}

.pdg-lft115 {
  padding-left: 115px;
}

.pdg-116 {
  padding: 116px;
}

.pdg-top116 {
  padding-top: 116px;
}

.pdg-rgt116 {
  padding-right: 116px;
}

.pdg-btm116 {
  padding-bottom: 116px;
}

.pdg-lft116 {
  padding-left: 116px;
}

.pdg-117 {
  padding: 117px;
}

.pdg-top117 {
  padding-top: 117px;
}

.pdg-rgt117 {
  padding-right: 117px;
}

.pdg-btm117 {
  padding-bottom: 117px;
}

.pdg-lft117 {
  padding-left: 117px;
}

.pdg-118 {
  padding: 118px;
}

.pdg-top118 {
  padding-top: 118px;
}

.pdg-rgt118 {
  padding-right: 118px;
}

.pdg-btm118 {
  padding-bottom: 118px;
}

.pdg-lft118 {
  padding-left: 118px;
}

.pdg-119 {
  padding: 119px;
}

.pdg-top119 {
  padding-top: 119px;
}

.pdg-rgt119 {
  padding-right: 119px;
}

.pdg-btm119 {
  padding-bottom: 119px;
}

.pdg-lft119 {
  padding-left: 119px;
}

.pdg-120 {
  padding: 120px;
}

.pdg-top120 {
  padding-top: 120px;
}

.pdg-rgt120 {
  padding-right: 120px;
}

.pdg-btm120 {
  padding-bottom: 120px;
}

.pdg-lft120 {
  padding-left: 120px;
}

.pdg-121 {
  padding: 121px;
}

.pdg-top121 {
  padding-top: 121px;
}

.pdg-rgt121 {
  padding-right: 121px;
}

.pdg-btm121 {
  padding-bottom: 121px;
}

.pdg-lft121 {
  padding-left: 121px;
}

.pdg-122 {
  padding: 122px;
}

.pdg-top122 {
  padding-top: 122px;
}

.pdg-rgt122 {
  padding-right: 122px;
}

.pdg-btm122 {
  padding-bottom: 122px;
}

.pdg-lft122 {
  padding-left: 122px;
}

.pdg-123 {
  padding: 123px;
}

.pdg-top123 {
  padding-top: 123px;
}

.pdg-rgt123 {
  padding-right: 123px;
}

.pdg-btm123 {
  padding-bottom: 123px;
}

.pdg-lft123 {
  padding-left: 123px;
}

.pdg-124 {
  padding: 124px;
}

.pdg-top124 {
  padding-top: 124px;
}

.pdg-rgt124 {
  padding-right: 124px;
}

.pdg-btm124 {
  padding-bottom: 124px;
}

.pdg-lft124 {
  padding-left: 124px;
}

.pdg-125 {
  padding: 125px;
}

.pdg-top125 {
  padding-top: 125px;
}

.pdg-rgt125 {
  padding-right: 125px;
}

.pdg-btm125 {
  padding-bottom: 125px;
}

.pdg-lft125 {
  padding-left: 125px;
}

.pdg-126 {
  padding: 126px;
}

.pdg-top126 {
  padding-top: 126px;
}

.pdg-rgt126 {
  padding-right: 126px;
}

.pdg-btm126 {
  padding-bottom: 126px;
}

.pdg-lft126 {
  padding-left: 126px;
}

.pdg-127 {
  padding: 127px;
}

.pdg-top127 {
  padding-top: 127px;
}

.pdg-rgt127 {
  padding-right: 127px;
}

.pdg-btm127 {
  padding-bottom: 127px;
}

.pdg-lft127 {
  padding-left: 127px;
}

.pdg-128 {
  padding: 128px;
}

.pdg-top128 {
  padding-top: 128px;
}

.pdg-rgt128 {
  padding-right: 128px;
}

.pdg-btm128 {
  padding-bottom: 128px;
}

.pdg-lft128 {
  padding-left: 128px;
}

.pdg-129 {
  padding: 129px;
}

.pdg-top129 {
  padding-top: 129px;
}

.pdg-rgt129 {
  padding-right: 129px;
}

.pdg-btm129 {
  padding-bottom: 129px;
}

.pdg-lft129 {
  padding-left: 129px;
}

.pdg-130 {
  padding: 130px;
}

.pdg-top130 {
  padding-top: 130px;
}

.pdg-rgt130 {
  padding-right: 130px;
}

.pdg-btm130 {
  padding-bottom: 130px;
}

.pdg-lft130 {
  padding-left: 130px;
}

.pdg-131 {
  padding: 131px;
}

.pdg-top131 {
  padding-top: 131px;
}

.pdg-rgt131 {
  padding-right: 131px;
}

.pdg-btm131 {
  padding-bottom: 131px;
}

.pdg-lft131 {
  padding-left: 131px;
}

.pdg-132 {
  padding: 132px;
}

.pdg-top132 {
  padding-top: 132px;
}

.pdg-rgt132 {
  padding-right: 132px;
}

.pdg-btm132 {
  padding-bottom: 132px;
}

.pdg-lft132 {
  padding-left: 132px;
}

.pdg-133 {
  padding: 133px;
}

.pdg-top133 {
  padding-top: 133px;
}

.pdg-rgt133 {
  padding-right: 133px;
}

.pdg-btm133 {
  padding-bottom: 133px;
}

.pdg-lft133 {
  padding-left: 133px;
}

.pdg-134 {
  padding: 134px;
}

.pdg-top134 {
  padding-top: 134px;
}

.pdg-rgt134 {
  padding-right: 134px;
}

.pdg-btm134 {
  padding-bottom: 134px;
}

.pdg-lft134 {
  padding-left: 134px;
}

.pdg-135 {
  padding: 135px;
}

.pdg-top135 {
  padding-top: 135px;
}

.pdg-rgt135 {
  padding-right: 135px;
}

.pdg-btm135 {
  padding-bottom: 135px;
}

.pdg-lft135 {
  padding-left: 135px;
}

.pdg-136 {
  padding: 136px;
}

.pdg-top136 {
  padding-top: 136px;
}

.pdg-rgt136 {
  padding-right: 136px;
}

.pdg-btm136 {
  padding-bottom: 136px;
}

.pdg-lft136 {
  padding-left: 136px;
}

.pdg-137 {
  padding: 137px;
}

.pdg-top137 {
  padding-top: 137px;
}

.pdg-rgt137 {
  padding-right: 137px;
}

.pdg-btm137 {
  padding-bottom: 137px;
}

.pdg-lft137 {
  padding-left: 137px;
}

.pdg-138 {
  padding: 138px;
}

.pdg-top138 {
  padding-top: 138px;
}

.pdg-rgt138 {
  padding-right: 138px;
}

.pdg-btm138 {
  padding-bottom: 138px;
}

.pdg-lft138 {
  padding-left: 138px;
}

.pdg-139 {
  padding: 139px;
}

.pdg-top139 {
  padding-top: 139px;
}

.pdg-rgt139 {
  padding-right: 139px;
}

.pdg-btm139 {
  padding-bottom: 139px;
}

.pdg-lft139 {
  padding-left: 139px;
}

.pdg-140 {
  padding: 140px;
}

.pdg-top140 {
  padding-top: 140px;
}

.pdg-rgt140 {
  padding-right: 140px;
}

.pdg-btm140 {
  padding-bottom: 140px;
}

.pdg-lft140 {
  padding-left: 140px;
}

.pdg-141 {
  padding: 141px;
}

.pdg-top141 {
  padding-top: 141px;
}

.pdg-rgt141 {
  padding-right: 141px;
}

.pdg-btm141 {
  padding-bottom: 141px;
}

.pdg-lft141 {
  padding-left: 141px;
}

.pdg-142 {
  padding: 142px;
}

.pdg-top142 {
  padding-top: 142px;
}

.pdg-rgt142 {
  padding-right: 142px;
}

.pdg-btm142 {
  padding-bottom: 142px;
}

.pdg-lft142 {
  padding-left: 142px;
}

.pdg-143 {
  padding: 143px;
}

.pdg-top143 {
  padding-top: 143px;
}

.pdg-rgt143 {
  padding-right: 143px;
}

.pdg-btm143 {
  padding-bottom: 143px;
}

.pdg-lft143 {
  padding-left: 143px;
}

.pdg-144 {
  padding: 144px;
}

.pdg-top144 {
  padding-top: 144px;
}

.pdg-rgt144 {
  padding-right: 144px;
}

.pdg-btm144 {
  padding-bottom: 144px;
}

.pdg-lft144 {
  padding-left: 144px;
}

.pdg-145 {
  padding: 145px;
}

.pdg-top145 {
  padding-top: 145px;
}

.pdg-rgt145 {
  padding-right: 145px;
}

.pdg-btm145 {
  padding-bottom: 145px;
}

.pdg-lft145 {
  padding-left: 145px;
}

.pdg-146 {
  padding: 146px;
}

.pdg-top146 {
  padding-top: 146px;
}

.pdg-rgt146 {
  padding-right: 146px;
}

.pdg-btm146 {
  padding-bottom: 146px;
}

.pdg-lft146 {
  padding-left: 146px;
}

.pdg-147 {
  padding: 147px;
}

.pdg-top147 {
  padding-top: 147px;
}

.pdg-rgt147 {
  padding-right: 147px;
}

.pdg-btm147 {
  padding-bottom: 147px;
}

.pdg-lft147 {
  padding-left: 147px;
}

.pdg-148 {
  padding: 148px;
}

.pdg-top148 {
  padding-top: 148px;
}

.pdg-rgt148 {
  padding-right: 148px;
}

.pdg-btm148 {
  padding-bottom: 148px;
}

.pdg-lft148 {
  padding-left: 148px;
}

.pdg-149 {
  padding: 149px;
}

.pdg-top149 {
  padding-top: 149px;
}

.pdg-rgt149 {
  padding-right: 149px;
}

.pdg-btm149 {
  padding-bottom: 149px;
}

.pdg-lft149 {
  padding-left: 149px;
}

.pdg-150 {
  padding: 150px;
}

.pdg-top150 {
  padding-top: 150px;
}

.pdg-rgt150 {
  padding-right: 150px;
}

.pdg-btm150 {
  padding-bottom: 150px;
}

.pdg-lft150 {
  padding-left: 150px;
}

/* --- position --- */
.pos_rel {
  position: relative;
}

.pos_ab {
  position: absolute;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

/* breadcrumbs
**************************************** */
.breadcrumbs {
  text-align: left;
  width: 100%;
  color: var(--clr-main-sub, #1d7fb7);
  padding-block: 1.25rem;
}
@media screen and (max-width: 47.9375em) {
  .breadcrumbs {
    overflow: scroll;
    white-space: nowrap;
    font-size: 0.8571428571rem;
  }
}

.breadcrumbs a {
  text-decoration: none;
  color: var(--clr-main-sub, #1d7fb7);
}

.breadcrumbs .icon-arrow {
  margin-inline: 4px;
}

.breadcrumbs a:hover {
  text-decoration: none;
}

.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.single-article .container .breadcrumbs,
.single-news .container .breadcrumbs,
.single-story .container .breadcrumbs {
  border-block: 1px solid #eae6eb;
}

.page_ttl .breadcrumbs {
  position: absolute;
  left: 0;
  bottom: 0;
}

.page_ttl-none .breadcrumbs {
  position: relative;
  text-align: center;
}

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}

.btn a {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  width: min(100%, 430px);
  padding: 1.1em 2.5rem;
  color: var(--btn-clr-main, #fff);
  font-weight: bold;
  font-size: 1.25rem;
  font-family: var(--font-jp);
  text-align: center;
  line-height: 1.4;
  gap: 1rem;
  letter-spacing: 0.17em;
  background-color: #b21141;
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
  border-radius: 100px;
  transition: var(--transit-default);
  text-decoration: none;
}
@media screen and (max-width: 47.9375em) {
  .btn a {
    min-width: 100%;
    font-size: 1.1428571429rem;
  }
}
.btn a::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  background-position: right;
  background-size: 150%;
  border-radius: 100px;
  opacity: 0.5;
  transition: var(--transit-default);
}

.btn a i {
  display: none;
}

.btn a:hover {
  opacity: 0.5;
}

.flex > .btn a {
  min-width: 100%;
}

.btn-sm a {
  width: min(100%, 350px);
  padding: 1rem 3.375rem;
  font-size: 1.125rem;
}

.btn-lg a {
  padding: 1.5em 3em;
}
@media screen and (max-width: 47.9375em) {
  .btn-lg a {
    padding: 1em 2em;
  }
}

.btn.txt-ctr a {
  margin-left: auto;
  margin-right: auto;
}

.btn svg {
  position: absolute;
  right: 1.875rem;
  transition: var(--transit-default, all 0.4s ease-out);
  fill: var(--btn-clr-main, #fff);
}

.icon-new_tab {
  width: 0.875rem;
  height: 0.875rem;
}

.icon-pdf {
  width: 1.6875rem;
  height: 0.75rem;
}

.btn a:where(:not([target=_blank]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::after {
  content: "";
  position: absolute;
  right: 1.875rem;
  display: block;
  width: 9px;
  height: 19px;
  background: url(/wp/wp-content/themes/standard_sass/images/common/right-arrow.svg) no-repeat;
  background-size: cover;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .btn a:where(:not([target=_blank]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::after {
    width: 6px;
    height: auto;
    aspect-ratio: 6/12;
  }
}

.btn.btn-next_page a {
  min-width: 100%;
  border-radius: 0;
  text-align: left;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
  background-color: var(--btn-bg-wht, #fff);
  background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  box-shadow: none;
}
.btn-wh a::before {
  opacity: 1;
}

.btn-wh a i {
  display: block;
  position: absolute;
  inset: 2px;
  z-index: -1;
  background: var(--bg-wht);
  border-radius: 100px;
  opacity: 1;
}
.btn-wh a i::before {
  display: none;
}

.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}

@media screen and (max-width: 47.9375em) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}
/* --- btn-line --- */
.btn-line a {
  background-color: #00B900;
  max-width: 100%;
  width: fit-content;
}
.btn-line a::before {
  display: none;
}

/* --- btn-magazine --- */
.btn-magazine a {
  max-width: 100%;
  width: fit-content;
  border: 1px solid #b21141;
  background-color: #fff;
  color: #b21141;
  font-size: 1rem;
  line-height: 1.7;
}
.btn-magazine a::before {
  background-image: none;
}

.btn-ctr {
  text-align: center;
}

.txt-link {
  display: inline-block;
}

/* ##############################################################################

  FORMY

############################################################################## */
#formy_form {
  font-family: var(--font-yu-cothic) !important;
}

#formy_form table {
  width: 100%;
}

#formy_form th,
#formy_form td {
  font-family: var(--font-yu-cothic) !important;
  padding: 1rem;
  vertical-align: middle;
  border: none;
  border-bottom: solid 1px #eee;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
#formy_form td {
    display: block;
    width: auto !important;
    padding-left: 0;
    padding-right: 0;
  }
}

#formy_form th {
  font-weight: 500;
  white-space: nowrap;
  text-align: left;
}
@media print, screen and (min-width: 48em) {
  #formy_form th {
    width: 34%;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    width: 100%;
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 0.1875rem;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 0.3125rem 0.9375rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  #formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
    font-size: 16px;
  }
}

#formy_form textarea {
  height: 6.25rem;
}

#formy_form select {
  height: 2.5rem;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: var(--bg-wht, #fff);
}

.help_text {
  font-size: 0.875rem;
  color: #999;
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 8.75rem;
  border: solid 1px #ccc;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.requiredIcon {
  background-color: var(--bg-red);
  color: var(--clr-wht, #fff);
  margin: 0 0 0 1em;
  font-size: 0.75rem;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
}

#formy_btn input {
  font-weight: bold;
  border-radius: 100px;
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: var(--clr-wht, #fff);
  padding: 1rem 2rem;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-yu-cothic) !important;
  transition: var(--transit-default, all 0.4s ease-out);
}
#formy_btn input:not(:first-child) {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}

#formy_form ul li input[type=radio],
#formy_form ul li input[type=checkbox] {
  display: none !important;
}

#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}

#formy_form ul li label:hover {
  opacity: 0.7;
}

#formy_form ul li label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 2px solid #788b93;
  left: 1rem;
  top: 0.75rem;
}

#formy_form ul li input[type=radio] + label::before {
  border-radius: 0.625rem;
}

#formy_form ul li input[type=radio]:checked + label,
#formy_form ul li input[type=checkbox]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}

#formy_form ul li input[type=radio]:checked + label::before,
#formy_form ul li input[type=checkbox]:checked + label::before {
  border-color: #e75f5f;
}

#formy_form ul li input[type=radio]:checked + label::after,
#formy_form ul li input[type=checkbox]:checked + label::after {
  content: "";
  width: 0.625rem;
  height: 1.125rem;
  top: 0.25rem;
  left: 1.25rem;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}

.formy_confirm {
  background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
}

.formy_submit_disabled {
  background: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 0.5rem 1.6875rem;
  min-width: auto;
  font-size: 0.9375rem;
  border: 1px solid #aaa;
  border-radius: 2rem;
}

.formy_send {
  background-color: var(--clr-main);
}

#total_required {
  padding: 1rem;
  color: #f55555;
  text-align: center;
}

@media print, screen and (min-width: 48em) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }

  .autoConfirmBack {
    margin-bottom: 0.5714285714rem;
  }
}
/* --- section_pdg --- */
.section_pdg {
  padding-block: 80px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-block: 40px;
  }
}

.section_pdg-top {
  padding-top: 80px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-top {
    padding-top: 40px;
  }
}

.section_pdg-btm {
  padding-bottom: 80px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-btm {
    padding-bottom: 40px;
  }
}

.section_pdg-sm {
  padding-block: 40px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-block: 24px;
  }
}

.lps_sec.section_pdg {
  padding-bottom: 7.8125vw;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec.section_pdg {
    padding-bottom: 24.154589372vw;
  }
}

.page-contact .lps_sec.section_pdg {
  padding-bottom: 4.1666666667vw;
}
@media screen and (max-width: 47.9375em) {
  .page-contact .lps_sec.section_pdg {
    padding-bottom: 9.6618357488vw;
  }
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 86%;
  }
}

.inner-sm {
  max-width: 960px;
}

.inner-xs {
  max-width: 840px;
}

.inner-lg {
  max-width: 1440px;
}

.inner-xl {
  max-width: 1600px;
}

@media screen and (max-width: 47.9375em) {
  .inner :not(.breadcrumbs) .inner {
    width: 100%;
  }
}
/* --- anchor --- */
.anchor {
  position: absolute;
  top: -80px;
  /* 固定ヘッダーの高さ */
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -64px;
    /* 固定ヘッダーの高さ */
  }
}

/* contact
********************************************** */
/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col2,
.flex-col3,
.flex-col4,
.flex-col5 {
  flex-wrap: wrap;
}

.flex-col1 > * {
  width: 100%;
}

.flex-col2 > * {
  width: 50%;
}

.flex-col3 > * {
  width: 33.3333333333%;
}

.flex-col4 > * {
  width: 25%;
}

.flex-col5 > * {
  width: 20%;
}

.flex-col2.gap {
  gap: 4%;
}

.flex-col3.gap {
  gap: 3%;
}

.flex-col4.gap {
  gap: 2%;
}

.flex-col5.gap {
  gap: 1%;
}

.flex-col2.gap > * {
  width: 48%;
  margin-bottom: 4%;
}

.flex-col3.gap > * {
  width: 31.33%;
  margin-bottom: 3%;
}

.flex-col4.gap > * {
  width: 23.5%;
  margin-bottom: 2%;
}

.flex-col5.gap > * {
  width: 19.2%;
  margin-bottom: 1%;
}

@media screen and (min-width: 48em) {
  .flex-col2.gap-wide {
    gap: 6%;
  }

  .flex-col3.gap-wide {
    gap: 5%;
  }

  .flex-col4.gap-wide {
    gap: 4%;
  }

  .flex-col5.gap-wide {
    gap: 3%;
  }

  .flex-col2.gap-wide > * {
    width: 47%;
    margin-bottom: 6%;
  }

  .flex-col3.gap-wide > * {
    width: 30%;
    margin-bottom: 5%;
  }

  .flex-col4.gap-wide > * {
    width: 22%;
    margin-bottom: 4%;
  }

  .flex-col5.gap-wide > * {
    width: 17.6%;
    margin-bottom: 3%;
  }

  .flex-col2.column-gap > *,
.flex-col3.column-gap > *,
.flex-col4.column-gap > *,
.flex-col5.column-gap > * {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .flex-col2.flex-sp-block > *,
.flex-col3.flex-sp-block > *,
.flex-col4.flex-sp-block > *,
.flex-col5.flex-sp-block > * {
    width: 100%;
  }

  .flex-col2.gap.flex-sp-block > *,
.flex-col3.gap.flex-sp-block > *,
.flex-col4.gap.flex-sp-block > *,
.flex-col5.gap.flex-sp-block > * {
    margin-bottom: 24px;
  }

  .flex-sp-col3 > * {
    width: 33.3333333333%;
  }

  .flex-sp-col2 > * {
    width: 50%;
  }

  .flex-col4.gap.flex-sp-col3 > * {
    width: 32%;
  }

  .flex-col4.gap.flex-sp-col2 > * {
    width: 49%;
  }

  .flex-col5.gap.flex-sp-col3 > * {
    width: 32%;
  }

  .flex-col5.gap.flex-sp-col2 > * {
    width: 49%;
  }

  .flex-col3.gap.flex-sp-col2 > * {
    width: 48.5%;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
.footer {
  background-color: var(--bg-blk);
}

.footer .gnav {
  opacity: 1;
  position: relative;
  z-index: 0;
  overflow: initial;
  pointer-events: auto;
  padding-top: 3.3333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .footer .gnav {
    padding-top: 7.729468599vw;
  }
}

.page--top {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  z-index: 1000;
  padding: 1rem 1.5rem;
  color: var(--clr-list);
  font-size: 1.125rem;
  font-weight: bold;
  font-family: var(--font-en);
  line-height: 1;
  letter-spacing: 0.02em;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
  opacity: 0;
  translate: 0 1rem;
  pointer-events: none;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-font-feature-settings: "pkna";
  font-feature-settings: "pkna";
}
@media screen and (max-width: 47.9375em) {
  .page--top {
    bottom: 7.1428571429rem;
    right: 0.7142857143rem;
    padding: 0.7142857143rem;
  }
}
.page--top::before {
  content: "";
  width: 0.625rem;
  height: 3.375rem;
  margin-bottom: 0.5rem;
  margin-left: 0.625rem;
  border-top: 1px solid;
  border-left: 1px solid;
  transform: skewY(50deg);
  transform-origin: bottom;
  transition: var(--transit-default);
}
.page--top.show {
  opacity: 1;
  translate: 0 0;
  pointer-events: auto;
}
.page--top:hover::before {
  scale: 1 1.2;
}

/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 111px;
  z-index: 9999;
  mix-blend-mode: exclusion;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .header {
    height: 64px;
  }
}
.header > .inner {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-left: 24px;
}
@media screen and (max-width: 67.5em) {
  .header > .inner {
    padding-left: 16px;
  }
}
@media screen and (max-width: 47.9375em) {
  .header > .inner {
    align-items: center;
  }
}

.header-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9990;
  width: 100%;
  height: 111px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 32px rgba(103, 15, 39, 0.1);
  transition: var(--transit-default);
  opacity: 0;
}
@media screen and (max-width: 47.9375em) {
  .header-bg {
    height: 64px;
  }
}

.header.active {
  mix-blend-mode: normal;
}

.header--logo {
  z-index: 9999;
  transition: var(--transit-default);
  font-weight: normal;
}
@media screen and (min-width: 48em) {
  .header--logo {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
  }
}

.header--logo svg {
  display: block;
  width: 235px;
  aspect-ratio: 234.3/18.5;
  fill: var(--bg-wht);
}
@media screen and (max-width: 47.9375em) {
  .header--logo svg {
    width: auto;
    height: 1rem;
  }
}

.header--logo span {
  display: block;
  margin-top: 1em;
  font-size: 0.8125rem;
  letter-spacing: 0.13em;
  line-height: var(--line-height-none);
}
@media screen and (min-width: 48em) {
  .header--logo span {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  .header--logo span {
    margin-top: 0.7em;
    font-size: 0.7857142857rem;
  }
}

.header--logo a {
  color: var(--clr-wht);
}

.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

body:not(.home) .header {
  border-bottom: 1px solid rgba(171, 154, 175, 0.2);
}

@media screen and (min-width: 48em) {
  body.home .header--logo {
    opacity: 0;
  }
  body.home .header-sm .header--logo {
    opacity: 1;
  }
}
.header-sm {
  border-bottom: 1px solid rgba(171, 154, 175, 0.2);
}
.header-sm + .header-bg {
  opacity: 1;
}

@media screen and (max-width: 67.5em) {
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}
/* gnav
********************************************** */
.gnav {
  position: fixed;
  z-index: 9990;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 111px;
  color: var(--clr-wht);
  background-color: #a1051d;
  background-image: url(../images/common/gnav-bg.jpg);
  background-position: center;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  transition: var(--transit-default);
  overflow: auto;
}
@media screen and (max-width: 47.9375em) {
  .gnav {
    padding-top: 64px;
    background-image: url(../images/common/gnav-bg-sp.jpg);
  }
}

.gnav.active {
  opacity: 1;
  pointer-events: auto;
}

.gnav a {
  color: var(--clr-wht);
}

.gnav--btm {
  margin-top: auto;
  padding-inline: 3.75rem;
  padding-bottom: 3.3125rem;
  font-size: 0.75rem;
}
@media screen and (max-width: 47.9375em) {
  .gnav--btm {
    align-items: center;
    flex-direction: column-reverse;
    gap: 0.5em;
    padding: 1.7142857143rem;
    font-size: 0.8571428571rem;
    border-top: 1px solid rgba(171, 154, 175, 0.2);
    margin-bottom: 4.5714285714rem;
  }
}

.gnav--btm-link {
  gap: 2em;
}
@media screen and (max-width: 47.9375em) {
  .gnav--btm-link {
    flex-wrap: wrap;
    gap: 0;
  }
  .gnav--btm-link li {
    width: 50%;
    padding-bottom: 6px;
  }
}

.gnav--menu {
  width: 83%;
  margin-inline: auto;
  flex: 1;
  padding-block: 4.1666666667vw;
}
@media screen and (min-width: 48em) {
  .gnav--menu {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    column-gap: 6.7708333333vw;
    grid-template-areas: "catch column-1 column-2" "sub_nav column-1 column-2";
  }
}
@media screen and (max-width: 100em) {
  .gnav--menu {
    width: 90%;
    column-gap: 4.1666666667vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .gnav--menu {
    width: 88%;
  }
}

@media screen and (min-width: 48em) {
  .gnav--intro {
    grid-area: catch;
  }
}

@media screen and (max-width: 47.9375em) {
  .gnav--intro-sns {
    display: flex;
    gap: 1.1428571429rem;
    flex-direction: row-reverse;
    margin-bottom: 1.4285714286rem;
  }
}

.gnav--catch {
  width: fit-content;
  font-size: 2.5rem;
  line-height: 1.5;
  letter-spacing: 0.21em;
  white-space: nowrap;
}
@media screen and (max-width: 100em) {
  .gnav--catch {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 48em) {
  .gnav--catch {
    font-feature-settings: normal;
  }
}
@media screen and (max-width: 47.9375em) {
  .gnav--catch {
    font-size: 1.2857142857rem;
    line-height: 1.8888888889;
    letter-spacing: 0.15em;
  }
}

.gnav--item:not(:last-child) {
  margin-bottom: 3.125rem;
}

.gnav_sub {
  margin-top: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .gnav_sub {
    display: none;
  }
}

.gnav--item-list {
  font-size: 1.125rem;
  line-height: 2;
}
@media screen and (max-width: 47.9375em) {
  .gnav--item-list {
    font-size: 1.0714285714rem;
  }
}
.gnav--item-list.column-2 {
  column-count: 2;
  page-break-inside: avoid;
  break-inside: avoid;
}

.gnav--item-list a {
  display: flex;
  align-items: center;
  gap: 1.4375rem;
}
.gnav--item-list a::before {
  content: "";
  width: 1rem;
  height: 1px;
  background-color: var(--bg-wht);
}
.gnav--item-list a br {
  display: none;
}
.gnav--item-list a .icon-arrow {
  display: none;
}

.gnav--sub_nav {
  font-size: 0.9375rem;
  line-height: 2.4;
}
@media screen and (min-width: 48em) {
  .gnav--sub_nav {
    grid-area: sub_nav;
  }
}
@media screen and (max-width: 47.9375em) {
  .gnav--sub_nav {
    margin-top: 1.7142857143rem;
    font-size: 0.8571428571rem;
  }
}

.gnav--sub_nav ul {
  column-count: 2;
  break-inside: avoid;
}

.gnav--sub_nav a {
  display: flex;
  gap: 1rem;
  margin-inline: -0.5em;
  padding: 0.5em;
  line-height: 1.3;
}

@media screen and (min-width: 48em) {
  .gnav--column.column-1 {
    grid-area: column-1;
  }
}
@media screen and (max-width: 47.9375em) {
  .gnav--column.column-1 {
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }
}
@media screen and (min-width: 48em) {
  .gnav--column.column-2 {
    grid-area: column-2;
  }
}

.gnav--link {
  display: grid;
  place-items: center;
  height: 100%;
  padding: 0 1em;
  text-align: center;
  color: var(--clr-wht);
}
@media screen and (max-width: 47.9375em) {
  .gnav--link {
    padding: 0 0.5em;
  }
}

.gnav--ttl {
  position: relative;
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;
  letter-spacing: 0.09em;
  line-height: var(--line-height-none);
}
@media screen and (max-width: 47.9375em) {
  .gnav--ttl {
    font-size: 1.2857142857rem;
  }
}
.gnav--ttl::before {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 4px;
  background-color: var(--bg-wht);
  border-radius: 100px;
}

.gnav--ttl a {
  margin: -1em;
  padding: 1em;
  flex: 1;
}
@media screen and (max-width: 47.9375em) {
  .gnav--ttl a {
    margin-right: calc(12.5px + 1em);
  }
}

.header--nav {
  position: relative;
  z-index: 9999;
  font-size: clamp(14px, 0.8333333333vw, 16px);
  line-height: var(--line-height-none);
  letter-spacing: 0.05em;
}

.language_btn {
  color: var(--clr-wht);
  font-size: 0.875rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 6px 14px 6px 16px;
}
@media screen and (max-width: 47.9375em) {
  .language_btn {
    margin-right: 8px;
  }
}

@media screen and (max-width: 93.75em) {
  .header--nav .menu-item {
    display: none;
  }
}
.header--nav .icon-heart {
  width: 27px;
}

.search-keyword {
  width: min(100%, 400px);
}
.search-keyword input.searchtext + .caret {
  position: absolute;
  left: calc(1rem + 3px);
  top: calc(1rem - 1px);
  width: 1px;
  height: calc(1rem + 2px);
  background-color: var(--bg-wht);
  animation: caret 1s linear infinite;
  pointer-events: none;
}
.search-keyword input.searchtext:focus + .caret {
  display: none;
}

.search-keyword.txt-ctr {
  margin-inline: auto;
}

.search-keyword input {
  width: 100%;
  padding: 0.5625rem 2.25rem 0.5625rem 1.125rem;
  font-size: 1.125rem;
  font-family: var(--font-yu-cothic);
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  background: none;
  outline: none;
}
.search-keyword input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.search-keyword button svg {
  fill: var(--bg-blk);
}

.search-keyword button {
  position: absolute;
  right: 0;
  bottom: 0;
  background: none;
  border: none;
  height: 100%;
  aspect-ratio: 1;
  cursor: pointer;
}
.search-keyword button svg {
  width: 17px;
  height: auto;
  aspect-ratio: 1;
  fill: currentColor;
}

.gnav--search .search-keyword {
  width: 100%;
}
.gnav--search .search-keyword input {
  color: var(--clr-wht);
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.gnav--search .search-keyword input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.gnav--search .search-keyword button svg {
  fill: var(--bg-wht);
}

.gnav--search-more {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
@media screen and (max-width: 47.9375em) {
  .gnav--search-more {
    font-size: 0.9285714286rem;
  }
}

.gnav--search-list {
  gap: 8px;
}

.gnav--search-list a {
  display: inline-block;
  padding: 0.7em;
  color: var(--clr-wht);
  font-size: 0.875rem;
  line-height: var(--line-height-none);
  border-radius: 4px;
  background-color: rgba(200, 25, 45, 0.75);
}

.gnav_btn {
  position: relative;
  z-index: 9999;
  width: 70px;
  height: 111px;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (min-width: 48em) {
  .gnav_btn {
    margin-right: 16px;
  }
}
@media screen and (max-width: 47.9375em) {
  .gnav_btn {
    width: 54px;
    height: 64px;
  }
}

.header.active .gnav_btn--lines span:nth-of-type(1) {
  transform: translateY(11px) rotate(-35deg);
}
@media screen and (max-width: 47.9375em) {
  .header.active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(8px) rotate(-35deg);
  }
}
.header.active .gnav_btn--lines span:nth-of-type(2) {
  opacity: 0;
}
.header.active .gnav_btn--lines span:nth-of-type(3) {
  transform: translateY(-10px) rotate(35deg);
}
@media screen and (max-width: 47.9375em) {
  .header.active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-8px) rotate(35deg);
  }
}

.gnav_btn--lines {
  position: relative;
  width: 36px;
  height: 24px;
}
@media screen and (max-width: 47.9375em) {
  .gnav_btn--lines {
    width: 26px;
    height: 18px;
  }
}

.gnav_btn--lines span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--bg-wht);
  transition: var(--transit-default, all 0.4s ease-out);
}
.gnav_btn--lines span:nth-of-type(1) {
  top: 0;
}
.gnav_btn--lines span:nth-of-type(2) {
  top: calc(50% - 0.5px);
}
.gnav_btn--lines span:nth-of-type(3) {
  bottom: 0;
}

@media print, screen and (min-width: 67.5625em) {
  .gnav,
.gnav ul,
.gnav a {
    height: 100%;
  }

  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
.gnav--menu > li.current-menu-item > .gnav--link {
    color: var(--clr-wht, #fff);
    background-color: var(--clr-body, #333);
  }

  .gnav--contact a {
    border-radius: 0;
  }

  /* hover */
  .gnav--menu .menu-item-has-children:hover .gnav--menu-sub {
    opacity: 1;
    pointer-events: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .gnav_sub_toggle {
    position: absolute;
    top: calc(50% - 22.5px);
    right: -12.5px;
    width: 45px;
    height: 45px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-wht);
  }
  .gnav_sub_toggle::before, .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 20px;
    height: 2px;
    background-color: currentColor;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
}
.home .lps_sec-bg-lft {
  width: 34.1145833333vw;
}

.home .lps_sec-bg-lft span {
  background-image: url(../images/bg-lft-top.jpg);
  aspect-ratio: 655/1010;
}

.home .lps_sec-bg-rgt {
  width: 27.03125vw;
}

.home .lps_sec-bg-rgt span {
  top: auto !important;
  bottom: 0 !important;
  background-image: url(../images/bg-rgt-top.jpg);
  aspect-ratio: 519/1151;
}

/* sns_area
********************************************** */
.sns--list a {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
.sns--list a:hover {
  opacity: 0.7;
}

.sns--list svg {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 47.9375em) {
  .header .sns_area {
    display: none;
  }
}
.header .sns--list {
  position: relative;
  z-index: 9999;
  height: 100%;
}
.header .sns--list svg {
  width: 1.125rem;
  height: 1.125rem;
  fill: var(--bg-wht);
}

.gnav .sns_area {
  flex: 1;
}
.gnav .sns_area svg {
  fill: currentColor;
}
@media screen and (max-width: 47.9375em) {
  .gnav .sns_area svg {
    width: 1.2857142857rem;
    height: 1.2857142857rem;
  }
}
.gnav .sns--list {
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}
.gnav .sns--list a {
  width: 1.7142857143rem;
  height: 1.7142857143rem;
}
@media screen and (min-width: 48em) {
  .gnav .sns_area {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
  }
}
.gnav .sns--list {
  gap: 0.625rem;
}
.gnav .sns--list a {
  display: grid;
  place-content: center;
  width: 3.0625rem;
  height: 3.0625rem;
  aspect-ratio: 1;
  background-color: var(--bg-wht);
  border-radius: 50%;
}
@media screen and (max-width: 47.9375em) {
  .gnav .sns--list a {
    width: 2.1428571429rem;
    height: 2.1428571429rem;
    padding: 0.4285714286rem;
  }
}

/* fix_sideNav
**************************************** */
.fix_sideNav {
  position: fixed;
  right: 0;
  z-index: 9999;
  transition: var(--transit-default);
  translate: 100% 0;
}
@media print, screen and (min-width: 48em) {
  .fix_sideNav {
    top: 20%;
    width: 6.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .fix_sideNav {
    inset-inline: 2%;
    bottom: 0;
    translate: 0 100%;
  }
}
.fix_sideNav.show {
  translate: 0 0;
}

.fix_sideNav ul {
  display: flex;
  gap: 0.5rem;
}
@media print, screen and (min-width: 48em) {
  .fix_sideNav ul {
    flex-direction: column;
  }
}

.fix_sideNav li {
  flex: 1;
}

.fix_sideNav a {
  display: block;
  width: 100%;
  padding: 0.625rem;
  color: #da063f;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bg-wht);
  border-radius: 0.5rem 0 0 0.5rem;
  box-shadow: 0 0 32px rgba(102, 15, 38, 0.15);
}
@media screen and (max-width: 47.9375em) {
  .fix_sideNav a {
    height: 100%;
    padding: 0.5714285714rem;
    border-radius: 0.5714285714rem 0.5714285714rem 0 0;
  }
}

.fix_sideNav img {
  display: block;
  max-width: 4rem;
  max-height: 4rem;
  margin-inline: auto;
  margin-bottom: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .fix_sideNav img {
    max-width: 2.2142857143rem;
    max-height: 2.2142857143rem;
    margin-bottom: 0.2857142857rem;
  }
}

/************************************************
Win対応
************************************************/
.win .header {
  mix-blend-mode: normal;
}

.win .header a {
  color: var(--clr-blk);
}

.win .header svg {
  fill: var(--bg-blk) !important;
}

.win .gnav_btn--lines span {
  background-color: var(--bg-blk);
}

.win .header.active a {
  color: var(--clr-wht);
}

.win .header.active svg {
  fill: var(--bg-wht) !important;
}

.win .header.active .gnav_btn--lines span {
  background-color: var(--bg-wht);
}

/* hero
********************************************** */
.hero {
  position: relative;
  overflow: hidden;
}
.hero::before,
.hero .hero-mask,
.hero .hero--cont,
.hero .hero--ttl {
  opacity: 0;
}

.hero.loaded::before,
.hero.loaded .hero-mask {
  animation: fade_In ease-out 0.8s forwards;
}
.hero.loaded .hero--ttl {
  animation: fade_slide_In ease-out 0.8s 0.3s forwards;
}
.hero.loaded .hero--cont {
  animation: fade_slide_In ease-out 0.8s 0.6s forwards;
}

.hero {
  position: relative;
  height: 62.7604166667vw;
  background-image: url(../images/hero-bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media screen and (max-width: 47.9375em) {
  .hero {
    height: 154.5893719807vw;
    background-image: url(../images/hero-bg-sp.jpg);
  }
}
.hero::before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url(../images/hero.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media screen and (max-width: 47.9375em) {
  .hero::before {
    background-image: url(../images/hero-sp.jpg);
  }
}
.hero::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-image: url(../images/hero-btm.svg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  aspect-ratio: 1920/414;
}

.hero-mask {
  position: absolute;
  inset: 0;
  mask-image: url(../images/hero-mask.png);
  mask-repeat: no-repeat;
  mask-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .hero-mask {
    mask-image: url(../images/hero-mask-sp.png);
  }
}

.hero .bg-circle {
  left: 5.1041666667vw;
  top: 16.8229166667vw;
  width: 53.28125vw;
}
@media screen and (max-width: 47.9375em) {
  .hero .bg-circle {
    left: -31.4009661836vw;
    top: 16.9082125604vw;
    width: 123.4299516908vw;
  }
}

.hero--logo {
  position: absolute;
  left: 50%;
  translate: -50% 0;
  width: fit-content;
  margin-inline: auto;
  margin-top: 5.2083333333vw;
  font-size: 0.8125rem;
  text-align: center;
}

.hero--logo svg {
  display: block;
  width: 223px;
  height: auto;
  margin-bottom: 0.7em;
  aspect-ratio: 234.3/18.5;
}

.hero--ttl {
  position: absolute;
  top: 11.7708333333vw;
  right: 0;
  z-index: 2;
  width: 39.1111111111vw;
}
@media screen and (max-width: 47.9375em) {
  .hero--ttl {
    right: calc(50% - 50vw);
    top: 32.3671497585vw;
    width: 73.9130434783vw;
  }
}

.hero--cont {
  position: absolute;
  top: 36.6145833333vw;
  inset-inline: calc(50% - 50vw);
  z-index: 1;
  width: 100%;
  padding-block: 0.625rem;
  padding-left: 12.6041666667vw;
}
@media screen and (min-width: 48em) {
  .hero--cont {
    min-height: 324px;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero--cont {
    left: calc(50% - 50vw);
    top: 100.4830917874vw;
    width: 100vw;
    padding-top: 0.7142857143rem;
    padding-bottom: 1.4285714286rem;
    padding-inline: 6%;
  }
}
.hero--cont::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5.2083333333vw;
  width: 66.6145833333vw;
  height: 100%;
  background-image: url(../images/hero-txt-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
@media screen and (max-width: 47.9375em) {
  .hero--cont::before {
    top: 13.768115942vw;
    width: 100vw;
    background-size: 308.9371980676vw;
  }
}

.hero--counter {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-end;
  width: fit-content;
  margin-bottom: 1.25vw;
  padding: 1.25vw 1.6666666667vw;
  background-color: var(--bg-wht);
  background-image: url(../images/common/feather.png);
  background-repeat: no-repeat;
  background-position: top -26% left 4%;
  background-size: 14.1666666667vw;
  border-radius: 0 0.8333333333vw 0.8333333333vw 0;
  box-shadow: 0 0 32px rgba(102, 15, 38, 0.15);
  border-left: 8px solid;
  border-image: linear-gradient(to bottom, #1f1828, #cf436b, #dbddd7) 1;
  border-image-slice: 1;
}
@media screen and (max-width: 47.9375em) {
  .hero--counter {
    width: calc(100% + 5.3140096618vw);
    margin-inline: -2.6570048309vw;
    margin-bottom: 5.7971014493vw;
    padding: 3.6231884058vw;
    border-left: 4px solid;
    background-size: 35.0241545894vw;
  }
}

.hero--counter .ttl {
  position: relative;
  padding-right: 0.8333333333vw;
  color: transparent;
  font-size: 1.6666666667vw;
  line-height: 1.3;
  background: linear-gradient(138deg, #4c2302 19%, #9b7937 30%, #97732f 35%, #4c2302 76%);
  -webkit-background-clip: text;
}
@media screen and (max-width: 47.9375em) {
  .hero--counter .ttl {
    padding-right: 2.4154589372vw;
    font-size: 3.8647342995vw;
  }
}
.hero--counter .ttl::after {
  content: "";
  position: absolute;
  right: 0;
  inset-block: 0;
  width: 3px;
  border-inline: 1px solid #c8bfa0;
}

.hero--counter .ttl span {
  display: block;
  font-size: 0.9375vw;
}
@media screen and (max-width: 47.9375em) {
  .hero--counter .ttl span {
    font-size: 2.6570048309vw;
  }
}

.hero--counter .counter-wrap {
  display: flex;
  align-items: baseline;
  padding-left: 0.8333333333vw;
  font-size: 3.5416666667vw;
  line-height: 1;
  background: rgb(226, 61, 88);
  background: linear-gradient(180deg, rgb(226, 61, 88) 0%, rgb(221, 27, 59) 50%, rgb(185, 15, 43) 100%);
  text-decoration: blink;
  color: transparent;
  -webkit-background-clip: text;
}
@media screen and (max-width: 47.9375em) {
  .hero--counter .counter-wrap {
    padding-left: 2.4154589372vw;
    font-size: 8.4541062802vw;
  }
}

.hero--counter .counter-wrap span {
  font-size: 2.2916666667vw;
}
@media screen and (max-width: 47.9375em) {
  .hero--counter .counter-wrap span {
    font-size: 5.7971014493vw;
  }
}

.hero--catch-shadow {
  z-index: 0;
  color: transparent !important;
  text-shadow: 0 0 1.25rem #fff;
}

.hero--catch {
  font-size: 2.0833333333vw;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .hero--catch {
    font-size: 4.8309178744vw;
  }
}

.hero--catch span {
  display: inline-block;
}

.hero--catch p {
  color: transparent;
  line-height: 1.2115384615;
  background: linear-gradient(138deg, #4c2302 19%, #9b7937 30%, #97732f 35%, #4c2302 76%);
  -webkit-background-clip: text;
}

.hero--catch ul {
  color: #222222;
  font-size: min(1.5vw, 1.5rem);
  line-height: 1.6666666667;
  letter-spacing: 0.03em;
}
@media screen and (max-width: 47.9375em) {
  .hero--catch ul {
    font-size: 3.3816425121vw;
  }
}

.hero--catch li {
  padding-left: 2.5rem;
  background-image: url(../images/check.png);
  background-size: 2.125rem;
  background-position: 0 0.1875rem;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .hero--catch li {
    padding-left: 1.4285714286rem;
    background-size: 1rem;
  }
}

.txt-red {
  color: transparent;
  background: linear-gradient(138deg, #8e0b0b 19%, #d4112f 50%, #8e0b0b 76%);
  -webkit-background-clip: text;
}

.txt-red-light {
  position: absolute;
  background-image: url(../images/hero-txt-deco.png);
  background-position: left top 0.5rem;
  background-repeat: repeat-x;
  background-clip: text;
  -webkit-background-clip: text;
}
@media screen and (max-width: 47.9375em) {
  .txt-red-light {
    background-position: left top -6px;
  }
}

/* home_article_line
********************************************** */
.home_article_line .inner {
  display: grid;
  grid-template-columns: 1fr 48.125rem;
  gap: 1rem;
  border-bottom: 1px solid #c8bfa0;
  padding-bottom: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .home_article_line .inner {
    display: flex;
    flex-direction: column;
  }
}
.home_article_line .post {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.home_article_line .more-link {
  order: 1;
  color: var(--clr-main);
  margin-left: 0.5rem;
  padding: 0.5em 1em;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 9999px;
  border: 1px solid var(--clr-main-sub);
}
.home_article_line .more-link:hover {
  color: var(--clr-wht);
  background-color: var(--clr-main-sub);
}

.slide-article_line {
  padding-right: 11.25rem;
}
@media screen and (max-width: 47.9375em) {
  .slide-article_line {
    padding-right: 5rem;
  }
}

.slide-article_line--arrow {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  translate: 0 -50%;
}
.slide-article_line--arrow .slick-arrow {
  display: grid;
  place-content: center;
  width: 2.25rem;
  height: 2.25rem;
}
.slide-article_line--arrow .slick-arrow::before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  color: var(--clr-main);
  rotate: 45deg;
}
.slide-article_line--arrow .slick-prev::before {
  border-left: 2px solid;
  border-bottom: 2px solid;
}
.slide-article_line--arrow .slick-next::before {
  border-right: 2px solid;
  border-top: 2px solid;
}

/* home_anchor
********************************************** */
.home_anchor {
  padding-top: 4.1666666667vw;
}

.home_anchor ul {
  display: grid;
}
@media screen and (min-width: 48em) {
  .home_anchor ul {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (max-width: 47.9375em) {
  .home_anchor ul {
    justify-content: center;
    padding-block: 1rem;
    border-block: 3px double #c8bfa0;
  }
}

@media screen and (min-width: 48em) {
  .home_anchor li {
    border-right: 3px double #c8bfa0;
  }

  .home_anchor li:first-child {
    border-left: 3px double #c8bfa0;
  }
}
.home_anchor li a {
  display: block;
  padding: 0.5em;
  color: var(--clr-main);
  font-size: 1.375rem;
}
@media screen and (max-width: 85.375em) {
  .home_anchor li a {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_anchor li a {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5em;
    font-size: 1.5714285714rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_anchor li a::before {
    display: none;
  }
}

.home_anchor li .font-num {
  line-height: var(--line-height-none);
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .home_anchor li .font-num {
    font-size: 1.5714285714rem;
  }
}

.home_anchor li .font-jp {
  line-height: 1.5;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 48em) {
  .home_anchor li .font-jp {
    margin-top: 1rem;
  }
}

/* home_article_cat
********************************************** */
.article_cat--bg .bg-lft .bg-circle {
  left: -18.0208333333vw;
}
@media screen and (max-width: 47.9375em) {
  .article_cat--bg .bg-lft .bg-circle {
    left: -36.231884058vw;
  }
}
.article_cat--bg .bg-rgt .bg-circle {
  right: -12.2916666667vw;
}
@media screen and (max-width: 47.9375em) {
  .article_cat--bg .bg-rgt .bg-circle {
    right: -36.231884058vw;
  }
}

.home_article_cat {
  background-image: url(../images/article_cat-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
}

.home_article_cat .section_ttl img {
  width: 58.125rem;
  height: auto;
}
@media screen and (max-width: 47.9375em) {
  .home_article_cat .section_ttl img {
    width: 80%;
  }
}

.home_article_cat--intro {
  position: relative;
  width: 90%;
  max-width: 1200px;
  margin-inline: auto;
}

.home_article_cat--intro .txtarea {
  margin-bottom: 5.5208333333vw;
  font-size: 1.375rem;
  line-height: 2.1818181818;
}
@media screen and (min-width: 48em) {
  .home_article_cat--intro .txtarea {
    margin-left: 19.2708333333vw;
    padding-inline: 4%;
    border-inline: 4px double #c8bfa0;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_article_cat--intro .txtarea {
    display: flex;
    flex-wrap: wrap;
    padding-inline: 1.7142857143rem;
    font-size: 1.1428571429rem;
    text-align: justify;
    line-height: 1.875;
  }
}
.home_article_cat--intro .txtarea::before {
  content: "";
  width: 16.5625vw;
  aspect-ratio: 318/436;
  background-image: url(../images/article_cat-img.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (min-width: 48em) {
  .home_article_cat--intro .txtarea::before {
    position: absolute;
    left: 3.4895833333vw;
    bottom: -8.8541666667vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_article_cat--intro .txtarea::before {
    order: 1;
    width: 10.2857142857rem;
    margin-right: calc(50% - 50vw);
    margin-bottom: -7.1428571429rem;
    margin-top: -1.4285714286rem;
    background-image: url(../images/article_cat-img_sp.png);
    aspect-ratio: 288/464;
  }
}

@media screen and (max-width: 47.9375em) {
  .home_article_cat--intro p:last-child {
    flex: 1;
  }
}
.article_cat {
  display: grid;
  gap: 1.8125rem;
}
@media screen and (min-width: 48em) {
  .article_cat {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 47.9375em) {
  .article_cat {
    gap: 0.7142857143rem;
  }
}

.article_cat a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 1.4583333333vw 2.0833333333vw;
  padding-left: 9.0476190476vw;
  border-left: 8px solid;
  border-image: linear-gradient(to bottom, #1f1828, #cf436b, #dbddd7) 1;
  border-image-slice: 1;
  background-color: var(--bg-wht);
  border-radius: 0 16px 0 0;
  box-shadow: 0 0 52px rgba(103, 15, 39, 0.15);
}
@media screen and (max-width: 47.9375em) {
  .article_cat a {
    padding: 0.5em 1em;
    padding-left: 6.1428571429rem;
    border-left: 4px solid;
    box-shadow: 0 0 10px rgba(103, 15, 39, 0.15);
  }
}
.article_cat a::before {
  content: "";
  position: absolute;
  left: 2.0833333333vw;
  top: 50%;
  display: block;
  width: 5.2083333333vw;
  height: 5.2083333333vw;
  background-image: var(--icon);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  translate: 0 -50%;
}
@media screen and (max-width: 47.9375em) {
  .article_cat a::before {
    left: 1.0714285714rem;
    width: 3.5714285714rem;
    height: 3.5714285714rem;
  }
}
.article_cat a .icon-arrow {
  width: 0.75rem;
  height: 1.5rem;
  fill: #999;
}

.cat-ttl {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .cat-ttl {
    font-size: 1.4285714286rem;
  }
}

.cat-txt {
  opacity: 0.5;
}
@media screen and (max-width: 47.9375em) {
  .cat-txt {
    font-size: 1rem;
  }
}

/* home_article
********************************************** */
.home_article {
  padding-bottom: 8.3333333333vw;
  min-height: 52.5520833333vw;
  background-image: url(../images/article-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
}
@media screen and (max-width: 47.9375em) {
  .home_article {
    padding-top: 0;
  }
}

.home_article .inner {
  max-width: 1200px;
}

.home_article .section_ttl img {
  width: 54.5rem;
  height: auto;
}
@media screen and (max-width: 47.9375em) {
  .home_article .section_ttl img {
    width: 80%;
  }
}

.home_article .posts-article {
  background-color: var(--bg-wht);
  box-shadow: 0 0 52px rgba(103, 15, 39, 0.15);
}

.home_article .posts-article .post {
  padding: 1.5625rem 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_article .posts-article .post {
    padding: 1rem;
  }
}
.home_article .posts-article .post:not(:last-child) {
  border-bottom: 1px solid #eedfdd;
}

/* column_box
********************************************** */
.column_box {
  display: grid;
  gap: 2.6041666667vw;
}
@media screen and (min-width: 48em) {
  .column_box {
    grid-template-columns: 27.3958333333vw 1fr;
  }
}

.column_box--ttl img {
  display: block;
}

@media screen and (max-width: 47.9375em) {
  .column_box--more {
    margin-top: 1.1428571429rem;
  }
}
.column_box--more a {
  font-size: 1.375rem;
}
@media screen and (max-width: 47.9375em) {
  .column_box--more a {
    display: block;
    width: fit-content;
    margin-inline: auto;
    font-size: 1.1428571429rem;
  }
}

.section_ttl span {
  display: block;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 47.9375em) {
  .section_ttl span.fz-56 {
    font-size: 2.5714285714rem;
  }
}

.home_voice .column_box--ttl img,
.home_news .column_box--ttl img {
  width: auto;
  height: 3.3125rem;
}

.home_youtube .column_box--ttl img.ttl {
  width: auto;
  height: 4.875rem;
}

.home_youtube .column_box--ttl img.balloon {
  width: auto;
  height: 3.75rem;
}

@media screen and (max-width: 47.9375em) {
  .home_voice .column_box--ttl img,
.home_news .column_box--ttl img,
.home_youtube .column_box--ttl img.ttl,
.home_youtube .column_box--ttl img.balloon {
    height: 1.8571428571rem;
    margin-inline: auto;
  }

  .home_youtube .column_box--ttl img.balloon {
    height: 2.8571428571rem;
    margin-bottom: 8px;
  }

  .home_youtube .column_box--ttl img.ttl {
    height: 5rem;
  }
}
/* home_flow
********************************************** */
.home_flow {
  padding-bottom: 9.375rem;
  background-image: url(../images/flow-bg01.jpg), url(../images/flow-bg02.jpg), url(../images/flow-bg03.jpg);
  background-position: top left, top 873px left, top 2014px left;
  background-repeat: no-repeat;
  background-size: 1920px;
}
@media screen and (max-width: 47.9375em) {
  .home_flow {
    padding-bottom: 2.8571428571rem;
  }
}
.home_flow::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1920/1200;
  background-image: url(../images/flow-bg-btm.png);
}

.home_flow .inner {
  position: relative;
  z-index: 1;
  max-width: 1150px;
}

.home_flow .btn a {
  width: min(100%, 280px);
  padding: 1rem 2.25rem;
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .home_flow .btn a {
    padding: 0.5714285714rem 1.4285714286rem;
    font-size: 1rem;
  }
}
.home_flow .btn a::after {
  content: "";
  right: 1.5rem;
}

.okuraku_flow--img {
  position: relative;
  flex-shrink: 0;
  width: fit-content;
}
@media screen and (max-width: 47.9375em) {
  .okuraku_flow--img {
    width: 30%;
  }
}

.okuraku_flow--img img {
  position: relative;
  z-index: 1;
}

.okuraku_flow li {
  display: flex;
  gap: 2.3958333333vw;
  max-width: 940px;
}
@media screen and (min-width: 48em) {
  .okuraku_flow li {
    width: 80%;
    min-height: 232px;
  }
}
.okuraku_flow li:not(:last-child) {
  padding-bottom: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .okuraku_flow li:not(:last-child) {
    padding-bottom: 2.2857142857rem;
  }
}
@media screen and (min-width: 48em) {
  .okuraku_flow li:not(:last-child) .okuraku_flow--img::before {
    content: "";
    position: absolute;
    top: calc(100% - 3.125rem);
    left: calc(50% - 2px);
    width: 4px;
    bottom: calc(-100% - 6.25rem);
    background-color: #b2c8d1;
    transform-origin: top center;
  }
  .okuraku_flow li:nth-child(2n+1) {
    margin-left: auto;
  }
  .okuraku_flow li:nth-child(2n+1) .okuraku_flow--img::before {
    transform: rotate(40deg);
  }
  .okuraku_flow li:nth-child(n+3) .okuraku_flow--img::before {
    background-color: #e62662;
  }
  .okuraku_flow li:nth-child(2n) .okuraku_flow--img::before {
    transform: rotate(-40deg);
  }
}

@media screen and (max-width: 47.9375em) {
  .okuraku_flow li {
    position: relative;
  }
  .okuraku_flow li:not(:last-child)::before {
    content: "";
    position: absolute;
    left: 15%;
    width: 2px;
    top: 0;
    bottom: -3.5714285714rem;
    background-color: #b2c8d1;
  }
  .okuraku_flow li:nth-child(4)::before, .okuraku_flow li:first-child::before {
    top: 3.5714285714rem;
  }
  .okuraku_flow li:nth-last-child(2)::before {
    bottom: 0;
  }
  .okuraku_flow li:nth-child(n+4)::before {
    background-color: #e62662;
  }

  .okuraku_flow--flow2 .okuraku_flow li::before {
    display: none;
  }
}
.okuraku_flow--icon {
  display: inline-block;
  width: fit-content;
  color: var(--clr-wht);
  font-size: 1.5rem;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 80%, 0 100%);
  background-image: linear-gradient(135deg, #d5ae35, #875909);
  padding: 18px 11px 22px;
  flex-shrink: 0;
}
@media screen and (max-width: 47.9375em) {
  .okuraku_flow--icon {
    font-size: 0.9285714286rem !important;
    padding: 0.7142857143rem 0.4285714286rem 1.2857142857rem;
  }
}

.okuraku_flow--ttl {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.okuraku_flow--ttl span {
  display: block;
}
@media screen and (max-width: 47.9375em) {
  .okuraku_flow--ttl span {
    font-size: 1.2857142857rem;
  }
}

.okuraku_flow--ttl span i {
  display: block;
  width: fit-content;
  padding: 0.5em;
  color: var(--clr-wht);
  font-size: 0.875rem;
  line-height: var(--line-height-none);
  background-color: var(--bg-red);
  border-radius: 4px;
}

@media screen and (max-width: 47.9375em) {
  .okuraku_flow--lead {
    font-size: 1.4285714286rem;
  }
}
.okuraku_flow--ttl .font-en {
  margin-top: 0.5em;
}
@media screen and (max-width: 47.9375em) {
  .okuraku_flow--ttl .font-en {
    display: none;
  }
}

@media screen and (max-width: 47.9375em) {
  .okuraku_flow--txt {
    font-size: 0.9285714286rem;
  }
}
.okuraku_flow--flow2 {
  padding-top: 10rem;
}
@media screen and (max-width: 47.9375em) {
  .okuraku_flow--flow2 {
    padding-top: 4.2857142857rem;
  }
}
.okuraku_flow--flow2::before, .okuraku_flow--flow2::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  z-index: -1;
  width: 130%;
  border-radius: 50%;
  background-color: var(--bg-wht);
  aspect-ratio: 1.8/1;
}
@media screen and (max-width: 47.9375em) {
  .okuraku_flow--flow2::before, .okuraku_flow--flow2::after {
    display: none;
  }
}
.okuraku_flow--flow2::after {
  width: calc(130% + 80px);
  opacity: 0.5;
}
.okuraku_flow--flow2 .okuraku_flow li .okuraku_flow--img::before {
  display: none;
}
.okuraku_flow--flow2 .okuraku_flow li:nth-child(2n+1) {
  margin-left: 0;
}
.okuraku_flow--flow2 .okuraku_flow li:nth-child(2n) {
  margin-left: auto;
}

/* home_program
********************************************** */
.bg-program {
  background-image: url(../images/program-bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
}
@media screen and (max-width: 47.9375em) {
  .bg-program {
    background-image: url(../images/program-bg-sp.jpg);
    background-position: bottom;
    background-size: 100%;
  }
}

.home_program .section_ttl img {
  width: auto;
  height: 4.75rem;
}
@media screen and (max-width: 47.9375em) {
  .home_program .section_ttl img {
    height: 2.1428571429rem;
  }
}

.home_program--lead {
  color: transparent;
  background: linear-gradient(138deg, #483008 0%, #5f4c24 50%, #483008 100%);
  -webkit-background-clip: text;
  font-size: 2.25rem;
  line-height: 1.4;
  letter-spacing: 0.11em;
}
@media screen and (max-width: 47.9375em) {
  .home_program--lead {
    font-size: 1.4285714286rem;
  }
}

.program_list.cat-okuraku {
  padding-bottom: 8.3333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .program_list.cat-okuraku {
    padding-bottom: 16.9082125604vw;
  }
}

.program_list.cat-english {
  background-image: url(../images/program-bg-english.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
}
.program_list.cat-english .program_list--bg {
  display: none;
}

@media screen and (max-width: 47.9375em) {
  .program_list--ttl {
    font-size: 2.2857142857rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .program_list--txt br {
    display: none;
  }
}
.home_program .program_list.cat-okuraku .sec-round {
  display: none;
}

.home_program .program_list.cat-english {
  padding-bottom: 7.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_program .program_list.cat-english {
    padding-bottom: 3rem;
  }
}

/* sec-become
********************************************** */
.sec-become {
  position: relative;
  z-index: -1;
  overflow: hidden;
  padding-top: 16.6666666667vw;
  padding-bottom: 21.3541666667vw;
  color: #fff2de;
  background-image: url(../images/become-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .sec-become {
    padding-top: 19.3236714976vw;
    padding-bottom: 33.8164251208vw;
    background-image: url(../images/become-bg_sp.jpg);
  }
}

.sec-become .inner {
  position: relative;
  z-index: 0;
}
.sec-become .inner::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 70%;
  z-index: -1;
  width: 70%;
  aspect-ratio: 1;
  background-color: #677f1d;
  border-radius: 50%;
  filter: blur(40px);
  translate: -50% -50%;
  z-index: -1;
  opacity: 0.5;
}

.sec-become img {
  width: 53.125rem;
  height: auto;
}
@media screen and (max-width: 47.9375em) {
  .sec-become img {
    width: 80%;
  }
}

.sec-become-txt {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .sec-become-txt {
    font-size: 0.8571428571rem;
    margin-bottom: 1em;
  }
}

/* home_story
********************************************** */
.home_story {
  position: relative;
  background-image: url(../images/story-bg.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: max(100%, 1920px);
}
@media screen and (max-width: 47.9375em) {
  .home_story {
    background-image: url(../images/story-bg-sp.jpg);
    background-size: 100%;
  }
}
.home_story::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -9.375vw;
  width: 100%;
  background-image: url(../images/story-bg-btm.png);
  background-position: center bottom;
  background-size: max(100%, 414px);
  aspect-ratio: 1920/358;
  pointer-events: none;
}

.home_story .section_ttl img {
  width: auto;
  height: 3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .home_story .section_ttl img {
    height: 5.7142857143rem;
  }
}

@media screen and (min-width: 48em) {
  .home_story--ttl {
    position: absolute;
    right: 3.8020833333vw;
    top: -5.2083333333vw;
    z-index: 1;
    aspect-ratio: 121/534;
    width: 6.3020833333vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_story--ttl {
    margin-bottom: 2.2857142857rem;
  }
}

.home_story--ttl img {
  width: 100%;
}

.home_story .more-cont {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 80px;
}
.home_story .more-cont::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  width: 53.875rem;
  aspect-ratio: 862/300;
  background-color: var(--bg-wht);
  border-radius: 50%;
  filter: blur(40px);
  translate: -50% -50%;
}
@media screen and (max-width: 47.9375em) {
  .home_story .more-cont::before {
    width: calc(100% - 130px);
    aspect-ratio: 1;
  }
}

@media screen and (max-width: 47.9375em) {
  .home_story .more-cont h3 {
    margin-bottom: 1.7142857143rem;
  }
}

.home_story .more-cont img {
  width: 49.5rem;
  height: auto;
}

/* home_movie--child
********************************************** */
.home_movie--child {
  position: relative;
  width: 14rem;
  top: 0;
  left: 0;
  height: auto;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.home_movie--child a::before {
  content: "";
  position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 18.25px);
  z-index: 1;
  display: block;
  width: 36.5px;
  height: 26px;
  background-image: url(../images/common/icon-youtube-color.svg);
}

.home_movie--child img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100% !important;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

@media screen and (max-width: 47.9375em) {
  .home_movie {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .home_movie--child {
    width: 11rem;
  }
  .home_movie--child img {
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: 100% !important;
  }
}
/* home_voice
********************************************** */
.home_voice .inner {
  position: relative;
}
.home_voice .inner::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 11.25vw;
  background-image: url(../images/voice-bg.png);
  background-size: contain;
  aspect-ratio: 216/324;
}
@media screen and (max-width: 47.9375em) {
  .home_voice .inner::after {
    display: none;
  }
}

@media screen and (max-width: 47.9375em) {
  .home_voice img {
    width: 100vw;
    max-width: initial;
    margin-inline: calc(50% - 50vw);
    height: auto;
  }
}
/* home_news
********************************************** */
@media screen and (max-width: 47.9375em) {
  .home_news {
    padding-bottom: 9.6618357488vw;
  }
}
.home_news .news_list {
  flex: 1;
}

.news_list--ttl {
  display: block;
  position: relative;
  margin-right: 40px;
  padding: 0;
  line-height: 1.2;
}
@media screen and (max-width: 47.9375em) {
  .news_list--ttl {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: 16px;
  }
}

/* home_youtube
********************************************** */
@media screen and (max-width: 47.9375em) {
  .home_youtube {
    padding-bottom: 5.7971014493vw;
  }
}
.youtube_box img {
  margin-bottom: 1.5rem;
  border-radius: 16px;
}

.youtube_box--ttl {
  font-size: 0.9375rem;
  font-weight: bold;
  font-family: var(--font-secondary);
}

.youtube_box--time {
  color: #767676;
  font-size: 0.75rem;
}

/* cta
********************************************** */
.cta {
  background-image: url(../images/common/cta-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .cta {
    background-image: url(../images/common/cta-bg_sp.jpg);
  }
}

.cta .inner {
  z-index: 0;
  color: #a08043;
  padding-top: 15.1041666667vw;
  padding-bottom: 16.6666666667vw;
}
@media screen and (max-width: 47.9375em) {
  .cta .inner {
    padding-top: 24.154589372vw;
    padding-bottom: 28.9855072464vw;
  }
}

.cta-en {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .cta-en {
    font-size: 0.8571428571rem;
    margin-bottom: 1em;
  }
}

.home .cta .inner {
  padding-bottom: 7.8125vw;
}
@media screen and (max-width: 47.9375em) {
  .home .cta .inner {
    padding-bottom: 11.5942028986vw;
  }
  .home .cta .inner::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    width: 100%;
    aspect-ratio: 1;
    background-color: var(--bg-wht);
    border-radius: 50%;
    filter: blur(40px);
    translate: -50% -50%;
    z-index: -1;
    opacity: 0.5;
  }
}

.cta svg {
  fill: #fff;
}

@media screen and (max-width: 47.9375em) {
  .cta-ttl {
    margin-bottom: 2.2857142857rem;
  }
}
.cta-ttl img {
  width: 62.5rem;
  height: auto;
}
@media screen and (max-width: 47.9375em) {
  .cta-ttl img {
    width: 80%;
  }
}

.cta-txt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  white-space: nowrap;
}
.cta-txt::before, .cta-txt::after {
  content: "";
  width: 0.4375rem;
  height: 1.25rem;
  flex-shrink: 0;
  border-inline: 2px solid #bfa684;
}
.cta-txt::before {
  transform: skewX(50deg);
}
.cta-txt::after {
  transform: skewX(-50deg);
}

.cta-btn {
  gap: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .cta-btn {
    gap: 2rem;
  }
}

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  padding-top: 111px;
  overflow: hidden;
  background-image: url(../images/common/page_ttl-none.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-bottom: 14px solid;
  border-image: linear-gradient(to left, #f85aa5 0%, #ea5078 53%, #c40c40 100%) 1;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl {
    padding-top: 64px;
    border-bottom: 6px solid;
    background-image: url(../images/common/page_ttl-none-sp.jpg);
  }
}

.page_ttl-icon {
  display: block;
  width: 2.6041666667vw;
  min-width: 30px;
  margin-inline: auto;
  margin-bottom: 1.25rem;
  aspect-ratio: 1;
  background-image: var(--icon);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-icon {
    margin-bottom: 1.1428571429rem;
  }
}

.page_ttl-none {
  min-height: 242px;
  padding-top: 200px;
  border-bottom: none;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-none {
    min-height: 102px;
    padding-top: 85px;
  }
}

.single .page_ttl-none {
  margin-bottom: -46px;
}
@media screen and (max-width: 47.9375em) {
  .single .page_ttl-none {
    margin-bottom: -20px;
  }
}

.page_ttl-none > .inner::after {
  content: "";
  display: block;
  width: 195px;
  margin-inline: auto;
  background-image: url(../images/common/ttl-01-deco.svg);
  background-size: 100%;
  aspect-ratio: 209/12;
}

.page_ttl--bg-wrap {
  height: 389px;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--bg-wrap {
    height: 256px;
  }
}

.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url(../images/common/page_ttl-common.jpg);
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--bg {
    background-image: url(../images/common/page_ttl-common-sp.jpg);
  }
}

.page_ttl-jp {
  position: relative;
  font-family: var(--font-jp);
  font-size: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-jp {
    font-size: 2.2857142857rem;
  }
}

.page_ttl-none .page_ttl-jp {
  font-size: 3.125rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-none .page_ttl-jp {
    font-size: 2.2857142857rem;
  }
}

.page_ttl-en {
  position: relative;
  display: block;
  margin-top: 0.5em;
  font-size: 1.375rem;
  font-family: var(--font-en, "Montserrat", sans-serif);
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-en {
    font-size: 1.1428571429rem;
  }
}

.page_ttl-en-small {
  text-transform: none;
}

.page_ttl--list_wrap {
  border-bottom: 1px solid rgba(171, 154, 175, 0.2);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list_wrap {
    white-space: nowrap;
    overflow: scroll;
  }
}

@media screen and (max-width: 47.9375em) {
  .page_ttl--list {
    width: 100%;
    flex-wrap: nowrap !important;
  }
}

@media print, screen and (min-width: 48em) {
  .page_ttl--list li {
    min-height: 96px;
  }
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list li {
    width: fit-content !important;
  }
}

.page_ttl--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em;
  gap: 0.625rem;
  text-align: left;
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  line-height: 1;
  color: var(--clr-page-ttl, #b2790a);
  line-height: var(--line-height-hdr, 1.4);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list a {
    padding: 1em;
    font-size: 1.0714285714rem;
  }
}
.page_ttl--list a::before {
  content: "";
  flex-shrink: 0;
  width: 1.5rem;
  background-image: url(../images/common/icon-light.svg);
  background-size: contain;
  aspect-ratio: 1;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list a::before {
    width: 1.1428571429rem;
  }
}
.page_ttl--list a:hover {
  color: var(--clr-main, #1d7fb7);
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  color: transparent;
  background: linear-gradient(138deg, rgb(138, 97, 20) 0%, rgb(153, 118, 44) 50%, rgb(138, 97, 20) 100%);
  -webkit-background-clip: text;
}
.ttl-01:not([class*=mgn-btm]):not(:last-child),
.ttl-02:not([class*=mgn-btm]):not(:last-child),
.ttl-03:not([class*=mgn-btm]):not(:last-child),
.ttl-04:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 1em;
}

:is(.ttl-01, .ttl-02, .ttl-03, .ttl-04) span {
  display: block;
}

.ttl-01 {
  font-size: 3rem;
  letter-spacing: var(--ltr-space-default);
  line-height: 1.25;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    font-size: 1.8571428571rem;
  }
}

.ttl-02 {
  font-size: 2.375rem;
  line-height: 1.35;
  letter-spacing: var(--ltr-space-default);
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-size: 1.4285714286rem;
  }
}

.ttl-sub {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1em;
  font-size: 1.125rem;
}
.ttl-sub::before {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--brd-main);
}

.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2.5rem;
  line-height: 1.35;
  letter-spacing: var(--ltr-space-default);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 1.4285714286rem;
  }
}

.ttl-03 {
  font-size: 1.75rem;
  line-height: 1.3;
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-size: 1.4285714286rem;
  }
}

.page_ttl-en + .ttl-03 {
  display: block;
  margin-top: 1em;
}

@media screen and (max-width: 47.9375em) {
  .page_ttl-en + .ttl-03 br {
    display: none;
  }
}
.ttl-04 {
  font-size: 1.5rem;
}

.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 2rem;
  margin-bottom: 16px;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
    font-size: 1.7142857143rem;
  }
}

.ttl-01-sub {
  display: flex;
  font-size: 1.375rem;
  padding-top: 16px;
  letter-spacing: 0.03em;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01-sub {
    font-size: 1rem;
  }
}

.ttl-01-sub:before,
.ttl-01-sub:after {
  border-top: 1px solid;
  content: "";
  width: 20px;
}

.ttl-01-sub:before {
  margin-right: 10px;
}

.ttl-01-sub:after {
  margin-left: 10px;
}

.ttl-deco::before {
  content: "";
  display: block;
  width: 6.75rem;
  margin-bottom: 1.5rem;
  background: url(../images/common/ttl-deco04.svg) no-repeat;
  background-size: 100%;
  aspect-ratio: 74/15;
}
@media screen and (max-width: 47.9375em) {
  .ttl-deco::before {
    width: 5.7142857143rem;
  }
}
.ttl-deco.txt-ctr::before {
  margin-inline: auto;
}

.ttl-gold {
  color: transparent;
  background: linear-gradient(138deg, rgb(138, 97, 20) 0%, rgb(153, 118, 44) 50%, rgb(138, 97, 20) 100%);
  -webkit-background-clip: text;
}

.ttl-gold2 {
  color: transparent;
  background: linear-gradient(180deg, #9d7b39 0%, #724f0c 100%);
  -webkit-background-clip: text;
}

.ttl-brown {
  color: transparent;
  background: linear-gradient(138deg, #483008 0%, #5f4c24 50%, #483008 100%);
  -webkit-background-clip: text;
  letter-spacing: 0.11em;
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
}

/* --- post --- */
.post {
  position: relative;
}

.post--img {
  background-color: var(--bg-wht);
}

.post--img img {
  transition: var(--transit-default);
}

.post:hover .post--img img {
  opacity: 0.5;
}

.post--img .img-contain {
  max-width: 80%;
}

.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.post--date {
  color: #aaa;
}
@media screen and (max-width: 47.9375em) {
  .post--date {
    font-size: 0.8571428571rem;
  }
}
.post--date + .post--views {
  margin-left: 1rem;
}

.post--views {
  display: none;
  color: #aaa;
  font-size: 0.75rem;
}
@media screen and (max-width: 47.9375em) {
  .post--views {
    font-size: 0.8571428571rem;
  }
}

.post--info {
  gap: 8px;
}

.post--info.flex .cat_list + .post--date {
  margin-left: auto;
}

.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.875rem;
  min-width: 83px;
  font-family: var(--font-secondary);
  font-weight: bold;
  text-align: center;
  background-color: var(--clr-pink);
  color: var(--clr-wht, #fff);
  padding: 0.3em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
  line-height: var(--line-height-none);
  border-radius: 4px;
}

.cat_list a:hover {
  opacity: 0.7;
}

.icon-new {
  display: flex;
  align-items: center;
  margin-inline: 1.25rem;
  color: var(--bg-red);
  font-size: 1.25rem;
  font-family: var(--font-secondary);
  font-weight: bold;
  line-height: 1;
}

@media screen and (max-width: 68.75em) {
  .container > .inner.flex {
    display: block;
  }

  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}
/* side_column
**************************************** */
.side_column {
  width: 300px;
}
@media screen and (max-width: 68.75em) {
  .side_column {
    width: 100%;
  }
}

.side_section:not(:last-child) {
  margin-bottom: 64px;
}

.side--ttl {
  margin-bottom: 1rem;
  color: var(--clr-main-sub);
  font-size: 2rem;
  line-height: 1;
  letter-spacing: -0.03em;
}

/* --- list --- */
.side--list {
  gap: 2rem;
  font-size: 1rem;
}

.side--list a {
  display: flex;
  justify-content: space-between;
}

.post-type-article .side--list {
  line-height: 2;
  column-count: 2;
  page-break-inside: avoid;
  break-inside: avoid;
}

/* --- profile --- */
.profile-img {
  display: block;
  margin-inline: auto;
  border-radius: 50%;
}
@media screen and (max-width: 47.9375em) {
  .profile-img {
    width: 80%;
  }
}

/* --- post --- */
.posts-side {
  counter-reset: number;
}
.posts-side .post {
  display: grid;
  grid-template-columns: 4rem minmax(0, 1fr);
  gap: 1rem;
  align-items: flex-start;
}
.posts-side .post:not(:last-child) {
  margin-bottom: 1rem;
}
.posts-side .post::before {
  counter-increment: number;
  content: counter(number);
  position: absolute;
  left: -0.625rem;
  top: -0.625rem;
  display: grid;
  place-content: center;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0.625rem;
  color: var(--clr-wht);
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 50%;
  background-color: var(--bg-red);
}
.posts-side .post--date {
  margin-bottom: 0;
  font-size: 0.75rem;
}
.posts-side .post--ttl {
  font-size: 0.9375rem;
  line-height: var(--line-height-hdr);
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}
.archive_list--ttl.active::after {
  transform: rotate(315deg);
}

.archive_month {
  display: none;
  padding: 1rem;
  background-color: var(--bg-off_wht);
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}

.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}

.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}

.archive-pulldown .archive_list--label {
  margin-right: 8px;
}

.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--bg-wht, #fff);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}

.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht, #fff);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* article
**************************************** */
.posts-article {
  counter-reset: number;
}
@media screen and (max-width: 47.9375em) {
  .posts-article {
    margin-bottom: 1.7142857143rem;
  }
}

.posts-article .post {
  display: grid;
  gap: 2.5rem;
  border-bottom: 1px solid #e5e5e5;
  padding-block: 1.5rem;
}
.posts-article .post .icon-new {
  margin-right: 0.4166666667vw;
}
@media screen and (min-width: 48em) {
  .posts-article .post {
    grid-template-columns: 240px 1fr;
  }
}
@media screen and (max-width: 47.9375em) {
  .posts-article .post {
    gap: 1.4285714286rem;
  }
}
.posts-article .post .ttl-deco05--cont {
  right: 2rem;
  padding: 0 1rem 1rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-article .post .ttl-deco05--cont {
    right: 0.5714285714rem;
    padding: 0 0.7142857143rem 0.4285714286rem;
  }
}
.posts-article .post .ttl-deco05--cont::before {
  counter-increment: number;
  content: counter(number);
  font-size: 1.25rem;
  font-family: var(--font-en);
}

.posts-article .post--ttl {
  color: #111;
  font-size: 1.75rem;
  line-height: 1.2857142857;
}
@media screen and (max-width: 47.9375em) {
  .posts-article .post--ttl {
    font-size: 1.2857142857rem;
  }
}

.posts-article .post--txtarea {
  overflow: hidden;
}

/* form
**************************************** */
.posts-form .post {
  display: grid;
  place-items: center;
  padding: 1em;
  text-align: center;
  border-radius: 8px;
  background-color: var(--bg-wht);
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
  border-top: 14px solid;
  border-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
}

/* program
**************************************** */
.posts-program .post {
  margin-top: 2.75rem;
  border-radius: 8px;
  background-color: var(--bg-wht);
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
}
.posts-program .post::before, .posts-program .post::after {
  content: "";
  position: absolute;
  background-color: var(--bg-wht);
  border-radius: 8px;
}
.posts-program .post::before {
  top: -2.75rem;
  left: calc(50% - 10rem / 2);
  z-index: 1;
  width: 10rem;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
}
.posts-program .post::after {
  top: 14px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.deco-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 14px;
  background-color: var(--color);
  border-radius: 8px 8px 0 0;
}
@media screen and (max-width: 47.9375em) {
  .deco-border {
    height: 6px;
  }
}

.posts-program .post--inner {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  padding: 2.0833333333vw;
  padding-top: calc(2.5rem + 14px);
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .posts-program .post--inner {
    padding: 2.2857142857rem;
  }
}

.posts-program .post--term {
  position: absolute;
  z-index: 3;
  top: -15px;
  left: 50%;
  font-size: 1.25rem;
  translate: -50% 0;
}
@media screen and (max-width: 47.9375em) {
  .posts-program .post--term {
    font-size: 0.9285714286rem;
  }
}
.posts-program .post--term::before {
  content: "";
  display: block;
  width: 24px;
  margin-inline: auto;
  background-image: url(../images/common/icon-light.svg);
  aspect-ratio: 1;
}

.cat-english .posts-program .post--term {
  top: -25px;
}

.posts-program .post--ttl {
  color: var(--clr-main);
  font-size: 2rem;
  line-height: 1.3125;
}
@media screen and (max-width: 47.9375em) {
  .posts-program .post--ttl {
    font-size: 1.7142857143rem;
  }
}

.posts-program .post--ttl .font-en {
  display: block;
  text-transform: capitalize;
  margin-top: 1em;
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-program .post--ttl .font-en {
    font-size: 0.7142857143rem;
  }
}

.posts-program .post--ttl a {
  color: var(--clr-main);
}

.posts-program .post--txt {
  font-size: 1.0416666667vw;
  line-height: 1.6363636364;
}
@media screen and (max-width: 47.9375em) {
  .posts-program .post--txt {
    font-size: 1.0714285714rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .posts-program .post--txt br {
    display: none;
  }
}
.posts-program .btn {
  margin-top: auto;
}

/* story
**************************************** */
.posts-story {
  gap: 4.9% !important;
}
@media screen and (max-width: 47.9375em) {
  .posts-story {
    gap: 10vw !important;
    width: 85%;
    margin-inline: auto;
  }
}

@media screen and (min-width: 48em) {
  .posts.posts-story > * {
    width: 30%;
    margin-bottom: 3%;
  }

  .home .posts-story .post:nth-child(1) {
    margin-left: 4.9%;
  }
  .home .posts-story .post:nth-child(2) {
    margin-right: 4.9%;
  }
}
.posts-story .post {
  overflow: hidden;
}

.posts-story .post--ttl {
  font-size: 1.25rem;
  line-height: 1.6666666667;
}
@media screen and (max-width: 47.9375em) {
  .posts-story .post--ttl {
    font-size: 1.2857142857rem;
  }
}

.posts-story .post--img {
  position: relative;
  display: block;
  margin-bottom: 24px;
  -webkit-perspective: 500px;
  perspective: 500px;
  transform-style: preserve-3d;
  transition: 1s ease-in-out;
  box-shadow: 0 0 52px rgba(103, 15, 39, 0.15);
  background-color: var(--bg-wht);
}

.before-card,
.after-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.before-card::before,
.after-card::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-size: contain;
}

.before-card::before {
  right: 1em;
  bottom: 1em;
  width: 5.625rem;
  aspect-ratio: 90/41;
  background-image: url(../images/txt-before.svg);
}

.after-card {
  z-index: 1;
}
.after-card::before {
  left: 1em;
  top: 1em;
  width: 4.8125rem;
  aspect-ratio: 77/41;
  background-image: url(../images/txt-after.svg);
}

html:not(.ipad) .posts-story .after-card {
  transform: rotateY(180deg);
}
html:not(.ipad) .posts-story .post.is-active .post--img {
  transform: rotateY(180deg);
}

html.ipad .posts-story .after-card {
  opacity: 0;
  filter: blur(10px);
  transform: scale(1.1);
  transition: var(--transit-default);
}
html.ipad .posts-story .post.is-active .after-card {
  opacity: 1;
  filter: blur(0);
  transform: scale(1);
}

.story_more-btn a {
  max-width: 500px;
  width: min(100%, 500px);
}

/* voice
**************************************** */
.posts-voice .post {
  gap: 3.375rem;
  border: 1px solid rgba(151, 89, 11, 0.1);
  padding: 2.5rem;
  background-color: var(--bg-wht);
}
@media screen and (max-width: 47.9375em) {
  .posts-voice .post {
    gap: 2.2857142857rem;
    padding: 1.4285714286rem;
  }
}
.posts-voice .post:not(:last-child) {
  margin-bottom: 2.5rem;
}

.posts-voice .post--name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1em;
  font-size: 1.125rem;
}
.posts-voice .post--name::before {
  content: "";
  flex: 1;
  height: 1px;
  background-color: rgba(151, 89, 11, 0.1);
  display: block;
}

.posts-voice .post--main {
  position: relative;
  flex-shrink: 0;
  gap: 2rem;
}
@media screen and (min-width: 48em) {
  .posts-voice .post--main {
    width: 30%;
  }
}

.posts-voice .post--img {
  position: relative;
  padding-bottom: 6.25rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-voice .post--img {
    padding-bottom: 5.7142857143rem;
  }
}

.posts-voice .post--img + .btn {
  margin-top: 1.5rem;
}

.posts-voice .post--img .img {
  position: relative;
}
.posts-voice .post--img .img::before {
  position: absolute;
  z-index: 1;
  color: var(--clr-main);
  font-family: var(--font-en);
  line-height: 1;
}

.posts-voice .post:hover .post--img img {
  opacity: 1 !important;
}

.posts-voice .post--img .before {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  width: 40%;
}
.posts-voice .post--img .before::before {
  content: "Before";
  position: relative;
  display: block;
  font-size: 1.25rem;
}
.posts-voice .post--img .before::after {
  content: "";
  position: absolute;
  top: -5rem;
  left: 1.25rem;
  width: 3.75rem;
  background-image: url(../images/b_a_arrow.svg);
  background-repeat: no-repeat;
  aspect-ratio: 161/150;
  transform: rotate(-15deg);
}
@media screen and (max-width: 47.9375em) {
  .posts-voice .post--img .before::after {
    top: -5.7142857143rem;
    width: 4.2857142857rem;
  }
}

.posts-voice .post--img .after {
  margin-left: 6.25rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-voice .post--img .after {
    margin-left: 5.7142857143rem;
  }
}
.posts-voice .post--img .after::before {
  content: "After";
  left: 0.625rem;
  top: 0.625rem;
  font-size: 1.25rem;
}

.posts-voice .post--txtarea {
  flex: 1;
}

.posts-voice .post--txtarea dd:not(:last-child) {
  margin-bottom: 1rem;
}

/* coach
**************************************** */
.posts-coach {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-coach {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.1428571429rem;
  }
}

.posts-coach .post--img {
  overflow: hidden;
  border-radius: 50%;
}

.posts-coach .post--ttl span {
  display: block;
}

/* news
**************************************** */
/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: var(--clr-wht, #fff);
}

/* --- news_list --- */
.news_list .post {
  padding-block: 1.5rem;
  border-bottom: 2px solid #efefef;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post {
    display: block;
  }
}

.news_list .post--date {
  color: var(--clr-blk);
  font-size: 1.125rem;
}

.news_list .post--txt {
  font-size: 0.875rem;
  color: var(--clr-main-sub);
}

@media print, screen and (min-width: 48em) {
  .news_list .post--info {
    margin-right: 2rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .news_list .post--info {
    margin-bottom: 0.5714285714rem;
  }
}

.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}

.news_list .post--link:hover svg {
  fill: var(--clr-main);
}

.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 1rem;
}

/* gallery
**************************************** */
.posts-gallery .post {
  width: 48%;
}
@media screen and (max-width: 47.9375em) {
  .posts-gallery .post {
    width: 100%;
    margin-bottom: 2.9166666667vw;
  }
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 50px;
  padding-bottom: 50px;
}

#search-list .ttl-01 {
  margin: 70px 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- アイキャッチ --- */
.eyecatch {
  gap: 8%;
  align-items: center;
}
@media screen and (max-width: 47.9375em) {
  .eyecatch {
    gap: 1.7142857143rem;
  }
}

.eyecatch--child {
  position: relative;
}

.eyecatch--child .ttl-deco03 {
  position: absolute;
  top: 1.25rem;
  left: -1.25rem;
}
@media screen and (max-width: 47.9375em) {
  .eyecatch--child .ttl-deco03 {
    top: 0.7142857143rem;
    left: -0.7142857143rem;
  }
}

.eyecatch--before {
  width: 30% !important;
}
@media screen and (max-width: 47.9375em) {
  .eyecatch--before {
    width: 80% !important;
  }
}

.eyecatch--after {
  flex: 1;
}

/* --- 何分で読めます --- */
.estimated-reading-time {
  margin-bottom: 5rem !important;
  padding: 1em;
  font-size: 1.375rem;
  font-family: var(--font-jp);
  text-align: center;
  border-top: 14px solid;
  border-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  background-color: #f2f2f2;
  line-height: var(--line-height-none);
}
@media screen and (max-width: 47.9375em) {
  .estimated-reading-time {
    border-top: 6px solid;
    font-size: 1.1428571429rem;
  }
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

#ez-toc-container {
  position: relative;
  margin-block: 8.125rem;
  padding: 2rem;
  background-color: #faf9f3;
  border: 1px solid #f4eee6;
}
@media screen and (min-width: 48em) {
  #ez-toc-container {
    display: grid;
    grid-template-columns: 11.9791666667vw 1fr;
  }
}
@media screen and (max-width: 47.9375em) {
  #ez-toc-container {
    margin-block: 6.4285714286rem;
    padding: 1.4285714286rem;
  }
}
#ez-toc-container::before, #ez-toc-container::after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  width: 13.0625rem;
  background-image: url(../images/common/ttl-01-deco.svg);
  background-size: 100%;
  aspect-ratio: 209/12;
  translate: -50% 0;
}
@media screen and (max-width: 47.9375em) {
  #ez-toc-container::before, #ez-toc-container::after {
    width: 11.5714285714rem;
  }
}
#ez-toc-container::before {
  top: -2.8125rem;
}
@media screen and (max-width: 47.9375em) {
  #ez-toc-container::before {
    top: -3.2142857143rem;
  }
}
#ez-toc-container::after {
  bottom: -2.8125rem;
  transform: scale(1, -1);
}
@media screen and (max-width: 47.9375em) {
  #ez-toc-container::after {
    bottom: -3.2142857143rem;
  }
}

#ez-toc-container p.ez-toc-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--clr-main);
  font-size: 2rem;
  font-family: var(--font-jp);
  font-weight: bold;
  letter-spacing: 0.075em;
}
@media screen and (min-width: 48em) {
  #ez-toc-container p.ez-toc-title {
    flex-direction: column;
  }
}
@media screen and (max-width: 47.9375em) {
  #ez-toc-container p.ez-toc-title {
    font-size: 1.2142857143rem;
    flex-direction: column;
    gap: 2px;
  }
}
#ez-toc-container p.ez-toc-title::before, #ez-toc-container p.ez-toc-title::after {
  content: "";
  display: block;
  width: 74px;
  background-image: url(../images/common/ttl-deco04.svg);
  background-size: 100%;
  aspect-ratio: 74/15;
}
#ez-toc-container p.ez-toc-title::after {
  transform: scale(1, -1);
}

.ez-toc-list {
  counter-reset: number;
}

.ez-toc-list li:not(:last-child) {
  border-bottom: 1px solid #f0e9db;
}
.ez-toc-list li:nth-of-type(9) ~ li a::before {
  content: counter(number);
}

.ez-toc-list a {
  position: relative;
  display: block;
  padding-left: 3.75rem;
  padding-block: 0.5rem;
  text-decoration: none;
  line-height: 1.5;
}
@media screen and (max-width: 47.9375em) {
  .ez-toc-list a {
    font-size: 1rem;
  }
}
.ez-toc-list a::before {
  counter-increment: number;
  content: "0" counter(number);
  position: absolute;
  left: 1rem;
  top: 0.625rem;
  color: #9b7621;
  font-size: 1.125rem;
  font-family: var(--font-num);
  line-height: var(--line-height-none);
}
@media screen and (max-width: 47.9375em) {
  .ez-toc-list a::before {
    font-size: 1.1428571429rem;
  }
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 0.3125rem;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 0.25rem 0.375rem;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* article
**************************************** */
.sticky {
  position: sticky;
  top: 140px;
}

.article--info .article--ttl {
  padding-bottom: 1em;
  color: var(--clr-body);
  text-align: justify;
  border-bottom: 4px double #c8bfa0;
}

.main_column--txt {
  flex: 1;
}

.main_column--fix {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 40px;
  margin-right: 77px;
}
@media screen and (max-width: 47.9375em) {
  .main_column--fix {
    display: none;
  }
}

.article--footer {
  padding-top: 2.5rem;
  border-top: 1px solid #eae6eb;
}

.movie--wrap {
  position: relative;
  cursor: pointer;
}

.movie::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  translate: -50% -50%;
  display: block;
  width: 4.5625rem;
  background-image: url(../images/common/icon-youtube-color.svg);
  aspect-ratio: 213/150;
}

.movie--wrap iframe {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

/* favorite
**************************************** */
.simplefavorite-button {
  outline: none;
  display: inline-block;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.simplefavorite-button.active .icon-heart::before {
  background-image: url(../images/common/icon-heart-fill.svg);
}

.btn-bookmark .simplefavorite-button {
  width: min(100%, 380px);
}

.btn-bookmark .simplefavorite-button i {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  color: var(--clr-wht);
  padding: 1.2em;
  font-size: 1.125rem;
  font-family: var(--font-jp);
  letter-spacing: 0.08em;
  border: 3px solid;
  background-color: var(--bg-red);
  background-image: linear-gradient(-35deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  border-radius: 100px;
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
  aspect-ratio: auto;
}
@media screen and (max-width: 47.9375em) {
  .btn-bookmark .simplefavorite-button i {
    padding: 1em;
    border: 2px solid;
  }
}
.btn-bookmark .simplefavorite-button i.icon-heart::before {
  background-image: url(../images/common/icon-heart-wht.svg);
}
.btn-bookmark .simplefavorite-button i::after {
  content: "この記事をブックマーク";
}

.btn-bookmark .simplefavorite-button.active i {
  color: var(--clr-sub);
  border-color: var(--bg-red);
  background-color: #eee;
  background-image: none;
  box-shadow: 0 0 0 transparent;
}
.btn-bookmark .simplefavorite-button.active i.icon-heart::before {
  background-image: url(../images/common/icon-heart-fill.svg);
}
.btn-bookmark .simplefavorite-button.active i::after {
  content: "ブックマークしました";
}

.btn-bookmark-mark {
  position: relative;
  z-index: 2;
  display: grid;
  place-items: center;
}
.btn-bookmark-mark.sm .icon-heart {
  display: inline-block;
  width: 27px;
}
.btn-bookmark-mark.sm .icon-heart::before {
  width: 100%;
}

.txt-bookmark .simplefavorite-button {
  width: min(100%, 380px);
}

.txt-bookmark .simplefavorite-button i {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  color: var(--bg-red);
  padding: 1.2em;
  font-size: 1.125rem;
  font-family: var(--font-jp);
  letter-spacing: 0.08em;
  aspect-ratio: auto;
}
@media screen and (max-width: 47.9375em) {
  .txt-bookmark .simplefavorite-button i {
    padding: 1em;
  }
}
.txt-bookmark .simplefavorite-button i::after {
  content: "この記事をブックマーク";
}

.txt-bookmark .simplefavorite-button.active i.icon-heart::before {
  background-image: url(../images/common/icon-heart-fill.svg);
}
.txt-bookmark .simplefavorite-button.active i::after {
  content: "ブックマークしました";
}

.txt-bookmark-mark {
  position: relative;
  z-index: 2;
  display: grid;
  place-items: center;
}
.txt-bookmark-mark.sm .icon-heart {
  display: inline-block;
  width: 27px;
}
.txt-bookmark-mark.sm .icon-heart::before {
  width: 100%;
}

/* coach
**************************************** */
.coach--info {
  padding: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .coach--info {
    padding: 1.7142857143rem;
  }
}

.coach--info .eyecatch {
  width: 80%;
  max-width: 200px;
  margin-inline: auto;
}

.coach--info table,
.coach--info iframe {
  width: 100%;
}

.coach--info iframe {
  height: auto;
  aspect-ratio: 16/9;
}

.coach--info .movie_box {
  gap: 1.5rem;
}

.coach--info .movie_box li {
  flex: 1;
}

/* news
**************************************** */
.news--info .post--txt {
  font-size: 0.9375rem;
}

/* gallery
**************************************** */
.gallery--btn {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.gallery--btn a {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  padding: 1em 2.5rem;
  color: var(--clr-main-sub);
  font-weight: bold;
  font-size: 1.25rem;
  font-family: var(--font-jp);
  text-align: center;
  line-height: 1.4;
  gap: 1rem;
  letter-spacing: 0.17em;
  transition: all 0.4s ease-out;
  transition: var(--transit-default);
  text-decoration: none;
  border-radius: 0.75rem;
  background-color: #f2dee1;
}
.gallery--btn a::after {
  transform: rotate(90deg);
  content: "";
  position: absolute;
  right: 1.875rem;
  display: block;
  width: 9px;
  height: 19px;
  background: url(/wp/wp-content/themes/standard_sass/images/common/icon-arrow.svg) no-repeat;
  background-size: cover;
  transition: all 0.4s ease-out;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .gallery--btn a::after {
    width: 6px;
    height: auto;
    aspect-ratio: 6/12;
  }
}
@media screen and (max-width: 47.9375em) {
  .gallery--btn a {
    max-width: 12.5rem;
    padding: 0.6em 2rem;
    font-size: 1.1428571429rem;
  }
}

.single-gallery .page_ttl-none {
  margin-bottom: 2.0833333333vw;
}

.gallery {
  padding: 4px;
  margin-bottom: 49px;
}
.gallery h2 {
  padding-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .gallery h2 {
    padding-top: 1.5rem;
  }
}

.gallery--movie {
  padding: 8px;
}
.gallery--movie a {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
}
.gallery--movie a::before {
  content: "";
  position: absolute;
  top: calc(50% - 26px);
  left: calc(50% - 35px);
  z-index: 1;
  display: block;
  width: 73px;
  height: 52px;
  background-image: url(../images/common/icon-youtube-color.svg);
}
@media screen and (max-width: 47.9375em) {
  .gallery--movie a::before {
    top: calc(50% - 18.25px);
    left: calc(50% - 18.25px);
    width: 36.5px;
    height: 26px;
  }
}
.gallery--movie img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}
@media screen and (max-width: 47.9375em) {
  .gallery--movie {
    width: 50%;
    padding: 4px;
    border-radius: 2px;
  }
}

.gallery--img {
  box-sizing: border-box;
  padding: 8px;
  font-size: 2rem;
  background-color: white;
  border-radius: 6px;
  transition: 0.5s;
}
.gallery--img img {
  width: 100%;
  height: 20.8333333333vw;
  object-fit: cover;
  border-radius: 4px;
}
@media screen and (max-width: 47.9375em) {
  .gallery--img img {
    height: 36.4583333333vw;
  }
}
.gallery--img:hover {
  opacity: 0.6;
}
@media screen and (max-width: 47.9375em) {
  .gallery--img {
    width: 50%;
    padding: 4px;
    border-radius: 2px;
  }
}

.gallery--img.is-hidden,
.gallery--movie.is-hidden {
  opacity: 0;
  height: 0;
  padding: 0;
  pointer-events: none;
  transition: 0.4s;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .gallery {
    margin-bottom: 0;
  }

  .single-gallery .btn {
    width: 80%;
    margin-inline: auto;
    margin-bottom: 3.3333333333vw;
  }
}
/* share
**************************************** */
.share_list li.twitter a {
  color: #2ba1f2;
}
.share_list li.facebook a {
  color: #2477f2;
}
.share_list li.line a {
  color: #27c754;
}

.share_list a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5625rem;
  padding: 0.7em 0.5em;
}

.share_box-horizon .share_list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, min(100%, 190px));
  gap: 0.375rem;
  padding: 1.8125rem;
  background-color: var(--bg-off_wht2);
}
@media screen and (max-width: 47.9375em) {
  .share_box-horizon .share_list {
    grid-template-columns: repeat(3, 1fr);
    padding: 1.1428571429rem;
  }
}

.share_box-horizon .share_list a {
  height: 100%;
  font-size: 0.9375rem;
  font-family: var(--font-secondary);
  font-weight: bold;
  line-height: 1;
  border-radius: 4px;
  background-color: var(--bg-wht);
}
@media screen and (max-width: 47.9375em) {
  .share_box-horizon .share_list a {
    font-size: 0.7142857143rem;
  }
}
.share_box-horizon .share_list a:hover {
  opacity: 0.5;
}

.share_box-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}
.share_box-vertical::before {
  content: "SHARE";
  display: block;
  color: var(--clr-main-sub);
  font-size: 0.8125rem;
  font-family: var(--font-en);
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-font-feature-settings: "pkna";
  font-feature-settings: "pkna";
}

.share_box-vertical .share_list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-block: 0.25rem;
  border-radius: 100px;
}

.share_box-vertical .share_list a {
  width: 2.5rem;
  height: 2.5rem;
  border: none;
}

/* posts--related
**************************************** */
.posts--related .editor-ttl {
  padding-bottom: 0.5em;
  border-bottom: 0.1875rem solid var(--clr-main);
}

.posts--related .more {
  display: inline-flex;
  gap: 8px;
  margin-left: auto;
}
@media screen and (min-width: 48em) {
  .posts--related .more {
    position: absolute;
    right: 0;
    top: 1em;
  }
}

.single-article .posts--related .editor-ttl {
  margin-bottom: 0;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  font-family: var(--font-jp);
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi {
    margin-top: 24px;
  }
}

.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0.5em 1em;
  background-color: var(--btn-bg-main);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--clr-wht, #fff);
  border-radius: 4px;
}
.wp-pagenavi a:hover {
  background-color: var(--btn-bg-main-hvr);
}

.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .archive .wp-pagenavi > *:not(.pages) {
    width: 2rem;
    height: 2rem;
  }
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  width: 40px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
    width: 32px;
  }
}

.wp-pagenavi > *:not(.pages) {
  margin-bottom: 4px;
}

.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 4px;
}

.wp-pagenavi .extend {
  width: 24px !important;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 8px;
  height: 8px;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -6px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi .first::before,
.wp-pagenavi .last::before {
    left: -5px;
  }
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -6px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi .first::after,
.wp-pagenavi .last::after {
    right: -5px;
  }
}

/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
}
@media print, screen and (min-width: 48em) {
  .sitemap--menu:not(:last-child) {
    margin-right: 7.4074074074%;
  }
}
@media screen and (max-width: 47.9375em) {
  .sitemap--menu:not(:last-child) {
    margin-bottom: 24px;
  }
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
}

.sitemap--menu a:hover {
  color: var(--clr-main, #1d7fb7);
}

.sitemap--menu > li > a {
  font-size: 1.125rem;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 0.5em;
  margin-bottom: 1.25em;
}

.sitemap_sub_nav--wrap {
  margin-bottom: 1.5em;
}

.sitemap_sub_nav {
  column-count: 2;
  page-break-inside: avoid;
  break-inside: avoid;
}

.sitemap_sub_nav a {
  display: flex;
  gap: 0.5rem;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child {
  margin-left: auto;
}

.lps_parts--button .btn:last-child {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--button.flex {
    margin-left: auto;
    margin-right: auto;
  }

  .lps_parts--button .btn:only-child {
    width: fit-content;
    min-width: min(420px, 100%);
  }

  .lps_parts--column.column-2 + .lps_parts--button,
.lps_parts--column.column-3 + .lps_parts--button,
.lps_parts--column.column-4 + .lps_parts--button {
    margin-top: 0;
  }
}
/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
}

.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}

.lps_parts--column:not(.column-1) .text {
  height: 100%;
}

.lps_parts--column .img {
  flex-shrink: 0;
}

.lps_parts--column .img + .text {
  padding-top: 24px;
}

.lps_parts--column .bg-wh .text {
  padding: 6%;
}

.lps_parts--column:not(.column-1) table {
  width: 100%;
}

.lps_parts--column.column-1 .box {
  padding: 43px 48px;
}

.lps_parts--column .column_item.bg-wh {
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
.lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }

  .lps_parts--column .lps_parts--child {
    display: block;
  }

  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }

  .lps_parts--column .column_item {
    width: 100%;
  }

  .lps_parts--column .column_item:not(:last-child) {
    margin-bottom: 40px;
  }

  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
.lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
.lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }

  .lps_parts--column .text.bg-wh,
.lps_parts--column.column-1 .box {
    padding: 24px;
  }
}
/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10;
  padding: 16px;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item {
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
}

.lps_parts--faq .faq_item:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .faq_item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.lps_parts--faq .editor-ttl2 {
  margin-bottom: 16px;
}

.lps_parts--faq .ttl-03,
.lps_parts--faq .text {
  padding: 24px 32px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03,
.lps_parts--faq .text {
    padding: 16px 24px;
  }
}
.lps_parts--faq .ttl-03::after,
.lps_parts--faq .text::after {
  position: absolute;
  left: 48px;
  display: block;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 600;
  font-style: italic;
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03::after,
.lps_parts--faq .text::after {
    left: 20px;
    font-size: 1.8571428571rem;
  }
}

.lps_parts--faq .ttl-03 {
  margin-bottom: 0 !important;
  color: var(--clr-wht, #fff);
  font-size: 1.25rem;
  font-family: var(--font-yu-cothic);
  -webkit-background-clip: unset;
  background-image: linear-gradient(90deg, #1f1828, #cf436b, #dbddd7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03 {
    font-size: 1.4285714286rem;
  }
}

.lps_parts--faq .text {
  position: relative;
  background-color: var(--bg-wht, #fff);
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .text {
    display: flex;
    gap: 5%;
  }
}
.lps_parts--faq .text p {
  letter-spacing: 0.04em;
  line-height: 2;
  color: var(--clr-blk, #000);
}

@media print, screen and (min-width: 48em) {
  .lps_parts--faq .img {
    width: 30%;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--faq .mce-content-body {
    margin-bottom: 0 !important;
    flex: 1;
  }
}

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 86px;
  counter-increment: number;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 48px;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 20px;
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 64px;
  left: 36px;
  display: block;
  width: 0;
  height: calc(100% - 80px);
  border-right: 3px solid var(--bg-sub, #8a6114);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    top: 36px;
    left: 18px;
    height: calc(100% - 48px);
  }
}

.lps_parts--flow .ttl-03 {
  font-size: 2rem;
  letter-spacing: var(--ltr-space-default);
  color: var(--clr-main, #875e0e);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03 {
    font-size: 1.4285714286rem;
  }
}
.lps_parts--flow .ttl-03::after {
  content: counter(number);
  position: absolute;
  top: -8px;
  left: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  text-align: center;
  color: var(--clr-wht, #fff);
  background-color: var(--bg-sub, #8a6114);
  font-family: var(--font-num, "Montserrat", sans-serif);
  font-size: 2.25rem;
  letter-spacing: 0;
  padding-bottom: 8px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03::after {
    top: -6px;
    left: -48px;
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1.2857142857rem;
  }
}

.lps_parts--flow.type-circle .flow_item:not(:last-child)::before {
  top: 37px;
  height: calc(100% - 44px);
}

.lps_parts--flow.type-circle .ttl-03 {
  font-size: 1.5rem;
}
.lps_parts--flow.type-circle .ttl-03::after {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  top: 3px;
  left: -60px;
  background-color: var(--bg-wht);
  border: 3px solid var(--bg-sub);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.type-circle .ttl-03::after {
    left: -39px;
  }
}

.lps_parts--flow .flow_item p {
  color: var(--clr-blk, #000);
  letter-spacing: 0.075em;
  line-height: 1.875;
}

.lps_parts--flow .txt-wh .ttl-03 {
  color: var(--clr-wht, #fff);
}
.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main, #875e0e);
  background-color: var(--bg-wht, #fff);
}

.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: var(--clr-wht, #fff);
}

@media print, screen and (min-width: 48em) {
  .lps_parts--flow .text {
    display: flex;
    gap: 5%;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--flow .img {
    width: 35%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .img:not(:last-child) {
    margin-bottom: 24px;
  }
}

.lps_parts--flow .mce-content-body {
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .mce-content-body {
    flex: 1;
  }
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.lps_sec {
  position: relative;
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  z-index: 0;
  height: 100%;
  overflow: hidden;
  mix-blend-mode: multiply;
}

.lps_sec-bg .bg {
  position: absolute;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
}

.bg-circle {
  position: absolute;
  width: 45.78125vw;
  height: auto;
  aspect-ratio: 879/882;
  animation: 100s rotate linear infinite;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .bg-circle {
    width: 63.768115942vw;
  }
}

.bg-circle svg {
  width: 100%;
  height: 100%;
}

.bg-lft {
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 50%;
  height: 100%;
  pointer-events: none;
}

.bg-lft .bg-circle {
  left: -36.5625vw;
}

.bg-rgt {
  position: absolute;
  right: 0;
  overflow: hidden;
  width: 50%;
  height: 100%;
  pointer-events: none;
}

.bg-rgt .bg-circle {
  right: -36.5625vw;
  top: 10.9375vw;
}

.lps_sec-bg-lft {
  width: 25.9375vw;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec-bg-lft {
    width: 46.3768115942vw;
  }
}

.lps_sec-bg-lft .bg {
  left: 0;
  background-image: url(../images/common/bg-lft.jpg);
  aspect-ratio: 498/1010;
}

.lps_sec-bg-lft .bg:first-child {
  top: -10.4166666667vw;
}

.lps_sec-bg-lft .bg:nth-child(2) {
  bottom: min(10%, 1000px);
}

.lps_sec-bg-lft .bg-circle {
  left: -36.5625vw;
  top: 15.1041666667vw;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec-bg-lft .bg-circle {
    left: -47.1014492754vw;
  }
}

.lps_sec-bg-rgt {
  width: 25.2083333333vw;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec-bg-rgt {
    width: 48.7922705314vw;
  }
}

.lps_sec-bg-rgt .bg {
  right: 0;
  background-image: url(../images/common/bg-rgt.jpg);
  aspect-ratio: 484/1010;
}

.lps_sec-bg-rgt .bg:first-child {
  top: 31.25vw;
}

.lps_sec-bg-rgt .bg:nth-child(2) {
  bottom: min(5%, 200px);
}

.lps_sec-bg-rgt .bg-circle {
  right: -36.5625vw;
  top: 10.9375vw;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec-bg-rgt .bg-circle {
    right: -52.1739130435vw;
  }
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}

.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
    margin-top: 40px;
  }
}

.lps_parts--img_text .lps_parts:has(.bg-wh) + .lps_parts:has(.bg-wh) {
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .lps_parts:has(.bg-wh) + .lps_parts:has(.bg-wh) {
    margin-top: 24px;
  }
}

.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
    margin-top: 16px;
  }
}

.lps_parts--button + .lps_parts--button {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--button + .lps_parts--button {
    margin-top: 8px;
  }
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}

.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }

  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
.lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
.lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-wh .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .lps_parts--child:not(.bg-wh) {
  gap: 3%;
  align-items: flex-start;
}

.lps_parts--img_text .img_text--img {
  width: 31.33%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .img_text--img {
    width: 100%;
    margin-bottom: 24px;
  }
}

.lps_parts--img_text .img_text--txt {
  width: 65.66%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .img_text--txt {
    width: 100%;
  }
}

.lps_parts--img_text .img_text--child .img {
  height: 100%;
}

.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 24px;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--img {
    margin-bottom: 0;
  }
}

.lps_parts--img_text .inner.bg-wh:not(.inner-lg) {
  box-shadow: 0 0 40px rgba(103, 15, 38, 0.15);
}

.lps_parts--img_text .img_text--txt p {
  letter-spacing: 0.1em;
  color: var(--clr-blk, #000);
}

.lps_parts--img_text .ttl-03 {
  font-size: 2rem;
  letter-spacing: var(--ltr-space-default);
  line-height: 1.4375;
  color: var(--clr-main, #875e0e);
}

/* --- L --- */
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    min-height: 607.64px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 6%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }

  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .btn {
    text-align: center;
  }

  .lps_parts--img_text .bg-wh + .bg-wh {
    margin-top: 0;
  }

  .lps_parts--img_text .inner-lg .img_text--child .img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide .slick-list {
  margin-bottom: 32px;
}

.lps_parts--slide .img img {
  display: inline-block;
}

.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .img + .text {
    margin-top: 16px;
  }
}

.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow {
    width: 56px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 40px;
  }
}
.lps_parts--slide .slick-arrow::after {
  content: "";
  background-image: url(../images/common/icon-arrow.svg);
  background-size: cover;
  display: block;
  width: 1.5rem;
  height: 3rem;
}

.lps_parts--slide .slick-prev {
  left: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}
.lps_parts--slide .slick-prev::after {
  content: "";
  transform: scale(-1, 1);
}

.lps_parts--slide .slick-next {
  right: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}

.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 4px;
  }
}

.lps_parts--slide .slick-dots li {
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--clr-main);
  opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  margin: 6px;
}
.lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--clr-main);
  opacity: 1;
}
.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}

.lps_parts--slide .slide-carousel .slick-list {
  margin-inline: -1rem;
}
.lps_parts--slide .slide-carousel .slick-slide {
  margin-inline: 1rem;
}
.lps_parts--slide .slide-carousel .slick-prev {
  left: -70px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-carousel .slick-prev {
    left: 0;
  }
}
.lps_parts--slide .slide-carousel .slick-next {
  right: -70px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-carousel .slick-next {
    right: 0;
  }
}

.lps_parts--slide .slide-extra_large {
  margin-inline: calc(50% - 50vw);
}
.lps_parts--slide .slide-extra_large .slick-slide {
  position: relative;
}
.lps_parts--slide .slide-extra_large .img {
  width: 100%;
  height: 800px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-extra_large .img {
    height: 400px;
  }
}
.lps_parts--slide .slide-extra_large .text {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: auto;
  margin: auto;
}
.lps_parts--slide .slide-extra_large .ttl-03,
.lps_parts--slide .slide-extra_large .mce-content-body {
  color: var(--clr-wht);
}
.lps_parts--slide .slide-extra_large .slick-list {
  margin-bottom: 0;
}
.lps_parts--slide .slide-extra_large .slick-arrow::after {
  background-image: url(../images/common/icon-arrow-wh.svg);
}
.lps_parts--slide .slide-extra_large .slick-prev {
  left: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-extra_large .slick-prev {
    left: 0;
  }
}
.lps_parts--slide .slide-extra_large .slick-next {
  right: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-extra_large .slick-next {
    right: 0;
  }
}
.lps_parts--slide .slide-extra_large .slick-dots {
  position: absolute;
  bottom: 70px;
}
.lps_parts--slide .slide-extra_large .slick-dots li {
  background-color: var(--bg-wht);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: var(--bg-sub);
  opacity: 0.9;
  filter: alpha(opacity=90);
}

#colorbox {
  outline: 0;
}

#cboxContent {
  overflow: visible;
  background: var(--bg-wht);
}
@media screen and (min-width: 48em) {
  #cboxContent {
    margin-right: 40px;
  }
}
@media screen and (max-width: 47.9375em) {
  #cboxContent {
    margin-top: 40px;
  }
}

.cboxIframe {
  background: #fff;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxLoadedContent {
  background: var(--bg-wht);
  padding: 1px;
}

#cboxLoadingOverlay {
  background: var(--bg-wht);
}

#cboxTitle {
  position: absolute;
  top: -22px;
  left: 0;
  color: #000;
}

#cboxCurrent {
  position: absolute;
  top: -22px;
  right: 205px;
  text-indent: -9999px;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  text-indent: -9999px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -20px;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxPrevious {
  background-position: 0px 0px;
  right: 44px;
}

#cboxPrevious:hover {
  background-position: 0px -25px;
}

#cboxNext {
  background-position: -25px 0px;
  right: 22px;
}

#cboxNext:hover {
  background-position: -25px -25px;
}

#cboxClose {
  width: 32px;
  height: 32px;
  color: transparent;
  background: none;
  top: 0;
}
@media screen and (min-width: 48em) {
  #cboxClose {
    right: -40px;
  }
}
@media screen and (max-width: 47.9375em) {
  #cboxClose {
    right: 0;
    top: -40px;
  }
}
#cboxClose::before, #cboxClose::after {
  content: "";
  display: block;
  width: 32px;
  height: 2px;
  background: #fff;
}
#cboxClose::before {
  transform: rotate(45deg);
  translate: -4px 8px;
}
#cboxClose::after {
  transform: rotate(135deg);
  translate: -4px -9px;
}

#cboxClose:hover {
  background-position: -50px -25px;
}

.cboxSlideshow_on #cboxPrevious,
.cboxSlideshow_off #cboxPrevious {
  right: 66px;
}

.cboxSlideshow_on #cboxSlideshow {
  background-position: -75px -25px;
  right: 44px;
}

.cboxSlideshow_on #cboxSlideshow:hover {
  background-position: -100px -25px;
}

.cboxSlideshow_off #cboxSlideshow {
  background-position: -100px 0px;
  right: 44px;
}

.cboxSlideshow_off #cboxSlideshow:hover {
  background-position: -75px -25px;
}

/* gallery
**************************************** */
.home #cboxOverlay,
.single-gallery #cboxOverlay {
  background: var(--bg-blk);
}

.home #cboxPrevious,
.home #cboxNext,
.single-gallery #cboxPrevious,
.single-gallery #cboxNext {
  opacity: 0;
  pointer-events: none;
}