@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;

// 色
$clr-main: #875e0e;

// CSS変数
:root {
  // typography
  --ltr-space-default: 0.07em;
  --ltr-space-hdr: 0;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";
  --font-secondary: "こぶりなゴシック W6 JIS2004", "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";
  // --font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
  --font-jp: "游明朝体36ポかな D", "游明朝体 Pr6N D", serif;
  --font-en: "Cormorant Garamond", serif;
  --font-num: "Cormorant", serif;
  // --font-noto-sans: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo",
  // "游ゴシック", "Yu Gothic", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  --font-yu-cothic: "游ゴシック", "Yu Gothic";

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: 0.5rem;

  // text colors
  --clr-main: #875e0e;
  --clr-main-sub: #9b7621;
  --clr-sub: #c4212b;
  --clr-list: #c8192d;
  --clr-body: #000;
  --clr-page-ttl: #b2790a;
  --clr-link: #333;
  --clr-wht: #fff;
  --clr-blk: #000;
  --clr-pink: #b7345e;

  // background colors
  --bg-main: #f6f5f1;
  --bg-sub: #8a6114;
  --bg-wht: #fff;
  --bg-red: #c8192d;
  --bg-off_wht: #f8f8f8;
  --bg-off_wht2: #f6f2ef;
  --bg-off_wht2: #f6f2ef;
  --bg-blk: #000;

  //button colors
  --btn-bg-main: var(--clr-main);
  --btn-bg-main-hvr: var(--clr-dark-blue);
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;

  --btn-clr-wht: #c8192d;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: var(--clr-dark-blue);

  // border colors
  --brd-main: #eadece;

  // animation
  --transit-default: all 0.4s ease-out;

  // formy settings
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  "blue": (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  "red": (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  "green": (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  ),
);

$aspect-ratios: (
  widescreen: "3/2",
  widescreen-2: "16/9",
  square: "1/1",
  portrait: "2/3",
);
