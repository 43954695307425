@use "../abstracts" as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body {
  text-align: justify;
}
.mce-content-body ol {
  list-style: decimal;
}
.mce-content-body ul:where(:not(.ez-toc-list)),
.mce-content-body ol {
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mce-content-body ul:where(:not(.ez-toc-list)) li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2.2em;
  padding-top: 0.5em;
  @include lh(16, 32);
  color: var(--bg-blk, #000);
}
.mce-content-body ol li {
  list-style-type: decimal-leading-zero;
  &::marker {
    font-family: var(--font-num);
    color: var(--clr-list, #c8192d);
    font-size: rem(24);
  }
}
.mce-content-body ul:where(:not(.ez-toc-list)) li {
  list-style: none;
  &::before {
    content: "";
    position: absolute;
    top: 1.2em;
    left: -1.5em;
    @include rect(10);
    background-color: var(--clr-list, #c8192d);
  }
}
.mce-content-body .check_list li {
  margin-left: 2.2em;
  padding-top: 0.5em;
  list-style: none;
  &::before {
    content: "";
    position: absolute;
    top: 0.8em;
    left: -2.2em;
    @include rect(24);
    background-image: linear-gradient(-35deg, #1f1828, #cf436b, #dbddd7);
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: rem(21);
    left: rem(-32);
    width: 9px;
    height: 6px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
  }
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--bg-wht, #fff);
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.mce-content-body hr {
  width: 100%;
  border-top: 4px double #c8bfa0;
  border: none;
  @include mq(sp) {
    height: sprem(16);
  }
}
.box {
  padding: 24px 32px;
  background-color: rgb(206 193 141 / 10%);
  border: 1px solid rgb(151 89 11 / 10%);
  @include lh(16, 32);
  letter-spacing: 0.1em;
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}
.box-wh {
  background-color: var(--bg-wht, #fff);
  border: 0;
  border: 1px solid rgba(151, 89, 11, 0.1);
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.mce-content-body img.aligncenter {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
  @include lh(16, 32);
  letter-spacing: 0.1em;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: var(--bg-off_wht);
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 1em;
    height: 1em;
    opacity: 0.25;
  }
  &::before {
    background-image: url("../images/common/icon-quote_left.svg");
    top: 13px;
    left: 10px;
  }
  &::after {
    background-image: url("../images/common/icon-quote_right.svg");
    bottom: 13px;
    right: 10px;
  }
}
.mce-content-body blockquote p {
  margin-bottom: 0;
}
.mce-content-body a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.mce-content-body table {
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
table {
  border: none;
  height: auto !important;
}
table th,
table td {
  padding: 1em;
  border: 1px solid rgb(151 89 11 / 20%);
  vertical-align: top;
}
table th {
  min-width: 120px;
  text-align: left;
  font-family: var(--font-secondary);
}
.mce-content-body sup {
  font-size: rem(12);
  color: #555;
}
.bold {
  font-weight: bold;
  color: var(--clr-sub, #c4212b);
}
.mce-content-body .bold {
  font-family: var(--font-secondary);
  color: var(--clr-body);
}
.mce-content-body .font-jp .bold {
  font-family: "游明朝体36ポかな B", "游明朝体 StdN B", serif;
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}
.single-story .mce-content-body iframe {
  display: block;
  max-width: 700px;
  margin-inline: auto;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}
.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}
.scroll table {
  margin: 0;
}
.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: 0.25;
  font-size: rem(14);
}

@include mq(sp) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
  }
  .mce-content-body ul li::before {
    @include rect(8);
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .box {
    padding: 24px;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}
.mce-content-body.wp-editor {
  padding: 24px !important;
}

@include mq(sp) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(
    --font-jp,
    --font-primary,
    "Yu Gothic",
    "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN",
    sans-serif
  );
  color: transparent;
  background: linear-gradient(138deg, rgba(138, 97, 20, 1) 0%, rgba(153, 118, 44, 1) 50%, rgba(138, 97, 20, 1) 100%);
  -webkit-background-clip: text;
  letter-spacing: var(--ltr-space-default);
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.editor-ttl {
  font-size: rem(26);
  @include lh(40, 54);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.editor-ttl2 {
  font-size: rem(24);
  @include lh(32, 46);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.editor-ttl3 {
  font-size: rem(20);
  @include lh(24, 36);
  @include mq(sp) {
    font-size: sprem(16);
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: rem(14);
  color: #999;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: "※";
  }
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  font-size: rem(15);
  padding: 1em 1.5em;
  letter-spacing: 0.1em;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 3px);
  }
}
.table1 th {
  color: var(--clr-main, #875e0e);
  &::before {
    left: 0;
    height: 2px;
    background-color: var(--clr-main, #875e0e);
  }
}
.table1 td {
  color: var(--clr-blk, #000);
  &::before {
    right: 0;
    height: 1px;
    background-color: rgb(151 89 11 / 20%);
  }
}
.table2 th,
.table2 td {
  color: var(--clr-blk, #000);
}
.table2 {
  th {
    background-color: rgb(206 193 141 / 10%);
  }
  td {
    background-color: var(--bg-wht, #fff);
  }
}

@include mq(sp) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #d3d3d3;
  }
}

/* タイトル装飾 */
// .ttl-deco01
.ttl-deco01--txt {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  color: var(--clr-page-ttl, #b2790a);
  font-weight: bold;
  font-size: rem(18);
  letter-spacing: 0.05em;
  &::before {
    content: "";
    flex-shrink: 0;
    width: rem(24);
    background-image: url(../images/common/icon-light.svg);
    background-size: contain;
    aspect-ratio: 1;
    @include mq(sp) {
      width: sprem(16);
    }
  }
}
// .ttl-deco03
.ttl-deco03--txt {
  display: inline-block;
  font-weight: bold;
  letter-spacing: 0.12em;
  color: var(--clr-wht, #fff);
  padding: rem(10) rem(20);
  line-height: var(--line-height-none) !important;
  background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
}
.ttl-deco03--txt-2 {
  background: var(--clr-main);
}

// .ttl-deco04
.ttl-deco04--cont {
  display: inline-block;
  width: fit-content;
  text-align: center;
  font-size: rem(32);
  letter-spacing: 0 !important;
  color: #986708;
  padding-top: 8px;
  &::before {
    content: "";
    display: block;
    width: 74px;
    height: 15px;
    margin-bottom: -15px;
    margin-inline: auto;
    background: url(../images/common/ttl-deco04.svg) no-repeat;
    background-size: 100%;
  }
}
// .ttl-deco05
.ttl-deco05--cont {
  display: inline-block;
  width: fit-content;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 80%, 0 100%);
  background-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  padding: 16px 26px 36px;
}
.ttl-deco05--txt {
  display: block;
  font-size: rem(12);
  letter-spacing: 0.12em !important;
  color: var(--clr-wht, #fff);
  margin-bottom: 0 !important;
  line-height: 1 !important;
  font-weight: bold;
}
.ttl-deco05--num {
  font-size: rem(40);
  letter-spacing: 0;
  color: var(--clr-wht, #fff);
}
// .ttl-deco06
.ttl-deco06 {
  // display: inline-flex;
  // align-items: center;
}
.ttl-deco06--txt {
  font-size: rem(16);
  letter-spacing: 0.12em !important;
  color: var(--clr-list, #c8192d);
  margin-bottom: 0 !important;
  position: relative;
  line-height: 1 !important;
  font-weight: bold;
  &::before {
    content: "";
    position: absolute;
    content: "";
    top: 0;
    right: -10px;
    width: 2px;
    height: 18px;
    background-color: var(--clr-list, #c8192d);
    transform: rotate(40deg);
    border-radius: 5px;
  }
}
.ttl-deco06--num {
  font-size: rem(48);
  letter-spacing: 0.02em;
  color: var(--clr-list, #c8192d);
  line-height: 1;
  padding-left: 18px;
}

/* 装飾ボックス　その方法はコレ!! */
.box-addition01 {
  padding-block: 80px 129px;
  &::after {
    content: "";
    display: block;
    border-top: 4px double #c8bfa0;
  }
}
.box-addition01 .ttl-02 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 0 !important;
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(24);
  }
  &::before,
  &::after {
    content: "";
    flex: 1;
    border-top: 4px double #c8bfa0;
  }
  &::after {
    left: 0;
  }
}
.addition01--txtarea {
  position: relative;
  margin-bottom: 1em;
  padding: rem(56);
  font-size: rem(20);
  color: var(--clr-blk, #000);
  letter-spacing: 0.075em;
  line-height: 1.75;
  background-color: #faf9f3;
  background-image: url(../images/common/addition01-bg-lft.png), url(../images/common/addition01-bg-rgt.png);
  background-position: left bottom, right top;
  background-repeat: no-repeat;
  @include mq(sp) {
    padding: sprem(24);
    font-size: sprem(18);
  }
}

/* 装飾ボックス　コラムボックス */
.box-addition02 {
  margin-block: 3rem;
  padding: rem(56) rem(64);
  background-color: var(--bg-wht, #fff);
  border-left: 14px solid;
  border-image: linear-gradient(to top, #f85aa5 0%, #ea5078 53%, #c40c40 100%) 1;
  border-image-slice: 1;
  box-shadow: 0 0 21px rgb(103 15 38 / 15%);
  @include mq(sp) {
    padding: sprem(24);
    border-left: 6px solid;
  }
}
.box-addition02 .editor-ttl2 {
  margin-top: 0.5em;
}
.addition02--txt {
  letter-spacing: 0.1em;
  line-height: 2;
}
/* next_page
********************************************** */
.next_page_list {
  box-shadow: 0 0 21px rgb(103 15 38 / 15%);
  li:nth-child(1) {
    background-color: #e27a89;
  }
  li:nth-child(2) {
    background-color: #c93f67;
  }
  li:nth-child(3) {
    background-color: #7f2848;
  }
}
.next_page {
  flex: 1;
}
.next_page a {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(20);
  color: var(--clr-wht);
  font-size: rem(32);
  line-height: 1.5;
  padding: rem(64);
  letter-spacing: 0.12em;
  @include mq(sp, min) {
    height: 100%;
    min-height: 240px;
  }
  @include mq(sp) {
    padding: sprem(24);
    font-size: sprem(20);
  }
  &:hover {
    opacity: 0.5;
  }
  &::after {
    content: "";
    display: block;
    flex-shrink: 0;
    width: rem(24);
    background: url(../images/common/right-arrow.svg) no-repeat;
    background-size: cover;
    aspect-ratio: 24/48;
    @include mq(sp) {
      width: sprem(10);
    }
  }
}
.next_page .font-en {
  display: block;
  margin-top: 1em;
  font-size: rem(16);
  line-height: var(--line-height-none);
  text-transform: uppercase;
  opacity: 0.7;
  font-weight: 600;
  letter-spacing: 0.09em;
}
.next_page-bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0.2;
  transition: var(--transit-default);
}
