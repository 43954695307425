@use "./variables" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes caret {
  0%,
  50% {
    opacity: 0;
  }
  51%,
  100% {
    opacity: 1;
  }
}

@keyframes fade_In {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade_slide_In {
  0% {
    opacity: 0;
    translate: 0 10px;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
