@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */

.footer {
  background-color: var(--bg-blk);
}
.footer .gnav {
  opacity: 1;
  position: relative;
  z-index: 0;
  overflow: initial;
  pointer-events: auto;
  padding-top: vw(64);
  @include mq(sp) {
    padding-top: spvw(32);
  }
}

// page--top
.page--top {
  position: fixed;
  bottom: rem(32);
  right: rem(16);
  z-index: 1000;
  padding: rem(16) rem(24);
  color: var(--clr-list);
  font-size: rem(18);
  font-weight: bold;
  font-family: var(--font-en);
  line-height: 1;
  letter-spacing: 0.02em;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
  opacity: 0;
  translate: 0 rem(16);
  pointer-events: none;
  @include write-v;
  @include mq(sp) {
    bottom: sprem(100);
    right: sprem(10);
    padding: sprem(10);
  }
  &::before {
    content: "";
    width: rem(10);
    height: rem(54);
    margin-bottom: rem(8);
    margin-left: rem(10);
    border-top: 1px solid;
    border-left: 1px solid;
    transform: skewY(50deg);
    transform-origin: bottom;
    transition: var(--transit-default);
  }
  &.show {
    opacity: 1;
    translate: 0 0;
    pointer-events: auto;
  }
  &:hover {
    &::before {
      scale: 1 1.2;
    }
  }
}
