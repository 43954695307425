@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item {
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
}
.lps_parts--faq .faq_item:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
.lps_parts--faq .editor-ttl2 {
  margin-bottom: 16px;
}
.lps_parts--faq .ttl-03,
.lps_parts--faq .text {
  padding: 24px 32px;
  @include mq(sp) {
    padding: 16px 24px;
  }
  &::after {
    position: absolute;
    left: 48px;
    display: block;
    font-family: var(--font-en, "Montserrat", sans-serif);
    font-weight: 600;
    font-style: italic;
    font-size: rem(32);
    @include mq(sp) {
      left: 20px;
      font-size: sprem(26);
    }
  }
}
.lps_parts--faq .ttl-03 {
  margin-bottom: 0 !important;
  color: var(--clr-wht, #fff);
  font-size: rem(20);
  font-family: var(--font-yu-cothic);
  -webkit-background-clip: unset;
  background-image: linear-gradient(90deg, #1f1828, #cf436b, #dbddd7);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.lps_parts--faq .text {
  position: relative;
  background-color: var(--bg-wht, #fff);
  box-shadow: 0 0 52px rgba(103, 15, 38, 0.15);
  @include mq(sp, min, ps) {
    display: flex;
    gap: 5%;
  }
  p {
    letter-spacing: 0.04em;
    line-height: 2;
    color: var(--clr-blk, #000);
  }
}
.lps_parts--faq .img {
  @include mq(sp, min, ps) {
    width: 30%;
  }
}
.lps_parts--faq .mce-content-body {
  @include mq(sp, min, ps) {
    margin-bottom: 0 !important;
    flex: 1;
  }
}
