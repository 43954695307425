@use "sass:math";
@use "../abstracts" as *;

@for $i from 0 through 150 {
  .mgn-#{$i} {
    margin: math.div($i, $fz-pc) + rem;
  }
  .mgn-top#{$i} {
    margin-top: math.div($i, $fz-pc) + rem;
  }
  .mgn-rgt#{$i} {
    margin-right: math.div($i, $fz-pc) + rem;
  }
  .mgn-btm#{$i} {
    margin-bottom: math.div($i, $fz-pc) + rem;
  }
  .mgn-lft#{$i} {
    margin-left: math.div($i, $fz-pc) + rem;
  }
}

@include mq(sp) {
  @for $i from 0 through 150 {
    $i2: math.div($i, 2);
    .mgn-#{$i} {
      margin: math.div($i2, $fz-sp) + rem;
    }
    .mgn-top#{$i} {
      margin-top: math.div($i2, $fz-sp) + rem;
    }
    .mgn-rgt#{$i} {
      margin-right: math.div($i2, $fz-sp) + rem;
    }
    .mgn-btm#{$i} {
      margin-bottom: math.div($i2, $fz-sp) + rem;
    }
    .mgn-lft#{$i} {
      margin-left: math.div($i2, $fz-sp) + rem;
    }
  }
}

@for $i from 0 through 15 {
  .mgn-#{$i}_em {
    margin: #{$i}em;
  }
  .mgn-top#{$i}_em {
    margin-top: #{$i}em;
  }
  .mgn-rgt#{$i}_em {
    margin-right: #{$i}em;
  }
  .mgn-btm#{$i}_em {
    margin-bottom: #{$i}em;
  }
  .mgn-lft#{$i}_em {
    margin-left: #{$i}em;
  }
}
