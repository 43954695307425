@use "../abstracts" as *;

/* ##############################################################################

    SINGLE

############################################################################## */

/* --- アイキャッチ --- */
.eyecatch {
  gap: 8%;
  align-items: center;
  @include mq(sp) {
    gap: sprem(24);
  }
}
.eyecatch--child {
  position: relative;
}
.eyecatch--child .ttl-deco03 {
  position: absolute;
  top: rem(20);
  left: rem(-20);
  @include mq(sp) {
    top: sprem(10);
    left: sprem(-10);
  }
}
.eyecatch--before {
  width: 30% !important;
  @include mq(sp) {
    width: 80% !important;
  }
}
.eyecatch--after {
  flex: 1;
}

/* --- 何分で読めます --- */
.estimated-reading-time {
  margin-bottom: rem(80) !important;
  padding: 1em;
  font-size: rem(22);
  font-family: var(--font-jp);
  text-align: center;
  border-top: 14px solid;
  border-image: linear-gradient(136deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  background-color: #f2f2f2;
  line-height: var(--line-height-none);
  @include mq(sp) {
    border-top: 6px solid;
    font-size: sprem(16);
  }
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}
#ez-toc-container {
  position: relative;
  margin-block: rem(130);
  padding: rem(32);
  background-color: #faf9f3;
  border: 1px solid #f4eee6;
  @include mq(sp, min) {
    display: grid;
    grid-template-columns: vw(230) 1fr;
  }
  @include mq(sp) {
    margin-block: sprem(90);
    padding: sprem(20);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    display: block;
    width: rem(209);
    background-image: url(../images/common/ttl-01-deco.svg);
    background-size: 100%;
    aspect-ratio: 209/12;
    translate: -50% 0;
    @include mq(sp) {
      width: sprem(162);
    }
  }
  &::before {
    top: rem(-45);
    @include mq(sp) {
      top: sprem(-45);
    }
  }
  &::after {
    bottom: rem(-45);
    transform: scale(1, -1);
    @include mq(sp) {
      bottom: sprem(-45);
    }
  }
}
#ez-toc-container p.ez-toc-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--clr-main);
  font-size: rem(32);
  font-family: var(--font-jp);
  font-weight: bold;
  letter-spacing: 0.075em;
  @include mq(sp, min) {
    flex-direction: column;
  }
  @include mq(sp) {
    font-size: sprem(17);
    flex-direction: column;
    gap: 2px;
  }
  &::before,
  &::after {
    content: "";
    display: block;
    width: 74px;
    background-image: url(../images/common/ttl-deco04.svg);
    background-size: 100%;
    aspect-ratio: 74/15;
  }
  &::after {
    transform: scale(1, -1);
  }
}
.ez-toc-list {
  counter-reset: number;
}
.ez-toc-list li {
  &:not(:last-child) {
    border-bottom: 1px solid #f0e9db;
  }
  &:nth-of-type(9) ~ li a::before {
    content: counter(number);
  }
}
.ez-toc-list a {
  position: relative;
  display: block;
  padding-left: rem(60);
  padding-block: rem(8);
  text-decoration: none;
  line-height: 1.5;
  @include mq(sp) {
    font-size: sprem(14);
  }
  &::before {
    counter-increment: number;
    content: "0" counter(number);
    position: absolute;
    left: rem(16);
    top: rem(10);
    color: #9b7621;
    font-size: rem(18);
    font-family: var(--font-num);
    line-height: var(--line-height-none);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
}
// .ez-toc-list ul {
//   margin-left: rem(16);
// }
// .ez-toc-list ul a {
//   position: relative;
//   display: inline-block;
//   padding-left: rem(16);
//   &::before {
//     content: "";
//     position: absolute;
//     top: rem(10);
//     left: 0;
//     width: rem(8);
//     height: 0;
//     border-bottom: 1px solid;
//   }
// }

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}
#pass input[name="post_password"] {
  padding: rem(5);
  border: solid 1px #aaa;
  outline: none;
}
#pass input[type="submit"] {
  padding: rem(4) rem(6);
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: rem(4);
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}
#pass input[type="submit"]:hover {
  opacity: 0.7;
}

/* article
**************************************** */
.sticky {
  position: sticky;
  top: 140px;
}
.article--info .article--ttl {
  padding-bottom: 1em;
  color: var(--clr-body);
  text-align: justify;
  border-bottom: 4px double #c8bfa0;
}
.main_column--txt {
  flex: 1;
}
.main_column--fix {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 40px;
  margin-right: 77px;
  @include mq(sp) {
    display: none;
  }
}
.article--footer {
  padding-top: rem(40);
  border-top: 1px solid #eae6eb;
}
.movie--wrap {
  position: relative;
  cursor: pointer;
}

.movie::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  translate: -50% -50%;
  display: block;
  width: rem(73);
  background-image: url(../images/common/icon-youtube-color.svg);
  aspect-ratio: 213/150;
}
.movie--wrap iframe {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

/* favorite
**************************************** */
.simplefavorite-button {
  outline: none;
  display: inline-block;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}
.simplefavorite-button.active {
  .icon-heart {
    &::before {
      background-image: url(../images/common/icon-heart-fill.svg);
    }
  }
}
.btn-bookmark .simplefavorite-button {
  width: min(100%, 380px);
}
.btn-bookmark .simplefavorite-button i {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(24);
  color: var(--clr-wht);
  padding: 1.2em;
  font-size: rem(18);
  font-family: var(--font-jp);
  letter-spacing: 0.08em;
  border: 3px solid;
  background-color: var(--bg-red);
  background-image: linear-gradient(-35deg, #f85aa5 0%, #ea5078 53%, #c40c40 100%);
  border-radius: 100px;
  box-shadow: 0 0 52px rgb(103 15 38 / 15%);
  aspect-ratio: auto;
  @include mq(sp) {
    padding: 1em;
    border: 2px solid;
  }
  &.icon-heart::before {
    background-image: url(../images/common/icon-heart-wht.svg);
  }
  &::after {
    content: "この記事をブックマーク";
  }
}
.btn-bookmark .simplefavorite-button.active i {
  color: var(--clr-sub);
  border-color: var(--bg-red);
  background-color: #eee;
  background-image: none;
  box-shadow: 0 0 0 transparent;
  &.icon-heart::before {
    background-image: url(../images/common/icon-heart-fill.svg);
  }
  &::after {
    content: "ブックマークしました";
  }
}
.btn-bookmark-mark {
  position: relative;
  z-index: 2;
  display: grid;
  place-items: center;
  &.sm .icon-heart {
    display: inline-block;
    width: 27px;
    &::before {
      width: 100%;
    }
  }
}


.txt-bookmark .simplefavorite-button {
  width: min(100%, 380px);
}
.txt-bookmark .simplefavorite-button i {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(24);
  color: var(--bg-red);
  padding: 1.2em;
  font-size: rem(18);
  font-family: var(--font-jp);
  letter-spacing: 0.08em;
  aspect-ratio: auto;
  @include mq(sp) {
    padding: 1em;
  }
  &::after {
    content: "この記事をブックマーク";
  }
}
.txt-bookmark .simplefavorite-button.active i {
  &.icon-heart::before {
    background-image: url(../images/common/icon-heart-fill.svg);
  }
  &::after {
    content: "ブックマークしました";
  }
}
.txt-bookmark-mark {
  position: relative;
  z-index: 2;
  display: grid;
  place-items: center;
  &.sm .icon-heart {
    display: inline-block;
    width: 27px;
    &::before {
      width: 100%;
    }
  }
}

/* coach
**************************************** */
.coach--info {
  padding: rem(64);
  @include mq(sp) {
    padding: sprem(24);
  }
}
.coach--info .eyecatch {
  width: 80%;
  max-width: 200px;
  margin-inline: auto;
}
.coach--info table,
.coach--info iframe {
  width: 100%;
}
.coach--info iframe {
  height: auto;
  aspect-ratio: 16/9;
}
.coach--info .movie_box {
  gap: rem(24);
}
.coach--info .movie_box li {
  flex: 1;
}

/* news
**************************************** */
.news--info .post--txt {
  font-size: rem(15);
}

/* gallery
**************************************** */
.gallery--btn {
  margin-right: rem(8);
  margin-left: rem(8);
  a {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    padding: 1em 2.5rem;
    color: var(--clr-main-sub);
    font-weight: bold;
    font-size: 1.25rem;
    font-family: var(--font-jp);
    text-align: center;
    line-height: 1.4;
    gap: 1rem;
    letter-spacing: 0.17em;
    transition: all 0.4s ease-out;
    transition: var(--transit-default);
    text-decoration: none;
    border-radius: rem(12);
    background-color: #f2dee1;
    &::after {
      transform: rotate(90deg);
      content: "";
      position: absolute;
      right: 1.875rem;
      display: block;
      width: 9px;
      height: 19px;
      background: url(/wp/wp-content/themes/standard_sass/images/common/icon-arrow.svg) no-repeat;
      background-size: cover;
      transition: all 0.4s ease-out;
      transition: var(--transit-default, all 0.4s ease-out);
      @include mq(sp) {
        width: 6px;
        height: auto;
        aspect-ratio: 6 / 12;
      }
    }
    @include mq(sp) {
      max-width: rem(200);
      padding: 0.6em 2rem;
      font-size: sprem(16);
    }
  }
}

.single-gallery .page_ttl-none {
  margin-bottom: vw(40);
}
.gallery {
  padding: 4px;
  margin-bottom: 49px;
  h2 {
    padding-top: rem(40);
    @include mq(sp) {
      padding-top: rem(24);
    }
  }
}

.gallery--movie {
  padding: 8px;
  a {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
  }
  a::before {
    content: "";
    position: absolute;
    top: calc(50% - 52px / 2);
    left: calc(50% - 70px / 2);
    z-index: 1;
    display: block;
    width: 73px;
    height: 52px;
    background-image: url(../images/common/icon-youtube-color.svg);
    @include mq(sp) {
      top: calc(50% - 36.5px / 2);
      left: calc(50% - 36.5px / 2);
      width: 36.5px;
      height: 26px;
    }
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
  @include mq(sp) {
    width: calc(100% / 2);
    padding: 4px;
    border-radius: 2px;
  }
}

.gallery--img {
  box-sizing: border-box;
  padding: 8px;
  font-size: 2rem;
  background-color: white;
  border-radius: 6px;
  transition: 0.5s;
  img {
    width: 100%;
    height: vw(400);
    object-fit: cover;
    border-radius: 4px;
    @include mq(sp) {
      height: vw(700);
    }
  }
  &:hover {
    opacity: 0.6;
  }
  @include mq(sp) {
    width: calc(100% / 2);
    padding: 4px;
    border-radius: 2px;
  }
}
.gallery--img.is-hidden,
.gallery--movie.is-hidden {
  opacity: 0;
  height: 0;
  padding: 0;
  pointer-events: none;
  transition: 0.4s;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .gallery {
    margin-bottom: 0;
  }
  .single-gallery .btn {
    width: 80%;
    margin-inline: auto;
    margin-bottom: vw(64);
  }
}

/* share
**************************************** */
.share_list li {
  &.twitter a {
    color: #2ba1f2;
  }
  &.facebook a {
    color: #2477f2;
  }
  &.line a {
    color: #27c754;
  }
}
.share_list a {
  display: flex;
  @include center-flex;
  gap: rem(9);
  padding: 0.7em 0.5em;
}
.share_box-horizon .share_list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, min(100%, 190px));
  gap: rem(6);
  padding: rem(29);
  background-color: var(--bg-off_wht2);
  @include mq(sp) {
    grid-template-columns: repeat(3, 1fr);
    padding: sprem(16);
  }
}
.share_box-horizon .share_list a {
  height: 100%;
  font-size: rem(15);
  font-family: var(--font-secondary);
  font-weight: bold;
  line-height: 1;
  border-radius: 4px;
  background-color: var(--bg-wht);
  @include mq(sp) {
    font-size: sprem(10);
  }
  &:hover {
    opacity: 0.5;
  }
}
.share_box-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(16);
  margin-top: rem(32);
  &::before {
    content: "SHARE";
    display: block;
    color: var(--clr-main-sub);
    font-size: rem(13);
    font-family: var(--font-en);
    text-transform: uppercase;
    @include write-v;
  }
}
.share_box-vertical .share_list {
  display: flex;
  flex-direction: column;
  gap: rem(4);
  padding-block: rem(4);
  border-radius: 100px;
}
.share_box-vertical .share_list a {
  width: rem(40);
  height: rem(40);
  border: none;
}

/* posts--related
**************************************** */
.posts--related .editor-ttl {
  padding-bottom: 0.5em;
  border-bottom: rem(3) solid var(--clr-main);
}
.posts--related .more {
  display: inline-flex;
  gap: 8px;
  margin-left: auto;
  @include mq(sp, min) {
    position: absolute;
    right: 0;
    top: 1em;
  }
}
.single-article .posts--related .editor-ttl {
  margin-bottom: 0;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  font-family: var(--font-jp);
  @include mq(sp) {
    margin-top: 24px;
  }
}
.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}
.wp-pagenavi a {
  position: relative;
  padding: 0.5em 1em;
  background-color: var(--btn-bg-main);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--clr-wht, #fff);
  border-radius: 4px;
  &:hover {
    background-color: var(--btn-bg-main-hvr);
  }
}
.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  @include rect(40);
  @include mq(sp) {
    @include rect(32);
  }
}
.wp-pagenavi-single a[rel="prev"],
.wp-pagenavi-single a[rel="next"] {
  width: 40px;
  @include mq(sp) {
    width: 32px;
  }
}
.wp-pagenavi > *:not(.pages) {
  margin-bottom: 4px;
}
.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 4px;
}
.wp-pagenavi .extend {
  width: 24px !important;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 8px;
  height: 8px;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="prev"]::before {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="next"]::before {
  transform: rotate(-135deg);
}
.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -6px;
  @include mq(sp) {
    left: -5px;
  }
}
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -6px;
  @include mq(sp) {
    right: -5px;
  }
}
