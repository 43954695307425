@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  text-align: left;
  width: 100%;
  color: var(--clr-main-sub, #1d7fb7);
  padding-block: rem(20);
  @include mq(sp) {
    overflow: scroll;
    white-space: nowrap;
    font-size: sprem(12);
  }
}
.breadcrumbs a {
  text-decoration: none;
  color: var(--clr-main-sub, #1d7fb7);
}
.breadcrumbs .icon-arrow {
  margin-inline: 4px;
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.single-article .container,
.single-news .container,
.single-story .container {
  .breadcrumbs {
    border-block: 1px solid #eae6eb;
  }
}
.page_ttl {
  .breadcrumbs {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.page_ttl-none {
  .breadcrumbs {
    position: relative;
    text-align: center;
  }
}
